import { Routes } from 'app/routes/Routes'

import EnvironmentService, { Environment } from './EnvironmentService'
import UserService from './UserService'

class BrowserServices {
  static getDomainName() {
    return window.location.hostname.split('.').slice(-1)[0]
  }

  static redirectToLink = (redirectionLink: string) => {
    //@ts-ignore
    window.location = redirectionLink
  }

  static removeParamFromRoute = (param = '') => {
    const search = window.location.search || ''
    const urlParams = new URLSearchParams(search.slice(1))
    urlParams.delete(param)
    return urlParams.toString()
  }

  static getCustomerIdUrl = (customerId = UserService.customerId) => {
    return `${Routes.Base(customerId)}${Routes.Overview.Base}`
  }

  static getCustomerIdParam = (pathname = '') => {
    const paths = pathname.split('/')
    return paths[paths.findIndex((item) => item === 'kunden') + 1]
  }

  static getSupplierDashboardUrl = () => {
    const baseUrl = EnvironmentService.getEnvValue('SUPPLIER_DASHBOARD_URL')
    const domain = EnvironmentService.is(Environment.Dev)
      ? ''
      : `.${BrowserServices.getDomainName()}`
    return `${baseUrl}${domain}${Routes.Overview.Base}`
  }

  static getOrganizationUrl = (contactOrganization: IContactOrganization) => {
    if (contactOrganization.parentable_type === 'SupplierStation') {
      return BrowserServices.getSupplierDashboardUrl()
    } else {
      return BrowserServices.getCustomerIdUrl(contactOrganization.parentable_id)
    }
  }
}

export default BrowserServices
