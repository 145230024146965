import { gql } from '@apollo/client/core'

export const PAGE_INFO_ATTRIBUTES = gql`
  fragment pageInfoAttributes on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`
