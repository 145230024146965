import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover, Space } from 'antd'
import KLink from 'app/components/Modules/KLink'
import Icon from 'app/components/UI-Elements/General/Icon'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useHelpUrl } from 'app/lib/hooks/useHelpUrl'
import * as React from 'react'

type Props = {
  recordType?: string
  id?: string
  popover?: boolean
  popoverProps?: React.ComponentProps<typeof Popover>
}

const KHelp: React.FC<Props> = ({ recordType, id, popover = false, popoverProps }) => {
  const { tComponent } = ToolBox.useString()
  const url = useHelpUrl({ recordType, id })
  const title = tComponent({
    componentType: 'modules',
    componentName: 'khelp',
    recordType,
    id
  })

  const content = (
    <Space>
      <InfoCircleOutlined />
      <KLink external to={url}>
        {title}
        <Icon type="external_link" className="pl-1" />
      </KLink>
    </Space>
  )

  return (
    <Choose>
      <When condition={popover}>
        <Popover content={content} trigger={['click']} {...popoverProps}>
          <InfoCircleOutlined />
        </Popover>
      </When>
      <Otherwise>{content}</Otherwise>
    </Choose>
  )
}

export default KHelp
