import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { DatePicker as MobileDatePicker } from 'antd-mobile'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KDatePickerProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import { useDate } from 'app/lib/hooks/toolBox'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { FormItemProps } from './types'

const minTime = () => {
  const { datify } = useDate()

  const date = datify.now
  date.setHours(6)
  date.setMinutes(0, 0, 0)

  return date
}
type Props = KDatePickerProps & {
  onChange?: (date: Dayjs, dateString: string | string[]) => void
}

const KMobileDatePicker = ({
  name,
  showTime,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  maxDate,
  minDate,
  className = '',
  disablePast,
  ...others
}: Props) => {
  const { formatMessage } = useIntl()
  const {
    momentify,
    calendar,
    datify: { convert: convertDate, revert: revertDate, nextHour, now },
    format
  } = useDate()
  const formatType = showTime ? 'datetime' : 'date'
  const convert = (date: string) => convertDate(date, formatType)
  const revert = (date: Date) => revertDate(date, formatType)

  const getRoundUpDate = useMemo(() => nextHour(), [])

  const getTranslation = (id: string) =>
    formatMessage({ id: `component.data_entry.date_picker.${id}` })

  const Pickers = ({ onChange, value }: FormItemProps<Date, string>) => {
    const getMinDate = () => {
      if (minDate) {
        return {
          minDate: convert(minDate),
          minTime: nextHour(convert(minDate))
        }
      }

      if (disablePast) {
        return {
          minDate: now,
          minTime:
            momentify.convert(value).get('day') === momentify.now.get('day')
              ? getRoundUpDate
              : minTime()
        }
      }
    }

    const getValue = useMemo(() => (value ? convert(value) : getRoundUpDate), [value])

    const minValues = getMinDate()

    return (
      <Space size="middle">
        <MobileDatePicker
          className={`${className} mobile-date-picker`}
          mode="date"
          format={format.date}
          title={getTranslation('set_date')}
          onChange={(date) => onChange(nextHour(date))}
          value={getValue}
          maxDate={maxDate && convert(maxDate)}
          minDate={minValues?.minDate}
          dismissText={getTranslation('dismiss_text')}
          okText={getTranslation('ok_text')}>
          <Button icon={<CalendarOutlined />} title={calendar(momentify.convert(getValue))} />
        </MobileDatePicker>
        <If condition={showTime}>
          <MobileDatePicker
            minDate={minValues?.minTime}
            mode="time"
            title={getTranslation('set_time')}
            minuteStep={60}
            onChange={onChange}
            value={getValue}
            dismissText={getTranslation('dismiss_text')}
            okText={getTranslation('ok_text')}>
            <Button
              icon={<ClockCircleOutlined />}
              title={momentify.revert(momentify.convert(getValue), 'time')}
            />
          </MobileDatePicker>
        </If>
      </Space>
    )
  }

  return (
    <KFormItem
      initialValue={revert(getRoundUpDate)}
      getValueFromEvent={(e) => revert(e)}
      name={name}
      {...others}>
      <Pickers />
    </KFormItem>
  )
}

export default KMobileDatePicker
