import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KDatePickerProps } from 'app/components/Modules/Form/typing'
import MobileFriendly from 'app/components/UI-Elements/General/MobileFriendly'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { useDate } from 'app/lib/hooks/toolBox'
import React, { useEffect } from 'react'

import KInputText from '../KInputText'
import KDesktopDatePicker from './Desktop'
import KMobileDatePicker from './Mobile'

type Props = KDatePickerProps & {
  timeName?: string
}
const KDatePicker: React.FC<Props> = ({
  minDate,
  maxDate,
  timeName,
  showTime,
  name,
  ...others
}) => {
  return (
    <>
      <MobileFriendly<KDatePickerProps>
        desktop={<KDesktopDatePicker />}
        mobile={<KMobileDatePicker />}
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        showTime={showTime || Boolean(timeName)}
        {...others}
      />
      <If condition={timeName}>
        <KFormItem name={name} hidden>
          <KTime name={timeName} />
        </KFormItem>
        <KInputText hidden name={timeName} />
      </If>
    </>
  )
}

const KTime = ({ value, name }: { value?: string; name: string }) => {
  const { form } = useKFormContext()
  const {
    momentify: { convert, revert }
  } = useDate()

  useEffect(() => {
    const getTime = (): string | void => {
      if (value) {
        return revert(convert(value, 'datetime'), 'time')
      }
    }
    form.setFieldsValue({ [`${name}`]: getTime() })
  }, [value])

  return <></>
}

export default KDatePicker
