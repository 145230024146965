import { Form, Row, Space } from 'antd'
import KDestroyButton from 'app/components/Modules/Form/KDestroyButton'
import KSubmit from 'app/components/Modules/Form/KSubmit'
import { FormRecord, KFormProps } from 'app/components/Modules/Form/typing'
import Portal from 'app/components/UI-Elements/General/Portal'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import KFormContextProvider from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useShow, useSubmit } from 'app/lib/hooks/useApi'
import { omit } from 'lodash'
import { ReactNode, useEffect, useState } from 'react'

import KButton from '../General/KButton'

function KForm<T extends FormRecord>(props: KFormProps) {
  const { smartTranslation } = ToolBox.useString()
  const { boxefy } = useFeedbacker()
  const {
    submitButtonProps = {},
    resetOnSuccess,
    visualAction,
    silentSuccess,
    showParams,
    payloadModifier,
    recordType,
    children,
    recordId,
    onSuccess,
    onFailure,
    initialValues,
    inlineDestroy,
    inlineErrors,
    cancelable,
    onCancel,
    visualRecordType = recordType,
    forceAction,
    hideSubmit,
    blockSubmit,
    submitTitle,
    onValuesChange,
    portalId,
    strictAttributes = [],
    initialFetch = true,
    loading,
    scrollToFirstError = { block: 'center' },
    ...others
  } = props
  const action = forceAction || (recordId ? 'update' : 'create')
  const { loading: loadingShow, execute: show, response } = useShow<T>(recordType, visualRecordType)
  const {
    loading: loadingUpdate,
    execute: updateOrCreate,
    errors
  } = useSubmit(action)(recordType, visualRecordType, silentSuccess)
  const [form] = Form.useForm()
  const [hasErrors, setHasErrors] = useState<boolean>(false)
  const { formId: generateFormId } = ToolBox.useId()

  useEffect(() => {
    if (recordId && initialFetch) show({ id: recordId, ...showParams })
  }, [recordId])

  useEffect(() => {
    form.setFieldsValue(recordId && response)
  }, [response])

  useEffect(() => {
    hasErrors && setHasErrors(false)
  }, [initialValues])

  const onFinish = async (values: FormRecord) => {
    const modifiedValues = payloadModifier ? payloadModifier(values) : values

    // nested payload keys should be suffixed with _attributes ==> that's how rails expect the nested keys :(
    Object.keys(omit(modifiedValues, ...strictAttributes)).forEach((key) => {
      if (typeof modifiedValues[key] === 'object') {
        modifiedValues[`${key}_attributes`] = modifiedValues[key]
        delete modifiedValues[key]
      }
    })

    try {
      const updatedMember = await updateOrCreate({
        ...initialValues,
        ...modifiedValues,
        ...{ id: recordId }
      })
      onSuccess?.(updatedMember)
      if (resetOnSuccess) {
        form.resetFields()
      } else if (updatedMember) {
        form.setFieldsValue(updatedMember)
      }
    } catch (e) {
      onFailure?.(e)
    }
  }

  const onFinishFailed = () => {
    setHasErrors(true)
  }

  const handleValuesChange = (changedValues: Partial<T>, values: T) => {
    onValuesChange?.(changedValues, values)
    setHasErrors(false)
  }

  const showDestroyButton = () => inlineDestroy && recordId

  const formId = generateFormId({ recordType, action })

  const formButtons = () => (
    <Space>
      <If condition={cancelable}>
        <KButton
          type="secondary"
          recordType={visualRecordType}
          action="cancel_form"
          onClick={onCancel && onCancel}
          title={smartTranslation({
            recordType: visualRecordType,
            id: `form.${visualRecordType}.action.cancel`
          })}
        />
      </If>
      <If condition={showDestroyButton()}>
        <KDestroyButton
          onSuccess={() => onSuccess(response)}
          onFailure={onFailure}
          recordId={recordId}
          recordType={recordType}
          visualRecordType={visualRecordType}
        />
      </If>
      <KSubmit
        type={'primary'}
        hidden={hideSubmit}
        disabled={hasErrors || blockSubmit}
        loading={loadingShow || loadingUpdate || props.loading}
        action={action}
        title={submitTitle}
        visualAction={visualAction}
        form={formId}
        {...submitButtonProps}
      />
    </Space>
  )

  return (
    <KFormContextProvider
      loading={loadingShow || loadingUpdate || loading}
      form={form}
      hasErrors={hasErrors}
      visualRecordType={visualRecordType}
      {...props}>
      <Form
        onFinish={onFinish}
        form={form}
        onValuesChange={handleValuesChange}
        onFinishFailed={onFinishFailed}
        id={formId}
        initialValues={{ ...response, ...initialValues }}
        scrollToFirstError={scrollToFirstError}
        {...others}>
        <Row gutter={[{ xs: 0, md: 12 }, 24]}>
          <If condition={errors && inlineErrors}>
            <KCol span={24}>{boxefy({ content: errors, type: 'error', closable: false })}</KCol>
          </If>
          <KCol span={24}>{children as ReactNode}</KCol>
          <Choose>
            <When condition={portalId}>
              <Portal id={portalId}>
                <div className="full-width text-right">{formButtons()}</div>
              </Portal>
            </When>
            <Otherwise>
              <KCol span={24} className="text-right">
                {formButtons()}
              </KCol>
            </Otherwise>
          </Choose>
        </Row>
      </Form>
    </KFormContextProvider>
  )
}
export default KForm
