/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { RecordType, RouteInstance } from 'app/constants/GlobalTypes'

export const Routes = {
  Default: '/',
  Base: (id: number) => `/kunden/${id}`,
  Authentication: {
    Base: '/',
    Key: RecordType.authentication,
    collection: () => ({
      login: `${Routes.Authentication.Base}anmelden`,
      forgot_password: `${Routes.Authentication.Base}passwort_vergessen`,
      email_confirmation: `${Routes.Authentication.Base}email_bestaetigung`,
      set_password: `${Routes.Authentication.Base}passwort_setzen`,
      set_password_confirmation: `${Routes.Authentication.Base}passwort_setzen/bestaetigung`
    })
  },
  Overview: {
    Base: '/uebersicht',
    Key: RecordType.overview,
    collection: () => ({
      index: Routes.Overview.Base
    })
  },
  Project: {
    Base: '/bauprojekte',
    Key: RecordType.project,
    collection: () => ({
      index: `${Routes.Project.Base}`,
      timeline: `${Routes.Project.Base}/projektplan`,
      map: `${Routes.Project.Base}/karte`
    }),
    member: (id: number | string = ':project_id') => ({
      show: `${Routes.Project.Base}/${id}`,
      rental: `${Routes.Project.Base}/${id}/mietauftraege`,
      invoice: `${Routes.Project.Base}/${id}/rechnungen`,
      document: `${Routes.Project.Base}/${id}/dokumente`,
      map: `${Routes.Project.Base}/${id}/karte`
    })
  },
  Invoice: {
    Base: '/rechnungen',
    Key: RecordType.invoice,
    collection: () => ({
      index: Routes.Invoice.Base,
      overview: `/rechnungen_uebersicht`
    }),
    member: (id = ':invoice_id', withBase = true) => {
      const base = withBase ? Routes.Invoice.Base : ''
      return {
        show: `${base}/${id}`
      }
    }
  },
  Account: {
    Base: '/kundenkonten',
    Key: RecordType.account,
    collection: () => ({
      index: Routes.Account.Base
    })
  },
  Checkout: {
    Base: '/ueberprufen',
    Key: RecordType.checkout,
    member: function (id = ':checkout_id', withBase = true) {
      const base = withBase ? `${Routes.Checkout.Base}/${id}` : ''
      return {
        show: `${base}`,
        address: `${base}/adresse`,
        machines: `${base}/maschinen`,
        delivery: `${base}/lieferung`,
        confirmation: `${base}/bestaetigung`
      }
    }
  },
  Rental: {
    Base: '/mietauftraege',
    Key: RecordType.rental,
    collection: () => ({
      index: Routes.Rental.Base
    }),
    member: function (id = ':rental_id', withBase = true) {
      const base = withBase ? `${Routes.Rental.Base}/${id}` : ''
      return {
        show: base,
        update: `${base}/auftrag_bearbeiten`,
        extend_machine: `${base}/anpassen`,
        missing_information: `${base}/informationen_unvollstaendig`,
        damage: `${base}/schaden`,
        machine_interaction: `${base}/maschinen_interaktion`,
        quote: subRoutefy(this, Routes.Quote),
        decline_quote: `${base}/angebot_ablehnen`,
        checkout: () => ({
          index: `${base}/checkout`,
          Product: `${base}/checkout/produkte`,
          Delivery: `${base}/checkout/lieferung`,
          Project: `${base}/checkout/projekt`,
          Payment: `${base}/checkout/zahlung`,
          Confirmation: `${base}/checkout/bestaetigung`
        })
      }
    }
  },
  Transactional: {
    Base: '/mietauftraege/verwaltung',
    Key: RecordType.transactional,
    member: function (id = ':transactional_id') {
      return {
        missing_information: `${Routes.Transactional.Base}/${id}/informationen_unvollstaendig`,
        machine_interaction: `${Routes.Transactional.Base}/${id}/maschinen_interaktion`,
        confirmation: `${Routes.Transactional.Base}/${id}/bestaetigung`,
        decline_quote: `${Routes.Transactional.Base}/${id}/angebot_ablehnen`,
        quote: subRoutefy(this, Routes.Quote)
      }
    }
  },
  Quote: {
    Base: '/angebot',
    Key: RecordType.quote,
    member: function (id = ':quote_id', rental_id = ':rental_id') {
      return {
        payment: `${Routes.Quote.Base}/${id}`,
        payment_with_rental_id: `${Routes.Quote.Base}/${id}/${rental_id}`,
        quote_feedback: subRoutefy(this, Routes.QuoteFeedback)
      }
    }
  },
  QuoteFeedback: {
    Base: '/ablehnen',
    Key: RecordType.quote_feedback,
    member: function () {
      return { create: `${this.Base}` }
    }
  },
  Setting: {
    Base: '/einstellungen',
    Key: RecordType.setting,
    collection: () => ({
      index: Routes.Setting.Base
    })
  },
  FAQ: {
    Base: '/faq',
    Key: RecordType.faq,
    collection: () => ({
      index: Routes.FAQ.Base
    })
  },
  PriceList: {
    Base: '/preisliste',
    Key: RecordType.price_list,
    collection: () => ({
      index: Routes.PriceList.Base
    })
  },
  User: {
    Base: '/nutzer',
    Key: RecordType.user,
    collection: () => ({
      index: Routes.User.Base
    })
  },
  LineItem: {
    Base: '/maschinen',
    Key: RecordType.line_item,
    member: function (id = ':line_item_id') {
      return {
        create: `${this.Base}/create`,
        show: `${this.Base}/${id}`,
        update: `${this.Base}/${id}/update`
      }
    }
  },
  SubItem: {
    Base: '/sub_items',
    Key: RecordType.sub_item,
    collection: function () {
      return {
        index: `${this.Base}`
      }
    },
    member: function (id = ':sub_item_id') {
      return {
        create: `${this.Base}/create`,
        update: `${this.Base}/${id}/update`
      }
    }
  },
  Payment: {
    Base: '/bezahlung',
    Key: RecordType.payment,
    // or :rental_id
    member: function (id = ':payment_id') {
      return {
        show: `${this.Base}/${id}`,
        confirmation: `${this.Base}/${id}/bestaetigung`
      }
    }
  },
  Update: {
    Base: '/',
    Key: RecordType.update,
    collection: () => ({
      index: `${Routes.Update.Base}update`
    })
  },
  Error: {
    Base: '/error',
    Key: RecordType.error,
    collection: () => ({
      role_not_authorized: `${Routes.Error.Base}/role_not_authorized`
    })
  }
}

export const subRoutefy = (
  context: RouteInstance,
  route: RouteInstance,
  id: string | number = `:${context.Key}_id`
): RouteInstance => ({
  ...route,
  Base: `${context.Base}${id ? `/${id}` : ''}${route.Base}`
})
