import { MemberProps } from 'app/components/Core/convention'
import Select from 'app/components/UI-Elements/DataEntry/Select'
import { LocaleEnum } from 'app/constants/Enums'
import { allLocales } from 'app/constants/Global'
import { KFormProps, LanguageSelectOptionType, RecordType } from 'app/constants/GlobalTypes'
import { useUpdate } from 'app/lib/hooks/useApi'
import { useCurrentContact, useSession } from 'app/lib/hooks/useCurrent'
import IntlService from 'app/lib/services/IntlService'
import { USER } from 'app/redux/reducers/actionTypes'
import { useDispatch } from 'react-redux'

const LanguageLabel: React.FC<{ langProps: LanguageSelectOptionType }> = ({ langProps }) => {
  return (
    <span className="d-flex align-items-center">
      <i className={`mr-2 flag flag-24 flag-${langProps.icon}`} />
      <span>{langProps.label}</span>
    </span>
  )
}

const LanguageToggle: React.FC<MemberProps<IContact, KFormProps<IContact>>> = () => {
  const { langCode } = useSession()
  const { id } = useCurrentContact()
  const { loading, execute } = useUpdate(RecordType.contact)
  const dispatch = useDispatch()

  const onChange = (locale: LocaleEnum) => {
    IntlService.setLocale(locale)
    execute({ locale: IntlService.getLocaleWithHost(locale), id }).then((data) => {
      dispatch({
        payload: data,
        type: USER.UPDATE_LOCALE
      })
    })
  }

  return (
    <Select<{ value: string; label: React.ReactNode }>
      filterOption={false}
      entries={allLocales.map((locale) => ({
        value: locale.language,
        label: <LanguageLabel langProps={locale} />
      }))}
      selectValueBy={'value'}
      selectLabelBy={'label'}
      value={langCode}
      onChange={onChange}
      loading={loading}
    />
  )
}

export default LanguageToggle
