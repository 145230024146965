import { InfoCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import KLink from 'app/components/Modules/KLink'
import Icon from 'app/components/UI-Elements/General/Icon'
import * as React from 'react'

type Props = {
  title: string
  url: string
}

const ProfileLink: React.FC<Props> = ({ title, url }) => {
  return (
    <Space direction="horizontal">
      <InfoCircleOutlined />
      <KLink external to={url}>
        <Space direction="horizontal">
          <span>{title}</span>
          <Icon type="external_link" />
        </Space>
      </KLink>
    </Space>
  )
}

export default ProfileLink
