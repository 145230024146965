import { Divider, Row } from 'antd'
import { MemberProps } from 'app/components/Core/convention'
import Info from 'app/components/Core/CustomerRole/Info'
import KForm from 'app/components/Modules/Form/KForm'
import KInputText from 'app/components/Modules/Form/KInputText'
import KNestedFields from 'app/components/Modules/Form/KNestedFields'
import KRadio from 'app/components/Modules/Form/KRadio'
import KSelect from 'app/components/Modules/Form/KSelect'
import { KFormProps } from 'app/components/Modules/Form/typing'
import KHelp from 'app/components/Modules/KHelp'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { RecordType } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'
import { useRestriction } from 'app/lib/hooks/useRestriction'
import React, { useMemo, useState } from 'react'

import InvitationForm from '../Invitation/Form'

const Form: React.FC<
  MemberProps<IContactOrganization, Partial<KFormProps<IContactOrganization>>>
> = ({ member, children, props }) => {
  const [hasEmail, setHasEmail] = useState(Boolean(member?.contact?.email))
  const currentContactOrganization = useCurrentContactOrganization()
  const { restricted } = useRestriction({ allowFor: ['admin'] })
  const { tRecordType } = ToolBox.useString()

  const onValuesChange = (values: { contact: IContact }) => {
    if (values.contact?.email !== undefined) {
      setHasEmail(Boolean(values.contact?.email))
    }
  }

  const isSameUser = useMemo(
    () => currentContactOrganization.id === member?.id,
    [currentContactOrganization, member]
  )

  return (
    <Row>
      <KCol span={24}>
        <KForm
          inlineDestroy={!restricted && !isSameUser}
          visualRecordType={RecordType.user}
          recordId={member?.id}
          initialValues={member}
          recordType={RecordType.contact_organization}
          layout="vertical"
          onValuesChange={onValuesChange}
          hideSubmit={restricted}
          {...props}>
          <Row>
            <KCol span={24}>
              <fieldset disabled={restricted}>
                <KNestedFields as="contact">
                  <KInputText name="id" hidden />
                  <KInputText name="first_name" required />
                  <KInputText name="last_name" required />
                  <KInputText name="email" rules={[{ type: 'email' }]} />
                  <KInputText name="tel" required />
                </KNestedFields>
              </fieldset>
            </KCol>
            <If condition={!isSameUser}>
              <KCol span={24}>
                <KSelect
                  allowClear
                  required={hasEmail}
                  recordType={RecordType.customer_role}
                  filterOption={true}
                  name="role"
                  optionLabelProp="name"
                  selectValueBy="id"
                  labelRenderer={(label) => (
                    <span>
                      {label}{' '}
                      <KHelp recordType={RecordType.contact_organization} id="role" popover />
                    </span>
                  )}
                  disabled={!hasEmail || restricted}
                />
              </KCol>
              <KCol span={24} render={hasEmail}>
                <Divider orientation="left">
                  <span className="text-md">
                    {tRecordType(RecordType.contact_organization, 'divider_invitation_mail')}
                  </span>
                </Divider>
              </KCol>
              <KCol span={24} render={hasEmail}>
                <Choose>
                  <When condition={member}>
                    <Row align="middle">
                      <KCol span={12}>
                        <InvitationForm
                          member={{ id: null, contact_organization_id: member?.id }}
                        />
                      </KCol>
                      <KCol span={12}>
                        <div id="invitation-submit" />
                      </KCol>
                    </Row>
                  </When>
                  <Otherwise>
                    <KRadio defaultValue="klarx_manager" showLabel={false} name="invitation" />
                  </Otherwise>
                </Choose>
              </KCol>
            </If>
            {children && <KCol span={24}>{children}</KCol>}
          </Row>
        </KForm>
      </KCol>
      <If condition={!isSameUser && hasEmail}>
        <KCol span={24}>
          <Divider orientation="left">
            <span className="text-md">
              {tRecordType(RecordType.contact_organization, 'divider_role_description')}
            </span>
          </Divider>
          <Info />
        </KCol>
      </If>
    </Row>
  )
}

export default Form
