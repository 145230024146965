import { Browser } from '@capacitor/browser'

import CapacitorService from '../services/CapacitorService'

export function useExternalLink(url: string) {
  const open = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (CapacitorService.isNative()) {
      e.preventDefault()
      await Browser.open({ url })
    }
  }

  return { open }
}
