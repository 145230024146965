import { gql } from '@apollo/client/core'

import { SUB_ITEM_BASE_ATTRIBUTES } from './SubItem'

export const LINE_ITEM_PRODUCT_BASE_ATTRIBUTES = gql`
  fragment lineItemProductBaseAttributes on LineItem {
    id
    name
    categorySegment {
      id
      name
      hasAttributeSegmentations
    }
    rentalId
    earliestAvailableDate
    startDate
    returnDate
    startTime
    imageUrl
    returnTime
    discountedUnitPrice
    subItems {
      ...subItemBaseAttributes
      categorySegment {
        id
        name
        hasAttributeSegmentations
      }
      subItems {
        ...subItemBaseAttributes
      }
    }
  }
  ${SUB_ITEM_BASE_ATTRIBUTES}
`
