import { Result } from 'antd'
import Button from 'app/components/UI-Elements/General/Button'
import { BugsnagErrorBoundary } from 'app/lib/Bugsnag'
import { UIProvider } from 'app/lib/providers/UIProvider'
import React, { ComponentProps } from 'react'
import { useIntl } from 'react-intl'

import KCard from '../DataDisplay/KCard'

type Props = {
  children: React.ReactNode
}
const ErrorBoundary: React.FC<Props> = ({ children }) => {
  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>{children}</BugsnagErrorBoundary>
  )
}

type FallbackComponentProps = ComponentProps<typeof BugsnagErrorBoundary>['FallbackComponent']

const FallbackComponent: FallbackComponentProps = ({ clearError }) => {
  return (
    <UIProvider>
      <ErrorPage clearError={clearError} />
    </UIProvider>
  )
}

type ErrorPageProps = {
  clearError: () => void
}
const prefix = 'component.feedback.result.unknown_error'
const ErrorPage: React.FC<ErrorPageProps> = ({ clearError }) => {
  const { formatMessage } = useIntl()
  const onClick = () => {
    clearError()
    window.location.href = '/'
  }
  const t = (id: string) => formatMessage({ id: `${prefix}.${id}` })

  return (
    <KCard>
      <Result
        status="500"
        title={t('title')}
        subTitle={t('content')}
        extra={
          <Button type="primary" onClick={onClick}>
            {t('button')}
          </Button>
        }
      />
    </KCard>
  )
}

export default ErrorBoundary
