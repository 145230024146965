import { gql } from '@apollo/client/core'

import { ADDRESS_ATTRIBUTES, PAGE_INFO_ATTRIBUTES } from './fragments'

const FETCH_PROJECT_PRODUCTS = gql`
  query fetchProjectProducts($id: ID!) {
    project(id: $id) {
      id
      address {
        ...addressAttributes
      }
      products {
        id
        name
        longitude
        latitude
        imageUrl
        returnDate
        rentalId
        categorySegment {
          name
        }
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
`

const PAGINATED_PROJECT_QUERY = gql`
  query projects(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $query: String
    $scope: String
    $id: [ID!]
    $withInvoices: Boolean
    $sort: [SortOptions!] = [{ sortField: "created_at", sortDirection: "desc" }]
  ) {
    projects: projectsPaginated(
      first: $first
      last: $last
      after: $after
      before: $before
      query: $query
      scope: $scope
      id: $id
      withInvoices: $withInvoices
      sort: $sort
    ) {
      nodes {
        id
        startDate
        endDate
        latitude
        longitude
        name
        stage
        address {
          ...addressAttributes
        }
      }
      pageInfo {
        ...pageInfoAttributes
      }
      totalCount
      pageIndex
    }
  }
  ${PAGE_INFO_ATTRIBUTES}
  ${ADDRESS_ATTRIBUTES}
`

export { FETCH_PROJECT_PRODUCTS, PAGINATED_PROJECT_QUERY }
