import { Config } from 'app/constants/Config'
import { useAppVersion } from 'app/lib/hooks/useAppVersion'
import * as React from 'react'

const Version: React.FC = () => {
  const { version } = useAppVersion()
  const buildNumber = Config.buildNumber
  const build = buildNumber ? `(${buildNumber})` : null

  if (version) {
    return (
      <div className="text-muted text-center">
        {version} {build}
      </div>
    )
  }
  return null
}

export default Version
