import { Col, List as AntList, Row } from 'antd'
import { CollectionProps } from 'app/components/Core/convention'
import KList from 'app/components/Modules/List/KList'
import { RecordType } from 'app/constants/GlobalTypes'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  onItemClick?: (item: INotification) => void
}

const List: React.FC<CollectionProps<INotification, Props>> = ({
  collection,
  props: { onItemClick } = {}
}) => {
  const navigate = useNavigate()

  const onClick = (notification: INotification) => {
    if (notification.link_to) {
      navigate(notification.link_to)
      onItemClick?.(notification)
    }
  }

  return (
    <Row gutter={[12, 0]}>
      <Col span={24}>
        <KList
          recordType={RecordType.notification}
          itemLayout="horizontal"
          dataSource={collection}
          renderItem={(notification) => (
            <AntList.Item className="pointer" onClick={() => onClick(notification)}>
              <Row gutter={[0, 8]}>
                <Col span={24}>
                  <span className="text-heading hoverable">{notification.title}</span>
                  <span className="text-muted pull-right text-sm">{notification.date}</span>
                </Col>
                <Col span={24}>
                  <span
                    className="text-black hoverable"
                    dangerouslySetInnerHTML={{ __html: notification.description }}
                  />
                </Col>
              </Row>
            </AntList.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export default List
