import { Modal as AntModal } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import { ModalType } from 'app/components/Modules/Feedback/typing'
import * as React from 'react'

interface Props extends ModalProps {
  open: boolean
  title: string
  type?: ModalType
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
}

const Modal: React.FC<Props> = ({ title, open, onClose, type = 'default', ...others }) => {
  const getModalTitle = (): React.ReactNode => {
    return <h4 className="mb-0">{title}</h4>
  }

  return (
    <AntModal
      title={getModalTitle()}
      onCancel={onClose}
      className={`KModal-${type}`}
      open={open}
      {...others}
    />
  )
}

export default Modal
