import { RecordType } from 'app/constants/GlobalTypes'
import { get } from 'lodash'
type IRecordType = keyof typeof RecordType

type UrlType = Partial<Record<IRecordType, Record<string, string>>>

export const urlConfig: UrlType = {
  overview: {
    default: 'https://help.klarx.de/de/f%C3%BCr-kunden'
  },
  contact_organization: {
    role: 'https://help.klarx.de/de/welche-unterschiedlichen-nutzerrollen-gibt-es'
  },
  machine_group: {
    machine_interaction:
      'https://help.klarx.de/de/wie-kann-ich-die-maschinen-freimelden-oder-verl%C3%A4ngern'
  },
  checkout: {
    general: 'https://help.klarx.de/de/wie-miete-ich-maschinen-%C3%BCber-den-klarxmanager',
    direct_booking: 'https://help.klarx.de/de/direkt-buchen'
  }
}

type Props = {
  recordType?: string
  id?: string
}

export function useHelpUrl({ recordType, id }: Props = {}): string {
  if (!recordType && !id) {
    return urlConfig.overview.default
  }

  const url = get(urlConfig, `${recordType}.${id}`)
  return url || ''
}
