import { RouteType } from 'app/constants/GlobalTypes'
import { retry } from 'app/lib/utils/retry'
import Private from 'app/routes/Root'
import { Routes } from 'app/routes/Routes'
import { lazy, Suspense } from 'react'
import * as React from 'react'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'

import MainLayout from '../pages/MainLayout'

type PageType = { default: React.ComponentType<{ route: Partial<RouteType> }> }

const AccountsPage = lazy(
  () => retry(() => import('app/pages/Account/Views/index')) as Promise<PageType>
)

const Main: React.FC = () => {
  return (
    <Switch>
      <Route path={Routes.Authentication.collection().login} element={<Navigate to={'/'} />} />
      <Route
        key="account.index"
        path={Routes.Account.collection().index}
        element={
          <Suspense fallback={null}>
            <AccountsPage route={{ key: 'account.index' }} />
          </Suspense>
        }
      />
      <Route
        path="/*"
        element={
          <MainLayout>
            <Private />
          </MainLayout>
        }
      />
    </Switch>
  )
}

export default Main
