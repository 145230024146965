import { composeWithDevTools } from '@redux-devtools/extension'
import EnvironmentService, { Environment } from 'app/lib/services/EnvironmentService'
import createRootReducer from 'app/redux/reducers/index'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, legacy_createStore } from 'redux'
import thunk from 'redux-thunk'

const history = createBrowserHistory()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers: any = EnvironmentService.is(Environment.Dev) ? composeWithDevTools : compose

export default function configureStore(initialState?: unknown) {
  return legacy_createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  )
}

export { history }
