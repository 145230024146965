import Button from 'app/components/UI-Elements/General/Button'
import { RecordType } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { notify } from 'app/lib/Notify'
import { logoutUser } from 'app/redux/actions/__legacy/User'
import { ComponentProps, useState } from 'react'

const Logout: React.FC<ComponentProps<typeof Button>> = (props) => {
  const [loading, setLoading] = useState(false)
  const { tButtonTitle } = ToolBox.useString()

  const onClick = () => {
    setLoading(true)
    logoutUser().catch((err: string) => {
      notify({ message: err, intent: 'danger' })
      setLoading(false)
    })
  }

  return (
    <Button
      onClick={onClick}
      title={tButtonTitle({ action: 'logout', recordType: RecordType.user })}
      type="secondary"
      loading={loading}
      {...props}
    />
  )
}

export default Logout
