import { gql } from '@apollo/client/core'

import { SUB_ITEM_BASE_ATTRIBUTES } from './fragments'

const FETCH_SUB_ITEM_QUERY = gql`
  query fetchSubItem($id: ID!) {
    subItem(id: $id) {
      ...subItemBaseAttributes
    }
  }
  ${SUB_ITEM_BASE_ATTRIBUTES}
`

const UPDATE_SUB_ITEM = gql`
  mutation updateCustomerSubItem($input: UpdateCustomerSubItemInput!) {
    updateCustomerSubItem(input: $input) {
      subItem {
        ...subItemBaseAttributes
      }
      errors {
        message
      }
    }
  }
  ${SUB_ITEM_BASE_ATTRIBUTES}
`

const CREATE_SUB_ITEM = gql`
  mutation createCustomerSubItem($input: CreateCustomerSubItemInput!) {
    createCustomerSubItem(input: $input) {
      subItem {
        ...subItemBaseAttributes
      }
      errors {
        message
      }
    }
  }
  ${SUB_ITEM_BASE_ATTRIBUTES}
`

const DELETE_SUB_ITEM = gql`
  mutation deleteSubItem($input: DeleteSubItemInput!) {
    deleteSubItem(input: $input) {
      subItem {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`

export { FETCH_SUB_ITEM_QUERY, UPDATE_SUB_ITEM, DELETE_SUB_ITEM, CREATE_SUB_ITEM }
