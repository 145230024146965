import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Config } from 'app/constants/Config'
import React from 'react'

import EnvironmentService, { branchName, Environment } from './services/EnvironmentService'

// Bugsnag Config
const DEV_ENVIRONMENT = EnvironmentService.isOneOf([Environment.Test, Environment.Dev])
const apiKey = DEV_ENVIRONMENT
  ? '3bb1e67d88924ae57ef97f6ffc1e4715' // random id for dev and test
  : EnvironmentService.getEnvValue('BUGSNAG_KEY')

const releaseStage = EnvironmentService.is(Environment.Staging)
  ? Environment.Staging
  : process.env.REACT_APP_ENV

console.log('start bugsnag')
Bugsnag.start({
  apiKey,
  releaseStage,
  appVersion: Config.version,
  enabledErrorTypes: {
    unhandledRejections: false
  },
  onError: (e) => {
    const resizeObserverLoopErrRe = /^ResizeObserver loop/

    return !(DEV_ENVIRONMENT || resizeObserverLoopErrRe.test(e.errors[0]?.errorMessage))
  },
  plugins: [new BugsnagPluginReact()]
})

Bugsnag.addMetadata('version', {
  buildNumber: `${Config.buildNumber}`,
  version: `${Config.version}`,
  branch: branchName
})

export const BugsnagErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
