import { gql } from '@apollo/client/core'

import { ADDRESS_ATTRIBUTES, CONTACT_BASE_ATTRIBUTES } from '.'

export const RENTAL_BASE_ATTRIBUTES = gql`
  fragment rentalBaseAttributes on Rental {
    id
    duration
    startDate
    returnDate
    rentalCode
  }
`

export const RENTAL_STATUS_ATTRIBUTES = gql`
  fragment rentalStatusAttributes on Rental {
    status
    statusInfo {
      actions {
        text
        path
      }
      title
      type
      rentalId
      description
      stepperData
    }
  }
`

export const RENTAL_CHECKOUT_ATTRIBUTES = gql`
  fragment rentalCheckoutAttributes on Rental {
    ...rentalBaseAttributes
    directBookable
    deliveryMethod
    defaultDeliveryMethod
    annotation
    upfrontDelivery
    receiveSmsUpdates
    longitude
    latitude
    projectId
    deliveryAddress {
      ...addressAttributes
    }
    managerContact {
      ...contactBaseAttributes
    }
    deliveryContact {
      ...contactBaseAttributes
    }
    customerOrderNumber
    project {
      id
      name
      longitude
      latitude
      address {
        ...addressAttributes
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
  ${RENTAL_BASE_ATTRIBUTES}
  ${CONTACT_BASE_ATTRIBUTES}
`
