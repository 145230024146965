import {
  AimOutlined,
  AlignLeftOutlined,
  AppstoreOutlined,
  CalendarOutlined,
  EuroCircleOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  OrderedListOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons'
import { MenuProps, Space } from 'antd'
import { Menu as AntMenu } from 'antd'
import { ReactComponent as ExternalLinkOutlined } from 'app/../../public/assets/images/icons/external_link_icon.svg'
import { useHelpUrl } from 'app/lib/hooks/useHelpUrl'
import { useRestriction } from 'app/lib/hooks/useRestriction'
import { Routes } from 'app/routes/Routes'
import { MenuInfo } from 'rc-menu/lib/interface'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import testIds from 'test_ids.json'

type MenuItemProps = Required<MenuProps>['items'][number] & { children?: MenuItemProps[] }

const getItem = ({ allowFor, ...others }: MenuItemProps & { allowFor: RoleType[] }) => {
  const { restricted } = useRestriction({
    allowFor
  })

  return restricted ? undefined : others
}

const Menu: React.FC<MenuProps> = ({ onClick = () => void 0 }) => {
  const { formatMessage } = useIntl()
  const helpUrl = useHelpUrl()

  const menuItems: MenuItemProps[] = [
    {
      label: formatMessage({ id: 'sidebar.overview' }),
      key: Routes.Overview.collection().index,
      icon: <AppstoreOutlined />
    },
    {
      label: formatMessage({ id: 'sidebar.rentals' }),
      key: Routes.Rental.collection().index,
      icon: <UnorderedListOutlined />
    },
    {
      label: formatMessage({ id: 'sidebar.constructionProject' }),
      key: `${Routes.Project.collection().index}-main`,
      icon: <AlignLeftOutlined rotate={90} />,
      children: [
        {
          label: formatMessage({ id: 'sidebar.constructionProject.list' }),
          key: Routes.Project.collection().index,
          icon: <OrderedListOutlined />
        },
        {
          label: formatMessage({ id: 'sidebar.scheduler' }),
          key: Routes.Project.collection().timeline,
          icon: <CalendarOutlined />
        },
        {
          label: formatMessage({ id: 'sidebar.constructionProject.map' }),
          key: Routes.Project.collection().map,
          icon: <AimOutlined />
        }
      ]
    },
    {
      label: formatMessage({ id: 'sidebar.invoices' }),
      key: `${Routes.Invoice.collection().index}-main}`,
      icon: <FileDoneOutlined />,
      children: [
        {
          label: formatMessage({ id: 'sidebar.invoices' }),
          key: Routes.Invoice.collection().index,
          icon: <FileDoneOutlined />
        },
        {
          label: formatMessage({ id: 'sidebar.invoicesOverview' }),
          key: Routes.Invoice.collection().overview,
          icon: <FileDoneOutlined />
        }
      ]
    },
    {
      label: formatMessage({ id: 'sidebar.priceList' }),
      key: Routes.PriceList.collection().index,
      icon: <EuroCircleOutlined />
    },
    getItem({
      label: formatMessage({ id: 'sidebar.users' }),
      key: Routes.User.collection().index,
      icon: <UsergroupAddOutlined />,
      allowFor: ['admin', 'manager']
    }),
    getItem({
      label: formatMessage({ id: 'pages.profile.settings' }),
      key: Routes.Setting.collection().index,
      icon: <SettingOutlined />,
      allowFor: ['admin', 'manager']
    }),
    {
      label: (
        <Space size={0}>
          {formatMessage({ id: 'sidebar.help_center' })}{' '}
          <span className="d-flex pl-2" role="img">
            <ExternalLinkOutlined />
          </span>
        </Space>
      ),
      key: 'help_center',
      icon: <InfoCircleOutlined />
    }
  ].filter(Boolean)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const getSelectedKeys = () => {
    const locationArr = pathname.slice(1).split('/')

    const basePath = `/${locationArr[0]}`

    if (locationArr[1]) {
      const combinedPath = `${basePath}/${locationArr[1]}`
      const subMenuKeys = menuItems
        .filter((i) => Boolean(i.children?.length))
        .flatMap((i) => i.children)
        .map((i) => i.key)

      if (subMenuKeys.includes(combinedPath)) {
        return combinedPath
      } else {
        return basePath
      }
    } else {
      return basePath
    }
  }

  const onItemClick = (args: MenuInfo) => {
    if (args.key === 'help_center') {
      window.open(helpUrl)
    } else {
      navigate(args.key)
    }
    onClick(args)
  }

  const activeKey = getSelectedKeys()

  return (
    <AntMenu
      defaultSelectedKeys={['/']}
      activeKey={activeKey}
      selectedKeys={[activeKey]}
      mode="inline"
      onClick={onItemClick}
      data-testid={testIds.ui_elements.layout.menu}
      items={menuItems}
    />
  )
}

export default Menu
