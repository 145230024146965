import { Space } from 'antd'
import { KModalProps } from 'app/components/Modules/Feedback/typing'
import Modal from 'app/components/UI-Elements/Feedback/Modal'
import SpinOverlay from 'app/components/UI-Elements/Feedback/Spin'
import Button from 'app/components/UI-Elements/General/Button'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'

export const footerPortalId = 'modal-footer'

const KModal: React.FC<KModalProps> = ({
  title,
  recordType,
  action = 'confirm',
  onClose,
  onConfirm,
  loading,
  modalType = 'default',
  open,
  titleTranslationValues: values,
  footer = null,
  okButtonProps,
  cancelButtonProps,
  ...others
}) => {
  const { smartTranslation, tButtonTitle } = ToolBox.useString()
  const { buttonId, generateId } = ToolBox.useId()

  const dataTestId =
    recordType && action && generateId({ recordType, componentType: 'modal', id: action })
  const modalTitle =
    open &&
    (title ||
      smartTranslation({
        values,
        recordType,
        id: `component.feedback.modal.title.${recordType}.${action}`
      }))
  const handleConfirm = (e: React.MouseEvent<HTMLElement>) => {
    onConfirm?.(e)
    onClose?.(e)
  }

  const getFooter = () => (
    <Choose>
      <When condition={modalType === 'form'}>
        <div id={footerPortalId}></div>
      </When>
      <When condition={footer}>{footer}</When>
      <When condition={modalType === 'info'}>
        <Button
          type="primary"
          onClick={onClose}
          id={buttonId({ recordType, action: 'close' })}
          {...cancelButtonProps}>
          {open && tButtonTitle({ recordType, action: 'close' })}
        </Button>
      </When>
      <Otherwise>
        <Space>
          <Button
            type="secondary"
            onClick={onClose}
            id={buttonId({ recordType, action: 'cancel' })}
            {...cancelButtonProps}>
            {open && tButtonTitle({ recordType, action: 'cancel' })}
          </Button>
          <Button
            type="primary"
            onClick={handleConfirm}
            id={buttonId({ recordType, action })}
            {...okButtonProps}>
            {open && tButtonTitle({ recordType, action })}
          </Button>
        </Space>
      </Otherwise>
    </Choose>
  )

  return (
    <Choose>
      <When condition={loading}>
        <SpinOverlay loading={true} />
      </When>
      <Otherwise>
        <Modal
          footer={getFooter()}
          type={modalType}
          title={modalTitle}
          open={open}
          closable={modalType !== 'confirm'}
          onCancel={onClose}
          data-testid={dataTestId}
          {...others}
        />
      </Otherwise>
    </Choose>
  )
}

export default KModal
