import { Row } from 'antd'
import { classNames } from 'app/lib'
import { ReactNode } from 'react'
import React, { ComponentProps } from 'react'

import KCol from './KCol'

interface Props extends Partial<ComponentProps<typeof KCol>> {
  card?: boolean
  loading?: boolean
  children: ReactNode
}

const Container: React.FC<Props> = ({ card = true, loading, children, className, ...others }) => {
  return (
    <Row justify="center">
      <KCol
        span={24}
        card={card}
        loading={loading}
        className={classNames('max-width-1200', className)}
        {...others}>
        {children}
      </KCol>
    </Row>
  )
}

export default Container
