import { dayjs } from '@2wunder/klarx-tool'
import { CalendarOutlined } from '@ant-design/icons'
import { Col, DatePicker, Form, Row } from 'antd'
import { Calendar } from 'antd-mobile'
import KDatePicker from 'app/components/Modules/Form/KDatePicker'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KDateRangeProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import MobileFriendly from 'app/components/UI-Elements/General/MobileFriendly'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { dateFormat, useDate } from 'app/lib/hooks/toolBox'
import DateTimeService from 'app/lib/services/_DateTimeService'
import React, { ComponentProps, useEffect, useState } from 'react'

import KTimeRange from './KTimeRange'

const { RangePicker } = DatePicker

// Do not change the order of these values. getValueProps() depends on it.
const ParseFormat = [dateFormat.datetime, dateFormat.date]

interface Props extends Omit<ComponentProps<typeof Calendar>, 'minDate'> {
  onChange?: (values: [Dayjs, Dayjs]) => void
  value?: [Dayjs, Dayjs]
  disabled?: boolean
  showTime?: boolean
  disablePast?: boolean
  minDate?: string
}

export const MobileRangePicker = ({
  onChange,
  value,
  minDate,
  disablePast,
  disabled,
  showTime,
  ...others
}: Props) => {
  const {
    dayjsify: { convert, revert, now: nowDayjs },
    datify: { now }
  } = useDate()
  const [visible, setVisible] = useState(false)

  const onConfirm = (startDate: Date, returnDate: Date): void => {
    onChange([dayjs(startDate).startOf('day'), dayjs(returnDate).endOf('day')])
    setVisible(false)
  }

  const getDateExtra = (date: Date) => {
    const dayjsDate = dayjs(date)

    const disableWithMinDate = minDate && convert(minDate, 'date').isAfter(dayjsDate)
    const disableWithToday = disablePast && nowDayjs.isAfter(dayjsDate)

    return {
      disable: disableWithMinDate || disableWithToday,
      cellCls: revert(dayjsDate, 'base')
    }
  }

  return (
    <Row gutter={[0, 12]}>
      <Col span={24}>
        <Button
          icon={<CalendarOutlined />}
          title={`${revert(value[0], 'date')} - ${revert(value[1], 'date')}`}
          onClick={() => setVisible(true)}
          disabled={disabled}
        />
        <Calendar
          visible={visible}
          onCancel={() => setVisible(false)}
          defaultDate={now}
          defaultValue={[value[0].toDate(), value[1].toDate()]}
          onConfirm={onConfirm}
          getDateExtra={getDateExtra}
          {...others}
        />
      </Col>
      <If condition={showTime}>
        <Col span={24}>
          <KTimeRange disabled={disabled} {...others} />
        </Col>
      </If>
    </Row>
  )
}

const range = (start: number, end: number) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

const KDateRange: React.FC<KDateRangeProps> = ({
  startName,
  endName,
  name,
  disabled,
  disablePast,
  maxDateValue,
  showTime,
  minDate,
  ...others
}) => {
  const format = showTime ? dateFormat.datetime : dateFormat.date

  const { form } = useKFormContext()

  const startValue = Form.useWatch(startName, form)
  const endValue = Form.useWatch(endName, form)

  useEffect(() => {
    // Update the main field if the start or end field changes e.g. by remote data
    form.setFieldValue(name, [dayjs(startValue, ParseFormat), dayjs(endValue, ParseFormat)])
  }, [startValue, endValue])

  const {
    dayjsify: { convert }
  } = useDate()

  const onChange = (values: [Dayjs, Dayjs]) => {
    form.setFields([
      { name: startName, value: values?.[0]?.format(format) || dayjs().format(format) },
      { name: endName, value: values?.[1]?.format(format) || dayjs().format(format) }
    ])
  }

  const disabledDate = (current: Dayjs): boolean => {
    const disableWithPast = disablePast && DateTimeService.checkIfDayjsDatePast(current)
    const disableWithMax =
      maxDateValue && !DateTimeService.checkIfDateAfterDayjsDate(maxDateValue, current)
    const disableWithMin = minDate && convert(minDate, 'date').isAfter(current)

    return disableWithPast || disableWithMax || disableWithMin
  }

  const disabledDateTime = () => {
    return {
      disabledMinutes: () => [...range(1, 30), ...range(31, 60)]
    }
  }

  const getValueProps = () => {
    const startValue = [form.getFieldValue(startName), showTime && form.getFieldValue('start_time')]
      .filter((i) => Boolean(i))
      .join(' ')
    const endValue = [form.getFieldValue(endName), showTime && form.getFieldValue('return_time')]
      .filter((i) => Boolean(i))
      .join(' ')

    // Warning: This whole logic relies on parsing 01.01.2020 04:00 05:00 to 01.01.2020 04:00
    return {
      value: [
        dayjs(startValue || dayjs().format(format), ParseFormat),
        dayjs(endValue || dayjs().format(format), ParseFormat)
      ]
    }
  }

  return (
    <>
      <KFormItem name={name} getValueProps={getValueProps} {...others}>
        <MobileFriendly
          onChange={onChange}
          desktop={
            <RangePicker
              className="full-width"
              disabledDate={disabledDate}
              format={format}
              disabled={disabled && [true, true]}
              minDate={dayjs(minDate, 'date')}
              disabledTime={disabledDateTime}
              showTime={
                showTime
                  ? {
                      hideDisabledOptions: true
                    }
                  : false
              }
            />
          }
          mobile={<MobileRangePicker showTime={showTime} minDate={minDate} disabled={disabled} />}
        />
      </KFormItem>
      <span className="d-none">
        <KDatePicker name={startName} showLabel={false} />
        <KDatePicker name={endName} showLabel={false} />
      </span>
    </>
  )
}

export default KDateRange
