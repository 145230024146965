/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Preferences } from '@capacitor/preferences'

const StorageService = {
  get: async (option: { key: string }) => await Preferences.get(option),
  set: async (option: { key: string; value: string }) => await Preferences.set(option),
  remove: async (option: { key: string }) => await Preferences.remove(option),
  clear: async () => await Preferences.clear(),
  keys: async () => await Preferences.keys(),
  migrate: async () => await Preferences.migrate()
}

export default StorageService
