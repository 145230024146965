/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import EnvironmentService from 'app/lib/services/EnvironmentService'
const tracking_id = EnvironmentService.getEnvValue('BING_TRACKING_ID')

export default function initBingAnalytics(history) {
  if (EnvironmentService.isProduction()) {
    (function (w, d, t, r, u) {var f, n, i; w[u] = w[u] || [], f = function () {var o = {ti: tracking_id}; o.q = w[u], w[u] = new initUET(o), w[u].push("pageLoad")}, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function () {var s = this.readyState; s && s !== "loaded" && s !== "complete" || (f(), n.onload = n.onreadystatechange = null)}, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i)})(window, document, "script", "//bat.bing.com/bat.js", "uetq");
    history.listen(pageLoadEvent);
  }
}

function pageLoadEvent() {
  const o = { ti: tracking_id }
  o.q = window.uetq
  window.uetq = new initUET(o)
  window.uetq.push('pageLoad')
}

function initUET(o) {
  if (typeof UET === 'function') {
    return new UET(o)
  } else {
    return []
  }
}
