import EnvironmentService, { Environment } from 'app/lib/services/EnvironmentService'

type HookType = {
  notProduction: boolean
}
export const useFeatureToggle = (): HookType => {
  const notProduction = EnvironmentService.isOneOf([Environment.Dev, Environment.Staging])

  return { notProduction }
}
