import { CheckOutlined } from '@ant-design/icons/lib'
import { Popconfirm } from 'antd'
import { KSubmitProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'

const KSubmit: React.FC<KSubmitProps> = ({
  visualAction,
  loading,
  disabled,
  hidden,
  action,
  children,
  title,
  showIcon = false,
  ...others
}) => {
  const { visualRecordType, recordType, form, confirmOnSubmit } = useKFormContext()
  const { smartTranslation } = ToolBox.useString()
  const { tSubmitTitle } = ToolBox.useFormTools()
  const { buttonId } = ToolBox.useId()
  const id = buttonId({ recordType: visualRecordType, action, suffix: 'submit' })

  const getConfirmTitle = () => {
    if (!confirmOnSubmit) return ''

    return smartTranslation({
      id: `form.${visualRecordType}.prompt.${action}`,
      recordType: visualRecordType
    })
  }

  const getTitle = () => {
    if (title) return title
    if (hidden) return ''

    return tSubmitTitle({
      recordType: visualRecordType || recordType,
      action: visualAction || action,
      disabled
    })
  }

  return (
    <Popconfirm
      disabled={!confirmOnSubmit}
      onConfirm={form.submit}
      placement={'topRight'}
      getPopupContainer={(target) => target}
      title={getConfirmTitle}>
      <Choose>
        <When condition={Boolean(children)}>{children}</When>
        <Otherwise>
          <Button
            htmlType="submit"
            id={id}
            data-testid={id}
            icon={showIcon && <CheckOutlined />}
            hidden={hidden}
            disabled={disabled}
            loading={loading}
            title={getTitle()}
            {...others}
          />
        </Otherwise>
      </Choose>
    </Popconfirm>
  )
}

export default KSubmit
