import { FormRecord } from 'app/components/Modules/Form/typing'
import { Reducer, ReducerAction, ReducerKeys, ReducerRow } from 'app/redux/typing'

export function persister<T extends FormRecord>(
  state: Reducer<T>,
  action: ReducerAction<T>,
  actionType: ReducerKeys
) {
  return syncher<T>({
    reducer: {
      ...state,
      ...{ [actionType]: reducefier(action.data) },
      ...{ hash: (+new Date()).toString(36) }
    },
    actionType
  })
}

export function syncher<T>({
  reducer,
  actionType
}: {
  reducer: Reducer<T>
  actionType: ReducerKeys
}) {
  const key = Object.keys(reducer[actionType])[0]
  switch (actionType) {
    case ReducerKeys.create:
      return { ...reducer, index: { ...{ [key]: reducer[actionType][key] }, ...reducer.index } }
    case ReducerKeys.destroy:
      Object.keys(reducer[actionType]).forEach((key: string) => delete reducer['index'][key])
      return { ...reducer }
    case ReducerKeys.update:
      return {
        ...reducer,
        index: { ...reducer.index, ...{ [key]: reducer[actionType][key] } },
        show: {
          ...reducer.show,
          ...(Object.keys(reducer.show).includes(key) && { [key]: reducer[actionType][key] })
        }
        // update not working
      }
    default:
      return { ...reducer }
  }
}

export function reducefier<T extends FormRecord>(data: T[] | T) {
  return {
    ...[data]
      .flat()
      .filter(Boolean)
      .reduce(
        (acc: ReducerRow<T>, el: T) => ({
          ...acc,
          // should remain as string otherwise reduce method will fucking change the order of the array
          // https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order
          [rowKey(el.id)]: el
        }),
        {}
      )
  }
}

const rowKey = (key: number) => `__${key}__`
