import { Grid } from 'antd'
import ProfileContent from 'app/components/Core/Profile/ProfileContent'
import KAvatar from 'app/components/UI-Elements/DataDisplay/KAvatar'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { useCurrentContact } from 'app/lib/hooks/useCurrent'
import * as React from 'react'

import Support from '../Customer/Support'

const ProfileAvatar: React.FC = () => {
  const {
    drawerfy: { open, close }
  } = useFeedbacker()
  const contact = useCurrentContact()
  const { md } = Grid.useBreakpoint()

  const onProfileClick = () => {
    open({
      content: <ProfileContent close={close} />,
      action: 'show',
      width: 300,
      recordType: RecordType.profile,
      destroyOnClose: true,
      className: !md && 'profile-drawer',
      footer: !md && <Support />
    })
  }

  const getInitials = (text: string) => {
    const initials = text?.match(/\b\w/g) || []
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
  }

  return (
    <div>
      <KAvatar className="pointer" size="large" type="primary" onClick={onProfileClick}>
        {getInitials(contact?.name || contact?.email)}
      </KAvatar>
    </div>
  )
}

export default ProfileAvatar
