import { useRequireUpdate } from 'app/lib/hooks/useAppVersion'
import { Routes } from 'app/routes/Routes'
import { treeRenderer } from 'app/routes/TreeRenderer'
import * as React from 'react'
import { Navigate, Route, Routes as Switch, useLocation } from 'react-router-dom'

import Update from '../Update/Views'
import Layout from './Container/layout'
import { AuthenticationRoutes as routes } from './RoutesConfig'

const LoginRedirect = () => {
  const location = useLocation()
  return (
    <Navigate
      to={`${Routes.Authentication.collection().login}?next_url=${location.pathname.substr(1)}`}
    />
  )
}

const AuthenticationRoutes = () => {
  const { requireUpdate } = useRequireUpdate()
  const tree = React.useMemo(() => treeRenderer(routes), [])

  if (requireUpdate) {
    return <Update />
  }

  return (
    <Layout>
      <Switch>
        {tree}
        <Route path="/*" element={<LoginRedirect />} />
      </Switch>
    </Layout>
  )
}

export default AuthenticationRoutes
