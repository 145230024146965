import { gql } from '@apollo/client/core'

export const CREATE_HUBSPOT_TICKET_FOR_OWNER_MUTATION = gql`
  mutation createHubspotTicketForOwner($input: CreateHubspotTicketForOwnerInput!) {
    createHubspotTicketForOwner(input: $input) {
      errors {
        path
        message
      }
    }
  }
`
