import { gql } from '@apollo/client/core'

import { RENTAL_CHECKOUT_ATTRIBUTES } from './fragments/Rental'

const UPDATE_RENTAL_DELIVERY_ADDRESS = gql`
  mutation updateRentalDeliveryAddress($input: DeliveryAddressInput!) {
    updateRentalDeliveryAddress(input: $input) {
      rental {
        ...rentalCheckoutAttributes
      }
      errors {
        message
      }
    }
  }
  ${RENTAL_CHECKOUT_ATTRIBUTES}
`

export { UPDATE_RENTAL_DELIVERY_ADDRESS }
