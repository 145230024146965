import { gql } from '@apollo/client/core'

const FETCH_ATTACHMENT_QUERY = gql`
  query fetchAttachment($id: ID!) {
    attachment(id: $id) {
      id
      name
      categorySegment {
        id
        name
        imageUrl
        earliestAvailableDateTime
      }
    }
  }
`

export { FETCH_ATTACHMENT_QUERY }
