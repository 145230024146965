import { List } from 'antd'
import { ListProps } from 'antd/lib/list'
import Button from 'app/components/UI-Elements/General/Button'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useIndex } from 'app/lib/hooks/useApi'
import { CollectionArgs } from 'app/redux/actions/typing'
import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'
import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Props<T> extends ListProps<T & any> {
  recordType?: string
  remember?: boolean
  showTotal?: boolean
  hideIfEmpty?: boolean
  params?: CollectionArgs
  displayHeader?: boolean
  visualRecordType?: string
}

function KList<T>({
  size = 'default',
  recordType,
  dataSource = [],
  params,
  remember = true,
  rowKey = 'id',
  showTotal,
  hideIfEmpty,
  displayHeader,
  visualRecordType,
  header,
  ...others
}: Props<T>) {
  const {
    response: { data, pagination },
    loading,
    execute
  } = useIndex<T>(recordType)
  const [fullData, setFullData] = useState<T[]>([])
  const testId = [recordType, 'klist'].join('-') // {recordType}-klist

  const { smartTranslation } = ToolBox.useString()

  useEffect(() => {
    if (dataSource.length === 0 && recordType) execute({ page: 1, per_page: 10, ...params })
  }, [])

  useEffect(() => {
    setFullData(uniqBy([...(remember ? fullData : []), ...data], rowKey))
  }, [data])

  const onLoadMore = () => {
    execute({
      page: pagination.current_page + 1,
      per_page: pagination.per_page,
      ...params
    })
  }

  const getRemaining = () => {
    return pagination?.total_count - pagination?.per_page * pagination?.current_page
  }

  const footer = (): React.ReactNode => {
    if (pagination && pagination.total_pages > 1 && pagination.next_page) {
      return (
        <Button
          size="small"
          loading={loading}
          onClick={onLoadMore}
          className="full-width"
          type="link">
          Mehr sehen ({getRemaining()})
        </Button>
      )
    } else if (!loading && showTotal) {
      return <span className="pull-right">{`${data.length || dataSource.length} Ergebnis`}</span>
    }
  }

  const empty = (): boolean => data.length === 0 && dataSource.length === 0

  const getHeader = () => {
    if (displayHeader) {
      const type = visualRecordType || recordType

      return (
        <h5 className="list-header">
          {header ||
            smartTranslation({ recordType: type, id: `component.data_display.list.title.${type}` })}
        </h5>
      )
    }
  }

  return (
    <List
      className={hideIfEmpty && empty() && 'd-none'}
      rowKey={rowKey}
      size={size}
      loading={loading}
      data-testid={testId}
      dataSource={[...fullData, ...dataSource]}
      footer={footer()}
      header={getHeader()}
      {...others}
    />
  )
}

export default KList
