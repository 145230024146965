import { Avatar as AntAvatar } from 'antd'
import { AvatarProps } from 'antd/lib/avatar'
import * as React from 'react'

interface Props extends AvatarProps {
  type: 'default' | 'primary' | 'secondary'
  onClick?: (e: React.MouseEvent) => void
}

const KAvatar: React.FC<Props> = ({ className, type, onClick, ...others }) => {
  const getClassName = (): string => {
    if (type === 'primary') {
      return 'avatar-primary'
    } else if (type === 'secondary') {
      return 'avatar-secondary'
    } else {
      return ''
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={onClick}>
      <AntAvatar className={`${className} ${getClassName()}`} {...others} />
    </div>
  )
}

export default KAvatar
