import { gql } from '@apollo/client/core'

const CUSTOMER_PRICE_LIST_QUERY = gql`
  query customerPriceList {
    customerPriceList {
      id
      deliveryPriceRate
      validFrom
      validTo
      customerTargetRevenue
      insurancePolicy
      referencePriceList
      priceListItemsByCategories {
        id
        ranges
        priceListItems {
          id
          prices {
            id
            amount
            range
          }
          insurancePrice {
            amount
          }
          categorySegment {
            name
            id
          }
        }
        category {
          name
        }
      }
      priceListDeliveryItems {
        label
        discountedMaxPrice
        discountedMinPrice
        composableType
      }
    }
  }
`

export { CUSTOMER_PRICE_LIST_QUERY }
