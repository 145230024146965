import { Select as AntdSelect, Spin } from 'antd'
import * as React from 'react'

import { SelectProps, SelectValueOptionTypes } from '../../Modules/Form/typing'

const { Option } = AntdSelect

function Select<T extends { id?: number | string; [key: string]: string | number | any }>({
  entries,
  value,
  selectValueBy = 'id',
  onChange,
  dropDownFooter,
  loading = false,
  optionLabelProp,
  children,
  selectLabelBy,
  ...others
}: SelectProps<T>) {
  const renderOptions = (): React.ReactNode => {
    return entries.map((entry, index) => (
      <Option key={`${entry[selectValueBy]}-${index}`} value={entry[selectValueBy]}>
        {entry[selectLabelBy]}
      </Option>
    ))
  }

  return (
    <AntdSelect<SelectValueOptionTypes>
      value={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <>
          <Spin spinning={loading}>{menu}</Spin>
          {dropDownFooter}
        </>
      )}
      getPopupContainer={(target) => target}
      optionLabelProp={optionLabelProp}
      {...others}>
      {children ? children : renderOptions()}
    </AntdSelect>
  )
}

export default Select
