import { dayjs } from '@2wunder/klarx-tool'
import { DatePicker } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KDatePickerProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { dateFormat } from 'app/lib/hooks/toolBox'
import React, { ComponentProps, useMemo } from 'react'

import { FormItemProps } from './types'

const range = (start: number, end: number) => {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}
// Used to parse min and max date
export const parseDate = (date: string) => dayjs(date, [dateFormat.date, dateFormat.base])

const KDesktopDatePicker: React.FC<KDatePickerProps & FormItemProps<dayjs.Dayjs, string>> = ({
  name,
  showTime,
  maxDate,
  minDate,
  disablePast,
  ...others
}) => {
  const formatType = showTime ? dateFormat.datetime : dateFormat.date
  const now = dayjs()
  // Used to parse the value date, which can include time
  const convert = (date: string) => dayjs(date, formatType) as Dayjs
  const revert = (date: Dayjs) => date.format(formatType)
  const minDateDayjs = dayjs(minDate, [dateFormat.date, dateFormat.base])
  const maxDateDayjs = dayjs(maxDate, [dateFormat.date, dateFormat.base])

  // TODO: 1 hour is hardcoded this should be a prop
  const getRoundUpDate = useMemo(() => now.add(1, 'hour').startOf('hour'), [])

  const { form } = useKFormContext()

  const Picker = ({
    value,
    ...others
  }: ComponentProps<typeof DatePicker> & FormItemProps<Dayjs, string>) => {
    const getDisabledDates = (current: dayjs.Dayjs) => {
      return (
        (minDate && minDateDayjs.isValid() && current.isBefore(minDateDayjs, 'day')) ||
        (disablePast && current.isBefore(now, 'day')) ||
        (maxDate && maxDateDayjs.isValid() && current.isAfter(maxDateDayjs, 'day'))
      )
    }

    const disabledDateTime = () => {
      let removedHours = 0

      if (disablePast && convert(value).get('D') === getRoundUpDate.get('D')) {
        removedHours = getRoundUpDate.get('hour')
      }

      const skippedOptions = range(0, 6).concat(range(21, 24))
      return {
        disabledHours: () => skippedOptions.concat(range(0, removedHours)),
        disabledMinutes: () => range(1, 60) // just show 00
      }
    }

    return (
      <DatePicker
        format={formatType}
        disabledDate={getDisabledDates}
        disabledTime={disabledDateTime}
        showTime={
          showTime
            ? {
                hideDisabledOptions: true,
                defaultValue: getRoundUpDate as Dayjs
              }
            : false
        }
        value={value ? convert(value) : getRoundUpDate}
        showNow={false}
        {...others}
      />
    )
  }

  return (
    <KFormItem
      initialValue={form.getFieldValue(name) || revert(getRoundUpDate)}
      getValueFromEvent={(value: dayjs.Dayjs) => revert(value || now)}
      name={name}
      {...others}>
      <Picker />
    </KFormItem>
  )
}

export default KDesktopDatePicker
