/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-lines */
/* eslint-disable no-prototype-builtins */
import { dayjs } from '@2wunder/klarx-tool'
import { GenericObject } from 'app/constants/GlobalTypes'
import { Position } from 'app/lib/typing'
import { CollectionArgs, FilterType } from 'app/redux/actions/typing'
import { LocaleTree } from 'lngProvider'
import {
  capitalize as _capitalize,
  get,
  isArray,
  isBoolean,
  isEmpty,
  isNumber,
  isPlainObject,
  isString,
  snakeCase,
  trim
} from 'lodash'
import moment, { Moment } from 'moment'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'

type RichTextFormattingType = (value: string) => string | number | boolean | ReactNode

export const ToolBox = {
  flattenPayload: (payload: any, prefix?: string, result?: any) => {
    prefix = prefix ? prefix : ''
    result = result ? result : {}
    if (isString(payload) || isNumber(payload) || isBoolean(payload)) {
      result[prefix] = payload
      return result
    }
    if (isArray(payload) || isPlainObject(payload)) {
      // eslint-disable-next-line prefer-const
      for (let i in payload) {
        let pref = prefix
        if (isArray(payload)) {
          pref = pref + `[${i}]`
        } else {
          if (isEmpty(prefix)) {
            pref = i
          } else {
            pref = prefix + '.' + i
          }
        }
        ToolBox.flattenPayload(payload[i], pref, result)
      }
      return result
    }
    return result
  },
  useMapTools: () => {
    function geofy<T extends { latitude: number; longitude: number }>(
      collection: T[]
    ): Array<T & Position> {
      return collection
        .filter((member: T) => {
          if (!member.hasOwnProperty('latitude') || !member.hasOwnProperty('longitude'))
            throw new Error('Object cannot be used as geo data.')
          return Boolean(member.latitude) && Boolean(member.longitude)
        })
        .map((member: T) => ({
          ...member,
          lat: member.latitude,
          lng: member.longitude
        }))
    }

    return { geofy }
  },
  useString: () => {
    const intl = (() => {
      try {
        return useIntl()
      } catch (e) {
        return {} as any
      }
    })()
    const getInitials = (text: string): string => {
      // eslint-disable-next-line prefer-const
      let initials = text?.match(/\b\w/g) || []
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
    }

    const capitalize = (string: string) => {
      return _capitalize(string)
    }

    const constantize = (string: string) => {
      return string.replace(/(^|_)./g, (s) => s.slice(-1).toUpperCase())
    }

    const smartTranslation = ({
      recordType,
      id,
      values
    }: {
      recordType: string
      id: string
      values?: GenericObject<string | number | ReactNode | RichTextFormattingType>
    }) => {
      if (tPayload(id)) {
        return intl.formatMessage({ id }, values)
      } else {
        return intl.formatMessage({ id: id.replace(`.${recordType}.`, '.default.') }, values)
      }
    }

    const tPlaceholder = ({ recordType, attribute }: { recordType: string; attribute: string }) => {
      return intl.formatMessage({
        id: `form.${recordType}.placeholder.${attribute}`,
        defaultMessage: humanize(attribute)
      })
    }

    const tAttribute = ({
      recordType,
      attribute,
      values
    }: {
      recordType: string
      attribute: string
      values?: GenericObject<string | React.ReactNode | RichTextFormattingType>
    }) => {
      return intl.formatMessage(
        { id: `record.${recordType}.attributes.${attribute}`, defaultMessage: humanize(attribute) },
        values
      )
    }

    const tPayload = (path: string) => {
      const locale = intl.locale.split('-')[0]
      return get(LocaleTree[locale], path.split('.'), null)
    }

    const tRecordType = (
      recordType: string,
      id?: string,
      values?: GenericObject<string | React.ReactNode | RichTextFormattingType>
    ) => {
      return intl.formatMessage(
        { id: `record.${recordType}.${id || recordType}`, defaultMessage: humanize(recordType) },
        values
      )
    }

    const tComponent = ({
      componentType,
      componentName,
      recordType = 'default',
      id,
      values
    }: {
      componentType: string
      componentName: string
      recordType?: string
      id: string
      values?: GenericObject<string | number | ReactNode | RichTextFormattingType>
    }) => {
      return smartTranslation({
        id: `component.${componentType}.${componentName}.${recordType}.${id}`,
        recordType,
        values
      })
    }

    const tButtonTitle = ({ recordType, action }: { recordType: string; action: string }) => {
      const prefix = `component.general.button.title`
      if (tPayload(`${prefix}.${recordType}.${action}`)) {
        return intl.formatMessage({
          id: `${prefix}.${recordType}.${action}`,
          defaultMessage: humanize(action)
        })
      } else {
        return intl.formatMessage({
          id: `${prefix}.default.${action}`,
          defaultMessage: humanize(action)
        })
      }
    }

    const humanize = (str: string) => {
      return capitalize(trim(snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')))
    }

    const combineStrings = (strings: Array<string>, seperator = '') => {
      return strings.filter((str) => str && str.trim()).join(seperator)
    }

    return {
      getInitials,
      capitalize,
      constantize,
      smartTranslation,
      tAttribute,
      tPayload,
      snakeCase,
      humanize,
      tRecordType,
      tComponent,
      tButtonTitle,
      tPlaceholder,
      combineStrings
    }
  },
  useId: () => {
    const generateId = ({
      recordType,
      componentType,
      id,
      suffix
    }: {
      recordType: string
      componentType: string
      id: string
      suffix?: string | number
    }) => {
      if (!recordType && !id) {
        return undefined
      }
      const _suffix = suffix ? `-${suffix}` : ''
      return `${recordType}-${componentType}-${id}${_suffix}`
    }

    const buttonId = ({
      recordType,
      action,
      suffix
    }: {
      recordType: string
      action: string
      suffix?: string | number
    }) => {
      return generateId({ recordType, id: action, componentType: 'button', suffix })
    }

    const formId = ({
      recordType,
      action,
      suffix
    }: {
      recordType: string
      action: string
      suffix?: string | number
    }) => {
      return generateId({ recordType, id: action, componentType: 'form', suffix })
    }

    const tableRow = ({ recordType, id }: { recordType: string; id?: string | number }) => {
      return generateId({ recordType, componentType: 'table_row', id: String(id) })
    }
    return { generateId, buttonId, formId, tableRow }
  },
  parameterize: (params?: CollectionArgs): string => {
    if (params) {
      const { includes, filters, sort, ...others } = params
      const full = [
        ToolBox.includefize(includes),
        ToolBox.filterize(filters),
        ToolBox.sortify(sort),
        new URLSearchParams(others as any).toString()
      ]
      return `?${full.filter(Boolean).join('&')}`
    } else {
      return ''
    }
  },
  includefize: (includes: string[]): string => {
    if (!includes) return ''
    return `includes[]=${includes.join('&includes[]=')}`
  },
  filterize: (filters: FilterType): string => {
    if (!filters) return ''
    const multiple = 'filter[#][]=$'
    const single = 'filter[#]=$'
    let initials = ''
    Object.keys(filters).forEach((key: any) => {
      const filterValue: any = filters[key]
      if (Array.isArray(filterValue)) {
        initials =
          initials.concat(
            filterValue.map((val: string) => multiple.replace('#', key).replace('$', val)).join('&')
          ) + '&'
      } else {
        initials = initials.concat(single.replace('#', key).replace('$', filterValue)).concat('&')
      }
    })
    return initials
  },
  sortify: (sort: string): string => {
    if (!sort) return ''
    return `sort=${sort}`
  },
  validaton: () => {
    const hasLetter = (value: string) => /[a-zA-Z]/g.test(value)
    const hasNumber = (value: string) => /\d/.test(value)

    return { hasLetter, hasNumber }
  },
  useFormTools: () => {
    const createFormData = (payload: GenericObject<any>, prefix = 'document') => {
      const data = new FormData()
      Object.keys(payload).forEach((key) => data.append(`${prefix}[${key}]`, payload[key]))
      return data
    }

    const getRadioOptions = ({
      recordType,
      attribute
    }: {
      recordType: string
      attribute: string
    }) => {
      const { tPayload } = ToolBox.useString()
      return tPayload(`form.${recordType}.options.${attribute}`)
    }

    const getFormError = ({
      recordType,
      id,
      values
    }: {
      recordType: string
      id: string
      values?: GenericObject<ReactNode>
    }) => {
      const { formatMessage } = useIntl()

      return formatMessage({ id: `form.${recordType}.error.${id}` }, values)
    }

    const getFormDescription = ({
      recordType,
      values
    }: {
      recordType: string
      values?: GenericObject<ReactNode>
    }) => {
      const { formatMessage } = useIntl()

      return formatMessage({ id: `form.${recordType}.description.${recordType}` }, values)
    }

    const getFormItemDescription = ({
      recordType,
      attribute,
      values
    }: {
      recordType: string
      attribute: string
      values?: GenericObject<ReactNode>
    }) => {
      const { formatMessage } = useIntl()
      return formatMessage({ id: `form.${recordType}.description.attributes.${attribute}` }, values)
    }

    const tSubmitTitle = ({
      recordType,
      action,
      disabled
    }: {
      recordType: string
      action: string
      disabled?: boolean
    }) => {
      const { tPayload, smartTranslation } = ToolBox.useString()
      const id = `form.${recordType}.action.disabled.${action}`
      if (disabled && tPayload(id)) {
        return smartTranslation({ recordType, id })
      } else {
        return smartTranslation({ recordType, id: `form.${recordType}.action.${action}` })
      }
    }

    return {
      createFormData,
      getRadioOptions,
      getFormDescription,
      getFormError,
      getFormItemDescription,
      tSubmitTitle
    }
  }
}

export const dateFormat = {
  date: 'DD.MM.YYYY',
  time: 'HH:mm',
  datetime: 'DD.MM.YYYY HH:mm',
  base: 'YYYY-MM-DD'
}
export const useDate = () => {
  const intl = (() => {
    try {
      return useIntl()
    } catch (e) {
      return {} as any
    }
  })()

  const format = dateFormat
  type DateType = keyof typeof dateFormat

  const momentify = {
    convert: (dateString: string | Date, type: DateType = 'datetime'): Moment =>
      moment(dateString, format[type]),
    revert: (date: Moment, type: DateType = 'datetime'): string => date.format(format[type]),
    now: moment(),
    tomorrow: moment().add(1, 'day'),
    convertString: (
      dateString: string,
      stringType: DateType = 'base',
      outputType: DateType = 'date'
    ) => {
      if (!dateString) return '-'
      const moment = momentify.convert(dateString, stringType)
      return momentify.revert(moment, outputType)
    }
  }

  const dayjsify = {
    convert: (dateString: string, type: DateType = 'datetime'): Dayjs =>
      dayjs(dateString, format[type]),
    revert: (date: Dayjs, type: DateType = 'datetime'): string => date.format(format[type]),
    now: dayjs(),
    tomorrow: dayjs().add(1, 'day'),
    convertString: (
      dateString: string,
      stringType: DateType = 'base',
      outputType: DateType = 'date'
    ) => {
      if (!dateString) return '-'
      const dayjs = dayjsify.convert(dateString, stringType)
      return dayjsify.revert(dayjs, outputType)
    }
  }

  const otherDateFormat = {
    date: 'MM/DD/YYYY',
    time: 'HH:mm',
    datetime: 'MM/DD/YYYY HH:mm'
  }

  const datify = {
    convert: (dataString: string, type: keyof typeof format = 'datetime') =>
      new Date(momentify.convert(dataString, type).format(otherDateFormat['datetime'])),
    revert: (value: Date, type: keyof typeof format = 'datetime'): string =>
      momentify.revert(moment(value), type),
    now: new Date(),
    nextHour: (date: Date = new Date()): Date => {
      date.getMinutes() !== 0 && date.setHours(date.getHours() + 1)
      date.setMinutes(0, 0, 0)
      return date
    }
  }

  const calendar = (value: Moment) => {
    const getTranslation = (id: string) =>
      intl.formatMessage({ id: `component.data_entry.date_picker.${id}` })

    return value.calendar(null, {
      lastDay: `[${getTranslation('yesterday')}], L`,
      sameDay: `[${getTranslation('today')}], L`,
      nextDay: `[${getTranslation('tomorrow')}], L`,
      lastWeek: 'L',
      nextWeek: 'L',
      sameElse: 'L'
    })
  }

  return { momentify, datify, dayjsify, calendar, format }
}
