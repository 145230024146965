import { Routes } from 'app/routes/Routes'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'

import { routes } from './RoutesConfig'
import { treeRenderer } from './TreeRenderer'

const Root = () => {
  const { locale } = useIntl()

  const tree = React.useMemo(() => treeRenderer(routes), [locale])

  return (
    <Switch>
      <Route
        key={Routes.Default}
        path={Routes.Default}
        element={<Navigate to={Routes.Overview.Base} />}
      />
      <Route
        key={'/dashboard'}
        path={'/dashboard'}
        element={<Navigate to={Routes.Overview.Base} />}
      />
      {tree}
    </Switch>
  )
}

export default Root
