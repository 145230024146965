import Bugsnag from '@bugsnag/js'

export const retry = (fn: () => Promise<unknown>, retriesLeft = 2, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((e) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            // reject(error);
            console.warn({ chunkError: e })
            Bugsnag.notify('Chunk failed. But it has reloaded', (event) => {
              event.severity = 'info'
            })
            window.location.reload()
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
