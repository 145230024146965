import { Row } from 'antd'
import { MemberProps } from 'app/components/Core/convention'
import KForm from 'app/components/Modules/Form/KForm'
import KFormInfo from 'app/components/Modules/Form/KFormInfo'
import KInputText from 'app/components/Modules/Form/KInputText'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { RecordType } from 'app/constants/GlobalTypes'
import * as React from 'react'

const Form: React.FC<MemberProps<IInvitation>> = ({ member }) => {
  return (
    <KForm
      portalId="invitation-submit"
      initialFetch={false}
      submitButtonProps={{ type: 'secondary' }}
      initialValues={member}
      recordType={RecordType.invitation}
      layout="vertical">
      <Row>
        <KCol span={24}>
          <KFormInfo name="contact_organization_id" />
        </KCol>
        <KCol span={24}>
          <KInputText name="contact_organization_id" hidden />
        </KCol>
      </Row>
    </KForm>
  )
}

export default Form
