export enum PaymentTypeEnum {
  credit_card = 'credit_card',
  invoice = 'invoice',
  paypal = 'paypal'
}

export enum RentalSuccessStatus {
  created = 'created',
  accepted = 'accepted',
  paid = 'paid',
  booked = 'booked'
}

export enum TransactionalSuccessType {
  missingInformation = 'missing_information',
  quote_feedback = 'quote_feedback'
}

export enum ComposableTypeEnum {
  hebetechnik = 'hebetechnik',
  erdbewegungsmaschinen = 'erdbewegungsmaschinen',
  lkw_teleskopbuehnen = 'lkw_teleskopbuehnen'
}

export enum LocaleEnum {
  en = 'en',
  de = 'de'
}

export const InvoiceStatus = ['paid', 'unpaid', 'due', 'canceled']
export const InvoiceDamagesOptions = ['damage', 'not_damage']
