import { dayjs } from '@2wunder/klarx-tool'

import { dateFormat } from '../hooks/toolBox'

class DateTimeService {
  static MONTH_IN_MILLISECONDS = 2678400000

  /**
   * Converts provided date to german date format
   * @param  date
   * @return Date in german format
   */

  static convertFromDayjsDate = (date: Dayjs, format: string = dateFormat.date) => {
    return dayjs.isDayjs(date) ? date.format(format) : ''
  }

  static convertFromDayjsDateToTime = (date: Dayjs) => {
    return dayjs.isDayjs(date) ? date.format(dateFormat.time) : ''
  }

  static convertToDayjsDate = (germanDate: string, format: string = dateFormat.date): Dayjs => {
    return germanDate ? dayjs(germanDate, format) : dayjs()
  }

  static convertToDayjsTime = (time: string) => {
    return dayjs(time, dateFormat.time)
  }

  static convertToDayjsWithFormat = (germanDate: string, format: string): Dayjs => {
    return germanDate ? dayjs(germanDate, format) : dayjs()
  }

  static getTwoDaysAfter = () => {
    //Monday --> Wednesday -> 2
    //Tuesday --> Thursday -> 2
    //Wednesday --> Friday -> 2
    //Thursday --> Monday -> 4
    //Friday --> Tuesday -> 4
    //Saturday --> Tuesday -> 3
    //Sunday --> Tuesday -> 2

    if (DateTimeService.isSaturday(dayjs())) {
      return dayjs().add(3, 'days')
    } else if (DateTimeService.isFriday(dayjs()) || DateTimeService.isThursday(dayjs())) {
      return dayjs().add(4, 'days')
    } else {
      return dayjs().add(2, 'days')
    }
  }

  static convertFromDayjsDateAndTime = (value: string) => {
    return dayjs(value).format('DD.MM.YYYY HH:mm')
  }

  static checkIfDatePast = (date: string) => {
    return dayjs().diff(DateTimeService.convertToDayjsDate(date), 'days') > 0
  }

  static checkIfDateFeature = (date: string) => {
    return DateTimeService.convertToDayjsDate(date).diff(dayjs(), 'days') >= 0
  }

  static checkIfDayjsDatePast = (date: Dayjs) => {
    const today = dayjs().format('YYYY-MM-DD')
    return dayjs(today).diff(date, 'days') >= 0
  }

  static checkIfDateAfterDayjsDate = (date1: string, date2: Dayjs) => {
    return DateTimeService.convertToDayjsDate(date1).isAfter(date2)
  }

  static isThursday = (date: Dayjs) => {
    return date.day() === 4
  }

  static isFriday = (date: Dayjs) => {
    return date.day() === 5
  }

  static isSaturday = (date: Dayjs) => {
    return date.day() === 6
  }

  static isSunday = (date: Dayjs) => {
    return date.day() === 0
  }

  static diffBetweenDates = (date1: string, date2: string) => {
    return dayjs(date1, dateFormat.date).diff(dayjs(date2, dateFormat.date), 'days')
  }

  static getYesterday = () => {
    return dayjs().add(-1, 'days') // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  static getTomorrow = () => {
    return dayjs().add(1, 'days') //.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  }

  static today = dayjs() // .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  static tomorrow = DateTimeService.getTomorrow()
  static yesterday = DateTimeService.getYesterday()
}

export default DateTimeService
