import { AutoComplete, Row } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import KInputText from 'app/components/Modules/Form/KInputText'
import KMap from 'app/components/Modules/Form/KMap'
import { KAddressInputProps } from 'app/components/Modules/Form/typing'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { loadGoogleScripts } from 'app/lib/hooks/loadGoogleScripts'
import { useAddress } from 'app/lib/hooks/useAddress'
import * as React from 'react'
import { useState } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

const KAddressInput: React.FC<KAddressInputProps> = ({
  name,
  children,
  showMap = false,
  mapProps,
  names = {
    address1: 'address1',
    address2: 'address2',
    city: 'city',
    zip: 'zip',
    country: 'country',
    latitude: 'latitude',
    longitude: 'longitude'
  },
  searchExtra,
  inputProps = { zip: {}, city: {} },
  recordTypeOverride,
  label,
  defaultExpand = false,
  ...other
}) => {
  const { isLoaded } = loadGoogleScripts()

  const [expand, setExpand] = useState<boolean>(defaultExpand)
  const [q, setQ] = useState<string>('')
  const { form, hasErrors, nested, recordType: nestedRecordType } = useKFormContext()
  const { fetchResults } = useAddress()

  const getPayload = (name: string, payload: string | string[]) => {
    if (nested) {
      return { [`${nestedRecordType}`]: { [`${name}`]: payload } }
    } else {
      return { [`${name}`]: payload }
    }
  }

  const onSelect = async (address: string) => {
    const result = await fetchResults(address, names)

    Object.keys(result).forEach((name: keyof typeof result) => {
      form.setFieldsValue(getPayload(name as string, result[name]))
    })

    setQ(address)
    setExpand(true)
    form.validateFields()
  }

  return (
    <Row justify="center">
      <KCol span={24} loading={!isLoaded}>
        <PlacesAutocomplete value={q} onChange={setQ}>
          {({ getInputProps, suggestions }) => (
            <KFormItem
              name={name}
              recordTypeOverride={recordTypeOverride}
              {...getInputProps()}
              required={!hasErrors}
              label={label}>
              <AutoComplete onSelect={onSelect} getPopupContainer={(target) => target}>
                {suggestions.map((suggestion, index) => (
                  <AutoComplete.Option value={suggestion.description} key={index}>
                    {suggestion.description}
                  </AutoComplete.Option>
                ))}
              </AutoComplete>
            </KFormItem>
          )}
        </PlacesAutocomplete>
      </KCol>
      {searchExtra && <KCol>{searchExtra}</KCol>}
      <KCol span={24} hidden={!(hasErrors || expand)}>
        <Row gutter={[16, 0]}>
          <KCol span={12}>
            <KInputText {...other} recordTypeOverride={recordTypeOverride} name={names.address1} />
          </KCol>
          <KCol span={12}>
            <KInputText
              {...other}
              recordTypeOverride={recordTypeOverride}
              required={false}
              name={names.address2}
            />
          </KCol>
          <KCol span={12}>
            <KInputText
              {...other}
              {...inputProps.city}
              recordTypeOverride={recordTypeOverride}
              name={names.city}
            />
          </KCol>
          <KCol span={6}>
            <KInputText
              {...other}
              {...inputProps.zip}
              recordTypeOverride={recordTypeOverride}
              name={names.zip}
            />
          </KCol>
          <KCol span={6}>
            <KInputText {...other} recordTypeOverride={recordTypeOverride} name={names.country} />
          </KCol>
        </Row>
      </KCol>
      <KCol span={24} hidden={!children}>
        {children}
      </KCol>
      <If condition={showMap}>
        <KCol span={24}>
          <KMap {...mapProps} />
        </KCol>
      </If>
    </Row>
  )
}

export default KAddressInput
