import Bugsnag from '@bugsnag/js'
import { GeocoderAddressComponent } from 'app/components/Modules/Form/typing'
import { GenericObject } from 'app/constants/GlobalTypes'
import { geocodeByAddress } from 'react-places-autocomplete'

export interface INames {
  address1: string
  city: string
  country: string
  zip: string
  address2?: string
  latitude?: string
  longitude?: string
}

const defaultNames = {
  address1: 'address1',
  address2: 'address2',
  city: 'city',
  country: 'country',
  zip: 'zip',
  latitude: 'latitude',
  longitude: 'longitude'
}

export const useAddress = () => {
  const addressComponent = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    country: 'short_name',
    postal_code: 'short_name'
  }

  const parseAddress = (result: google.maps.GeocoderResult, names: INames = defaultNames) => {
    const model_attributes = {
      street_number: names.address1,
      route: names.address1,
      locality: names.city,
      country: names.country,
      postal_code: names.zip
    }
    const values: GenericObject<string> = {}

    if (names.longitude) {
      Object.assign(values, { [`${names.longitude}`]: result.geometry.location.lng() })
    }

    if (names.latitude) {
      Object.assign(values, { [`${names.latitude}`]: result.geometry.location.lat() })
    }

    result.address_components.forEach((component: GeocoderAddressComponent) => {
      component.types.forEach((type: keyof typeof addressComponent) => {
        if (addressComponent[type]) {
          const prevValue = values[model_attributes[type]]

          const value = prevValue
            ? `${component[addressComponent[type]]} ${prevValue}`.trim()
            : component[addressComponent[type]]

          Object.assign(values, { [`${model_attributes[type]}`]: value })
        }
      })
    })

    return values
  }

  const geocode = async (
    location:
      | {
          lat: number
          lng: number
        }
      | google.maps.LatLng,
    names: INames = defaultNames
  ) => {
    return new Promise<GenericObject<string>>((resolve, reject) => {
      const geo = new window.google.maps.Geocoder()
      if (!geo) {
        Bugsnag.notify('window.google.maps.Geocoder is undefined in useAddress')
      }

      geo.geocode({ location: location }, (results, status) => {
        if (status === 'OK') {
          if (!results) {
            resolve({})
          }
          const address = parseAddress(results[0], names)
          resolve({ fullAddress: results[0].formatted_address, ...address })
        } else {
          reject(status)
        }
      })
    })
  }

  const fetchResults = async (address: string, names: INames = defaultNames) => {
    const results = await geocodeByAddress(address)
    if (!results) return {}

    return parseAddress(results[0], names)
  }

  return { fetchResults, geocode, parseAddress }
}
