/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { LOGO_FILE } from 'app/constants/Global'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { Routes } from 'app/routes/Routes'
import * as React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import Restricted from '../../General/Restricted'

type Props = {
  showCancel?: boolean
}
const Logo: React.FC<Props> = ({ showCancel }) => {
  const {
    modalify: { open }
  } = useFeedbacker()
  const { tComponent } = ToolBox.useString()
  const navigate = useNavigate()

  const onClick = () => {
    const content = tComponent({
      componentType: 'feedback',
      componentName: 'modal.content',
      id: 'cancel_request',
      recordType: RecordType.checkout
    })

    open({
      modalType: 'confirm',
      recordType: RecordType.checkout,
      action: 'cancel_request',
      content: content,
      onConfirm: () => navigate(Routes.Rental.collection().index),
      destroyOnClose: true
    })
  }

  const Logo = () => <img src={LOGO_FILE} className="d-flex" style={{ width: 80 }} alt="Klarx" />

  return (
    <Choose>
      <When condition={showCancel}>
        <div className="pointer d-flex" onClick={() => onClick()}>
          <Logo />
        </div>
      </When>
      <Otherwise>
        <Restricted mode="hide" renderOnHide={() => <Logo />}>
          <Link to={Routes.Overview.collection().index} className="d-flex">
            <Logo />
          </Link>
        </Restricted>
      </Otherwise>
    </Choose>
  )
}

export default Logo
