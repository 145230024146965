import { InfoCircleOutlined } from '@ant-design/icons'
import { Card as AntCard, Divider, Tooltip } from 'antd'
import { CardProps } from 'antd/lib/card'
import * as React from 'react'

interface Props extends CardProps {
  children?: React.ReactNode
  title?: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
  infoText?: string | React.ReactNode
  bodyPadding?: number
}

const KCard: React.FC<Props> = ({
  children,
  title,
  subtitle,
  infoText,
  className = '',
  bodyPadding,
  ...others
}) => {
  // Check KTitle and merge if possible
  const getTitle = () => (
    <If condition={title}>
      <h3 className="mb-0 white-space-normal">{title}</h3>
      <If condition={subtitle}>
        <p className="mb-2 text-muted">{subtitle}</p>
      </If>
      <If condition={subtitle}>
        <Divider className="my-0" />
      </If>
    </If>
  )

  return (
    <AntCard
      bodyStyle={{ padding: bodyPadding, display: !children && 'none' }}
      title={getTitle()}
      className={`custom-card-wrapper ${className}`}
      {...others}>
      {children}
      {infoText && (
        <Tooltip placement="leftTop" title={infoText}>
          <InfoCircleOutlined className="info-icon" />
        </Tooltip>
      )}
    </AntCard>
  )
}

export default KCard
