import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row } from 'antd'
import { useBrowserRouterContext } from 'app/lib/contexts/KBrowserRouter'
import { useState } from 'react'
import * as React from 'react'
import { useEffect } from 'react'

import TitleExtra from '../TitleExtra'

const buttonContainerSize = '40px'

const MobileHeader: React.FC = () => {
  const [title, setTitle] = useState('')
  const { goBack } = useBrowserRouterContext()

  useEffect(() => {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      setTitle(mutations[0].target.textContent)
    })
    // the create element thing is for specs purpose
    observer.observe(document.querySelector('title') || document.createElement('div'), {
      subtree: true,
      characterData: true,
      childList: true
    })

    return () => observer.disconnect()
  }, [])

  return (
    <Layout.Header>
      <Row style={{ padding: '0 24px' }} wrap={false}>
        <Col flex={buttonContainerSize}>
          <Button
            data-testid="mobile-back-button"
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={goBack}
          />
        </Col>
        <Col flex="auto" className="text-center">
          <h5 className="text-truncate mb-0">
            {title}
            <TitleExtra />
          </h5>
        </Col>
        <Col flex={buttonContainerSize}>
          {/* Right spacing for correct centering of the title. Could be used for a navbar right button */}
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default MobileHeader
