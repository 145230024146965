import { GenericObject } from 'app/constants/GlobalTypes'

import deLang, { deTree } from './entries/de-DE'
import enLang, { enTree } from './entries/en-US'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LocaleTree: GenericObject<any> = {
  de: deTree,
  en: enTree
}

const AppLocale = {
  de: deLang,
  en: enLang
}

export default AppLocale
