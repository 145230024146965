import { gql } from '@apollo/client/core'

const PAGINATED_RECENT_MACHINE_QUERY = gql`
  query recentMachines {
    recentMachines: recentlyRentedProducts {
      id
      name
      categorySegment {
        id
        name
      }
      imageUrl
      product {
        id
        name
      }
      project {
        name
      }
    }
  }
`

export { PAGINATED_RECENT_MACHINE_QUERY }
