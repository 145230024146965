import { Row } from 'antd'
import { MemberProps } from 'app/components/Core/convention'
import KForm from 'app/components/Modules/Form/KForm'
import KInputText from 'app/components/Modules/Form/KInputText'
import KPasswordInput from 'app/components/Modules/Form/KPasswordInput'
import { KFormProps } from 'app/components/Modules/Form/typing'
import Icon from 'app/components/UI-Elements/General/Icon'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { RecordType } from 'app/constants/GlobalTypes'
import { useCurrentContact } from 'app/lib/hooks/useCurrent'
import * as React from 'react'

import PasswordValidation from './Validation'

const PasswordForm: React.FC<MemberProps<IUser, Partial<KFormProps<IUser>>>> = ({
  props: { visualAction, ...others } = {}
}) => {
  const { email } = useCurrentContact()

  return (
    <Row>
      <KCol span={24}>
        <KForm
          inlineErrors
          forceAction="update"
          recordType={RecordType.password}
          initialValues={{ email }}
          layout="vertical"
          visualAction={visualAction}
          {...others}>
          <Row gutter={[0, 24]}>
            <KCol span={24}>
              <KInputText name="email" disabled suffix={<Icon type="user" />} />
              <If condition={visualAction === 'reset_password'}>
                <KPasswordInput required withConfirm={false} name="old_password" />
              </If>
              <KPasswordInput required withConfirm={true} />
            </KCol>
            <KCol span={24}>
              <PasswordValidation />
            </KCol>
          </Row>
        </KForm>
      </KCol>
    </Row>
  )
}

export default PasswordForm
