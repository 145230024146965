import { gql } from '@apollo/client/core'

export const ATTRIBUTE_SEGMENTATION_ATTRIBUTES = gql`
  fragment attributeSegmentationAttributes on AttributeSegmentation {
    descriptionShort
    descriptionLong
    restrictionMax
    restrictionMin
    restrictionValues
    displayMetric
  }
`
