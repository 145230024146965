import { App as CapApp, BackButtonListener, URLOpenListener } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import { useEffect, useRef } from 'react'
import useEvent from 'react-use-event-hook'

import CapacitorService from '../services/CapacitorService'

export const useBackButton = (callback: BackButtonListener) => {
  subscribeCapApp((cb) => CapApp.addListener('backButton', cb), callback)
}

export const useUrlOpen = (callback: URLOpenListener) => {
  subscribeCapApp((cb) => CapApp.addListener('appUrlOpen', cb), callback)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any
const subscribeCapApp = (
  addEventListener: (callback: AnyFunction) => Promise<PluginListenerHandle>,
  callback: AnyFunction
) => {
  const ref = useRef<PluginListenerHandle | null>(null)

  // CapApp.addListener does not updates the callback reference, so we need stable reference
  // which is updated outside of the callback subscription. UseCallback is not suitable for this.
  // And re-subscribing on every callback change caused race condition.
  const stableCallBack = useEvent(callback)

  useEffect(() => {
    if (ref.current === null && CapacitorService.appAvailable()) {
      addEventListener(stableCallBack).then((handler) => (ref.current = handler))
    }

    return () => {
      ref.current?.remove()
    }
  }, [])
}
