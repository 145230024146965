import { gql } from '@apollo/client/core'

const EXTEND_LINE_ITEMS = gql`
  mutation createLineItemExtensionRequest($input: CreateLineItemExtensionRequestInput!) {
    createLineItemExtensionRequest(input: $input) {
      errors {
        path
        message
      }
    }
  }
`

export { EXTEND_LINE_ITEMS }
