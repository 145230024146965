import { Form, Skeleton } from 'antd'
import { KFormItemProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ReactElement } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'

const KFormItem: React.FC<KFormItemProps> = ({
  label,
  required,
  name,
  rules = [],
  recordTypeOverride,
  readonly,
  hasFeedback = true,
  hidden,
  hasExtra,
  hideErrors,
  labelRenderer,
  showLabel = true,
  className,
  ...others
}) => {
  const { recordType, loading, nested, visualRecordType } = useKFormContext()
  const { formatMessage } = useIntl()

  const getLabel = (): string => {
    if (label) {
      return label as string
    }

    if ((name || label) && !hidden)
      return formatMessage({
        id: `record.${recordTypeOverride || visualRecordType || recordType}.attributes.${
          label || name
        }`
      })
  }

  const getExtra = (): string => {
    if (hasExtra && !hidden)
      return formatMessage({
        id: `form.${recordTypeOverride || visualRecordType || recordType}.extra.${label || name}`
      })
  }

  return (
    <Form.Item
      hasFeedback={hasFeedback}
      colon={true}
      rules={[...[{ required: required }], ...rules]}
      name={nested ? [recordTypeOverride || recordType, `${name}`] : name}
      label={showLabel && (labelRenderer?.(getLabel()) || getLabel())}
      extra={getExtra()}
      hidden={hidden}
      className={hideErrors ? `${className} form-item--hide-errors` : className}
      {...others}>
      <Choose>
        <When condition={loading}>
          <Skeleton.Input className="full-width" size="small" active={loading} />
        </When>
        <When condition={readonly}>
          {React.cloneElement(others.children as ReactElement, { readOnly: true })}
        </When>
        <Otherwise>{others.children}</Otherwise>
      </Choose>
    </Form.Item>
  )
}

export default KFormItem
