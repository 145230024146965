import Bugsnag from '@bugsnag/js'
import { Col, Row } from 'antd'
import Button from 'app/components/UI-Elements/General/Button'
import Icon from 'app/components/UI-Elements/General/Icon'
import { RecordType } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'
import CapacitorService from 'app/lib/services/CapacitorService'
import * as React from 'react'

import Layout from '../Container/layout'

const storeLink = CapacitorService.getStoreLink()

const Update: React.FC = () => {
  const { tComponent } = ToolBox.useString()

  const open = () => {
    if (!storeLink) {
      Bugsnag.notify('No app/play store available for this environment', (event) => {
        event.severity = 'info'
      })
    }
    window.open(storeLink, '_system')
  }

  const translate = (id: string) =>
    tComponent({
      recordType: RecordType.update,
      componentName: 'view',
      componentType: 'core',
      id
    })

  return (
    <Layout>
      <Row gutter={[24, 24]} justify="center" className="text-center mt-5">
        <Col span={24}>
          <h3>{translate('title')}</h3>
          <p>{translate('content')}</p>
        </Col>
        <Col span={24}>
          <Icon type="zahnrad" iconStyles={{ height: 100 }} />
        </Col>
        <Col span={24} className="mt-4">
          <Button onClick={open} type="primary">
            {translate('button')}
          </Button>
        </Col>
      </Row>
    </Layout>
  )
}

export default Update
