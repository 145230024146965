import { BellOutlined } from '@ant-design/icons'
import { Badge as AntBadge } from 'antd'
import List from 'app/components/Core/Notification/List'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { useIndex } from 'app/lib/hooks/useApi'
import useTranslation from 'app/lib/hooks/useTranslation'
import * as React from 'react'
import { useEffect } from 'react'

const Badge: React.FC = () => {
  const {
    response: { data: collection },
    execute
  } = useIndex<INotification>('Notification')
  const { tCore } = useTranslation({ scope: 'badge', recordType: RecordType.notification })
  const {
    drawerfy: { open, close }
  } = useFeedbacker()

  const onClick = () => {
    open({
      content: <List collection={collection} props={{ onItemClick: close }} />,
      title: tCore({ id: 'drawer_title' }),
      width: 320
    })
  }

  useEffect(() => {
    execute({})
  }, [])

  return (
    <div
      role="button"
      aria-hidden="true"
      onClick={onClick}
      className="pointer"
      style={{ width: '25px' }}>
      <AntBadge showZero count={collection.length}>
        <BellOutlined className="text-lg" />
      </AntBadge>
    </div>
  )
}

export default Badge
