import { RouteType } from 'app/constants/GlobalTypes'

const DocumentationRoutes: RouteType[] = [
  {
    key: `component_documentation`,
    path: `/component_documentation`,
    component: import('app/pages/Documentation/index'),
    exact: true,
    routes: []
  },
  {
    key: `design_system`,
    path: `/design_system`,
    component: import('app/pages/Documentation/DesignSystem'),
    exact: true,
    routes: []
  },
  ...(process.env.NODE_ENV === 'development' // tree shaking only works with NODE_ENV
    ? [
        {
          key: `graphiql`,
          path: `/graphiql`,
          component: import('app/pages/Documentation/Graphiql'),
          exact: true,
          routes: []
        }
      ]
    : [])
]

export default DocumentationRoutes
