import { gql } from '@apollo/client/core'

import {
  ADDRESS_ATTRIBUTES,
  CONTACT_BASE_ATTRIBUTES,
  RENTAL_CHECKOUT_ATTRIBUTES
} from '../fragments'
import { LINE_ITEM_PRODUCT_BASE_ATTRIBUTES } from '../fragments/LineItem'

const FETCH_RENTAL_BASE_INFO = gql`
  query fetchRentalBaseInfo($id: ID!) {
    rental(id: $id) {
      id
      startDate
      returnDate
      hasLineItems
      deliveryAddress {
        ...addressAttributes
      }
    }
  }
  ${ADDRESS_ATTRIBUTES}
`

const RENTAL_CHECKOUT_QUERY = gql`
  query fetchCheckoutRental($id: ID!) {
    rental(id: $id) {
      ...rentalCheckoutAttributes
    }
  }
  ${RENTAL_CHECKOUT_ATTRIBUTES}
`

const RENTAL_CHECKOUT_PRODUCTS_QUERY = gql`
  query fetchRentalCheckoutProducts($id: ID!) {
    rental(id: $id) {
      id
      products {
        ...lineItemProductBaseAttributes
      }
    }
  }
  ${LINE_ITEM_PRODUCT_BASE_ATTRIBUTES}
`

const UPDATE_CHECKOUT_RENTAL_DELIVERY = gql`
  mutation checkoutRentalDelivery($input: DeliveryInput!) {
    checkoutRentalDelivery(input: $input) {
      rental {
        ...rentalCheckoutAttributes
      }
      errors {
        message
      }
    }
  }
  ${RENTAL_CHECKOUT_ATTRIBUTES}
`

const UPDATE_MANAGER_CONTACT = gql`
  mutation updateRentalManagerContact($input: ManagerContactInput!) {
    updateRentalManagerContact(input: $input) {
      rental {
        id
        managerContact {
          ...contactBaseAttributes
        }
      }
      errors {
        message
      }
    }
  }
  ${CONTACT_BASE_ATTRIBUTES}
`

const UPDATE_DELIVERY_CONTACT = gql`
  mutation updateRentalDeliveryContact($input: DeliveryContactInput!) {
    updateRentalDeliveryContact(input: $input) {
      rental {
        id
        deliveryContact {
          ...contactBaseAttributes
        }
      }
      errors {
        message
      }
    }
  }
  ${CONTACT_BASE_ATTRIBUTES}
`

export {
  RENTAL_CHECKOUT_QUERY,
  RENTAL_CHECKOUT_PRODUCTS_QUERY,
  UPDATE_MANAGER_CONTACT,
  FETCH_RENTAL_BASE_INFO,
  UPDATE_DELIVERY_CONTACT,
  UPDATE_CHECKOUT_RENTAL_DELIVERY
}
