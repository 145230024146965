import { MailOutlined, PhoneOutlined, SwapOutlined } from '@ant-design/icons/lib'
import { Avatar, Space } from 'antd'
import Button from 'app/components/UI-Elements/General/Button'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useCurrentContact, useCurrentCustomer } from 'app/lib/hooks/useCurrent'
import RouteService from 'app/lib/services/RouteService'
import { useIntl } from 'react-intl'

type Props = {
  showConnectButton?: boolean
}
const Support: React.FC<Props> = ({ showConnectButton }) => {
  const customer = useCurrentCustomer()
  const contact = useCurrentContact()

  const { formatMessage } = useIntl()
  const { getInitials } = ToolBox.useString()

  return (
    <Space direction="vertical" align="center" className="full-width">
      <If condition={showConnectButton && contact?.has_supplier_station}>
        <div className="text-center mt-2 mb-4">
          <a href={RouteService.getKlarxConnectUrl()} target="_blank" rel="noopener noreferrer">
            <Button type="secondary" size="middle">
              <SwapOutlined />
              {formatMessage({ id: 'sider.switch.to.klarx.connect' })}
            </Button>
          </a>
        </div>
      </If>
      <Space direction="vertical" className="support-description">
        <div>
          <span className="anticon" />
          <span className="text-bold text-primary">
            {formatMessage({ id: 'sidebar.klarx.partner' })}
          </span>
        </div>
        <Space direction="horizontal">
          <span className="anticon">
            <Avatar
              size="large"
              className="d-flex align-items-center"
              src={customer?.owner_image_url}>
              {getInitials(customer?.owner_name)}
            </Avatar>
          </span>
          <Space direction="vertical">
            <span className="d-block line-height-1">{customer?.owner_name}</span>
            <span className="text-muted">
              {formatMessage({ id: 'sidebar.klarx.account.manager' })}
            </span>
          </Space>
        </Space>
      </Space>
      <Space direction="horizontal" className="support-contacts">
        <Button
          onClick={() => window.open(`tel:${customer?.owner_phone}`)}
          className="m-2"
          size="large"
          shape="circle"
          type="primary"
          danger
          icon={<PhoneOutlined />}
        />
        <Button
          onClick={() => window.open(`mailto:${customer?.owner_email}`)}
          className="m-2"
          size="large"
          shape="circle"
          type="primary"
          danger
          icon={<MailOutlined />}
        />
      </Space>
    </Space>
  )
}

export default Support
