import { SearchOutlined } from '@ant-design/icons'
import SimpleSelect from 'app/components/UI-Elements/DataEntry/_Select'
import { notify } from 'app/lib/Notify'
import { Search } from 'app/redux/actions/Search'
import { debounce } from 'lodash'
import { useState } from 'react'
import * as React from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const GlobalSearch: React.FC = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([])

  const onSearch = debounce((value: string) => {
    setLoading(true)
    Search.index({ q: value })
      .then((data) => {
        setSearchResults(data)
      })
      .catch((err) => notify({ message: err, intent: 'danger' }))
      .finally(() => setLoading(false))
  }, 400)

  const onChange = (searchResult: ISearchResult) => {
    searchResult?.frontend_path && navigate(searchResult.frontend_path)
  }

  return (
    <SimpleSelect<ISearchResult>
      filterOption={false}
      showSearch={true}
      loading={loading}
      value={null}
      allowClear={true}
      popupMatchSelectWidth={true}
      size={'large'}
      entries={searchResults}
      selectValueBy={'id'}
      suffixIcon={<SearchOutlined />}
      optionLabelProp={'name'}
      placeholder={formatMessage({ id: 'component.data_entry.search.placeholder.default' })}
      emptyText={formatMessage({ id: 'component.data_entry.search.not_found.quick_search' })}
      onSearch={onSearch}
      onChange={onChange}
      groupWith={'record_type'}
      customOptionRenderer={(searchItem) => `${searchItem.name} ${searchItem.rental_code}`}
    />
  )
}

export default GlobalSearch
