import { RecordType } from 'app/constants/GlobalTypes'
import { RecordTypeEnum } from 'app/queries'
import { FormatXMLElementFn, Options, PrimitiveType } from 'intl-messageformat'
import { MessageDescriptor, useIntl } from 'react-intl'

import EnvironmentService, { Environment } from '../services/EnvironmentService'

type Values = Record<string, PrimitiveType | FormatXMLElementFn<string, string>>

type IntlOptions = Options

type ComponentTypes =
  | 'navigation'
  | 'feedback'
  | 'general'
  | 'data_entry'
  | 'data_display'
  | 'hooks'
  | 'core'
  | 'modules'

type Props = {
  scope?: string
  recordType?: RecordTypeEnum | keyof typeof RecordType | string | 'default'
  componentType?: ComponentTypes
}

type TranslateFunction = (
  descriptor: MessageDescriptor,
  values?: Values,
  options?: IntlOptions
) => string

export const modalContentPrefix = 'component.feedback.modal.content'
export const buttonTitlePrefix = 'component.general.button.title'

export default function ({ scope, componentType, recordType = 'default' }: Props = {}) {
  const { formatMessage } = useIntl()

  const tComponent: TranslateFunction = ({ id, ...others }, values, options) => {
    if (!componentType) {
      if (EnvironmentService.is(Environment.Dev)) {
        throw 'Specify Component Type'
      } else {
        console.error('Specify Component Type')
      }
    }

    return formatMessage(
      {
        id: `component.${componentType}.${scope}.${recordType}.${id}`,
        ...others
      },
      values,
      options
    )
  }

  const tCore: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      {
        id: `component.core.${scope}.${recordType}.${id}`,
        ...others
      },
      values,
      options
    )
  }

  const tRoute: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      {
        id: `route.${scope}.${id}`,
        ...others
      },
      values,
      options
    )
  }

  const tAttribute: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      {
        id: `record.${recordType}.attributes.${id}`,
        ...others
      },
      values,
      options
    )
  }

  const tRecordType: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      { id: `record.${recordType}.${id || recordType}`, ...others },
      values,
      options
    )
  }

  const tModal: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      { id: `${modalContentPrefix}.${recordType}.${id}`, ...others },
      values,
      options
    )
  }

  const tButtonTitle: TranslateFunction = ({ id, ...others }, values, options) => {
    return formatMessage(
      { id: `${buttonTitlePrefix}.${recordType}.${id}`, ...others },
      values,
      options
    )
  }

  const tPageTitle: TranslateFunction = ({ id = 'index', ...others }, values, options) => {
    return formatMessage({ id: `page.${recordType}.${id}`, ...others }, values, options)
  }

  return {
    t: formatMessage,
    tRoute,
    tCore,
    tComponent,
    tAttribute,
    tRecordType,
    tModal,
    tPageTitle,
    tButtonTitle
  }
}
