import ant_en from 'antd/locale/en_US'
import ant_mobile_en from 'antd-mobile/lib/locale-provider/en_US'
import { ToolBox } from 'app/lib/hooks/toolBox'

import enMessages from '../locales/en_US.json'
import enMessagesV2 from '../locales/v2/en_US.json'

const typeTemplate = 'Please put a valid ${type}'

const enLang = {
  messages: {
    ...enMessages,
    ...ToolBox?.flattenPayload(enMessagesV2),
    formValidation: {
      required: 'This field is required.',
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      }
    }
  },
  antLocale: ant_en,
  antMobileLocale: ant_mobile_en,
  locale: 'en-US'
}

export default enLang
export const enTree = enMessagesV2
