import { ClockCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { DatePicker as MobileDatePicker } from 'antd-mobile'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KFormItemProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import { useDate } from 'app/lib/hooks/toolBox'
import React, { useMemo } from 'react'
import { useIntl } from 'react-intl'

const defaultStartTime = '09:00'
const defaultReturnTime = '17:00'

interface Props extends Partial<KFormItemProps> {
  disabled?: boolean
}

interface TimePickerProps {
  value?: string
}

const KTimeRange: React.FC<Props> = ({ disabled, ...others }) => {
  const {
    datify: { revert, convert }
  } = useDate()

  const TimePicker = ({ value, ...others }: TimePickerProps) => {
    const { formatMessage } = useIntl()

    const getValue = useMemo(() => convert(value, 'time'), [value])

    const getTranslation = (id: string) =>
      formatMessage({ id: `component.data_entry.date_picker.${id}` })

    return (
      <MobileDatePicker
        mode="time"
        minuteStep={30}
        title={getTranslation('set_time')}
        value={getValue}
        dismissText={getTranslation('dismiss_text')}
        okText={getTranslation('ok_text')}
        {...others}>
        <Button icon={<ClockCircleOutlined />} title={value || '-'} disabled={disabled} />
      </MobileDatePicker>
    )
  }

  const getValueFromEvent = (a: Date) => revert(a, 'time')

  return (
    <Space direction="horizontal">
      <KFormItem
        initialValue={defaultStartTime}
        getValueFromEvent={getValueFromEvent}
        name={'start_time'}
        hasFeedback={false}
        {...others}>
        <TimePicker />
      </KFormItem>
      <KFormItem
        initialValue={defaultReturnTime}
        getValueFromEvent={getValueFromEvent}
        name={'return_time'}
        hasFeedback={false}
        {...others}>
        <TimePicker />
      </KFormItem>
    </Space>
  )
}

export default KTimeRange
