import { MenuOutlined } from '@ant-design/icons/lib'
import { Col, Layout, Row, Space } from 'antd'
import ActivityBadge from 'app/components/Core/Activity/Badge'
import Badge from 'app/components/Core/Notification/Badge'
import ProfileAvatar from 'app/components/Core/Profile/Avatar'
import GlobalSearch from 'app/components/Core/Search/GlobalSearch'
import QuickActions from 'app/components/Core/Search/QuickActions'
import Restricted from 'app/components/UI-Elements/General/Restricted'
import Logo from 'app/components/UI-Elements/Layout/Header/Logo'
import * as React from 'react'

import PlainHeader from '../Plain'
import CustomerName from './CustomerName'

type Props = {
  toggleDrawer?: () => void
}
const Header: React.FC<Props> = ({ toggleDrawer }) => {
  return (
    <Restricted renderOnHide={() => <PlainHeader />}>
      <Layout.Header>
        <Row align="top" wrap={false}>
          <Col xxl={14} xl={14} lg={14} md={12} sm={18} xs={24}>
            <Row gutter={[12, 0]} align="middle" wrap={false}>
              <Col flex="none" className="d-flex align-center">
                <div className="menu-toggler">
                  <MenuOutlined onClick={toggleDrawer} />
                </div>
                <div className="klarx-logo d-flex">
                  <Logo />
                </div>
              </Col>
              <Col flex="auto" style={{ maxWidth: 600 }}>
                <GlobalSearch />
              </Col>
              <Col flex="none">
                <div className="quick-actions d-flex">
                  <QuickActions />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xxl={10} xl={10} lg={10} md={12} sm={6} xs={0}>
            <Row justify="end" align="top" wrap={false}>
              <Space align="center" className="mr-3" style={{ justifyContent: 'center' }}>
                <CustomerName />
                <ActivityBadge />
                <Badge />
                <ProfileAvatar />
              </Space>
            </Row>
          </Col>
        </Row>
      </Layout.Header>
    </Restricted>
  )
}

export default Header
