import { dayjs } from '@2wunder/klarx-tool'

class ConfigService {
  static init(config: IConfigAttributes) {
    const { holiday_period, ...restConfig } = config || {}
    const { banner_enabled, start_date, end_date } = holiday_period || {}

    const holidayConfig = {
      showBanner: () => !!banner_enabled,
      getStartDate: () => dayjs(start_date).toLocaleDate(),
      getEndDate: () => dayjs(end_date).toLocaleDate(),
      getNexEndDate: () => dayjs(end_date).add(1, 'day').toLocaleDate()
    }

    return {
      ...restConfig,
      holidayConfig
    }
  }
}

export declare type IConfigService = typeof ConfigService.init

export default ConfigService
