import { useRestriction } from 'app/lib/hooks/useRestriction'
import * as React from 'react'

interface Props {
  allowFor?: RoleType[]
  mode?: 'hide' | 'disable'
  children: React.ReactElement
  renderOnHide?: () => React.ReactElement
}

const Restricted = ({
  allowFor = ['admin', 'manager', 'member', 'accounting'],
  mode = 'hide',
  children,
  renderOnHide,
  ...others
}: Props) => {
  const { restricted } = useRestriction({ allowFor })
  const hidden = mode === 'hide' && restricted

  return (
    <Choose>
      <When condition={!hidden}>
        {React.cloneElement(children, {
          ...(mode === 'disable' ? { disabled: restricted } : {}),
          ...others
        })}
      </When>
      <When condition={renderOnHide}>{renderOnHide()}</When>
    </Choose>
  )
}

export default Restricted
