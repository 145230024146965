import { Select, Spin } from 'antd'
import { SelectProps } from 'antd/lib/select'
import Empty from 'app/components/UI-Elements/Feedback/Empty'
import { groupBy } from 'lodash'
import * as React from 'react'

import { FormRecord } from '../../Modules/Form/typing'

const { Option, OptGroup } = Select

interface Props<T> extends SelectProps<T> {
  entries: T[]
  groupWith?: string
  selectValueBy?: string
  selectValueByFallback?: string
  optionLabelFallback?: string
  dropDownFooter?: React.ReactNode
  emptyText?: string
  onChange?: (entry: T) => void
  customOptionRenderer?: (entry: T) => React.ReactNode
}

function SimpleSelect<T extends FormRecord>({
  entries,
  value,
  groupWith,
  selectValueBy = 'id',
  selectValueByFallback,
  optionLabelFallback,
  dropDownFooter,
  emptyText,
  notFoundContent,
  customOptionRenderer,
  onBlur,
  onChange,
  loading,
  optionLabelProp,
  filterOption,
  ...others
}: Props<T>) {
  const valueSelector = (): string => {
    return selectValueBy || selectValueByFallback
  }

  const optionLabelSelector = (): string => {
    return optionLabelProp || optionLabelFallback
  }

  const filterOptionEvent = (input: string, option: any): boolean => {
    return option[optionLabelSelector()].toLowerCase().includes(input.toLowerCase())
  }

  const onChangeEvent = (value: string) => {
    onChange(entries.find((entry) => entry[valueSelector()] === value))
  }

  const renderGroups = (): React.ReactNode => {
    const groups: { [key: string]: T[] } = groupBy<T>(entries, (entry) => entry[groupWith])
    return Object.keys(groups).map((key) => (
      <OptGroup key={key} label={key}>
        {renderOptions(groups[key])}
      </OptGroup>
    ))
  }

  const renderOptions = (entries: T[]): React.ReactNode => {
    return entries.map((entry, index) => (
      <Option key={`${entry.id}-${index}`} value={entry.id}>
        {customOptionRenderer ? (
          customOptionRenderer(entry)
        ) : (
          <div>
            <span>{entry[optionLabelProp]?.toString()?.trim() || entry[optionLabelFallback]}</span>
          </div>
        )}
      </Option>
    ))
  }

  const descriptiveEmptyContent = <Empty description={emptyText} />

  return (
    <Select<any>
      onBlur={onBlur}
      loading={loading}
      onChange={onChangeEvent}
      filterOption={filterOption ? filterOptionEvent : false}
      dropdownRender={(menu) => (
        <Spin spinning={loading}>
          {menu}
          {dropDownFooter}
        </Spin>
      )}
      getPopupContainer={(target) => target}
      notFoundContent={emptyText ? descriptiveEmptyContent : notFoundContent}
      {...others}>
      {groupWith ? renderGroups() : renderOptions(entries)}
    </Select>
  )
}

export default SimpleSelect
