import { InfoWindow, Marker as GoogleMarker, MarkerProps } from '@react-google-maps/api'
import { Position } from 'app/lib/typing'
import React, { useState } from 'react'

interface Props<T> extends MarkerProps {
  item: T
  open?: boolean
  tooltip?: (item: T & Position) => React.ReactNode
  onMarkerToggle?: (open: boolean) => void
}

function Marker<T extends { lat: number; lng: number }>({
  item,
  open,
  tooltip,
  onMarkerToggle,
  ...others
}: Props<T>) {
  const [isLoaded, setIsLoaded] = useState(false)

  const onTooltipToggle = (isOpen: boolean) => {
    onMarkerToggle?.(isOpen)
  }

  const onLoad = () => setIsLoaded(true)

  return (
    <GoogleMarker
      onLoad={onLoad}
      position={item}
      onMouseDown={() => onMarkerToggle?.(!open)}
      {...others}>
      <If condition={open && isLoaded && tooltip}>
        <InfoWindow onCloseClick={() => onTooltipToggle?.(false)}>{tooltip(item)}</InfoWindow>
      </If>
    </GoogleMarker>
  )
}

export default Marker
