import { Row } from 'antd'
import Version from 'app/components/Core/Profile/Version'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { LOGO_FILE } from 'app/constants/Global'
import * as React from 'react'

type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <Row justify="center" className="bg-white" style={{ height: '100%' }}>
      <KCol card xs={24} md={18} lg={12} xl={10} xxl={7}>
        <Row gutter={[12, 24]}>
          <KCol span={24} className="text-center">
            <img
              src={LOGO_FILE}
              className="mx-auto mb-2 mt-5"
              style={{ width: 120 }}
              alt="klarx logo"
            />
            <h3>Baumaschinen einfach mieten.</h3>
          </KCol>
          <KCol span={24}>{children}</KCol>
          <KCol span={24} className="text-center">
            <Version />
          </KCol>
        </Row>
      </KCol>
    </Row>
  )
}

export default Layout
