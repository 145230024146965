import BrowserService from 'app/lib/services/BrowserService'
import CapacitorService from 'app/lib/services/CapacitorService'

export const isCypress = Boolean(window.Cypress)

const KLARX_MANAGER_URL_PREFIX = 'app.'
export const branchName = process.env.REACT_APP_GIT_BRANCH

export enum Environment {
  Prod = 'production',
  Staging = 'staging',
  Demo = 'demo',
  Dev = 'development',
  Test = 'test'
}

class EnvironmentService {
  static isProduction = () => {
    return EnvironmentService.is(Environment.Prod)
  }

  static getEnvValue = (attr: string) => {
    return process.env[`REACT_APP_${attr}`]
  }

  static is = (env: Environment) => {
    return EnvironmentService.getEnvValue('ENV') === env
  }

  static isOneOf = (envs: Array<Environment>) => {
    return envs.includes(EnvironmentService.getEnvValue('ENV') as Environment)
  }

  static getHostUrlValue = () => {
    switch (process.env.REACT_APP_ENV) {
      case Environment.Prod:
        return EnvironmentService.getEnvValue('HOST_URL') + '.' + BrowserService.getDomainName()
      case Environment.Dev:
        return EnvironmentService.getEnvValue('HOST_URL')
      default:
        if (CapacitorService.isNative()) {
          const scheme = EnvironmentService.getEnvValue('HOST_SCHEME')
          const host = window.location.host.replace(KLARX_MANAGER_URL_PREFIX, '')
          return `${scheme}${host}`
        }
        return window.location.origin.replace(KLARX_MANAGER_URL_PREFIX, '')
    }
  }
}

export default EnvironmentService
