import { Col, List as AntList, Row } from 'antd'
import KList from 'app/components/Modules/List/KList'
import { RecordType } from 'app/constants/GlobalTypes'

const List: React.FC = () => {
  return (
    <Row gutter={[12, 0]}>
      <Col span={24}>
        <KList<IActivity>
          recordType={RecordType.activity}
          params={{}}
          itemLayout="horizontal"
          renderItem={(activity) => (
            <AntList.Item data-testid="activity-list-item">
              <Row gutter={[0, 8]} className="fade-in-slow">
                <Col span={24}>
                  <span
                    className="text-heading"
                    dangerouslySetInnerHTML={{ __html: activity.header }}
                  />
                </Col>
                <Col span={24}>
                  <span
                    className="text-black"
                    dangerouslySetInnerHTML={{ __html: activity.description }}
                  />
                </Col>
                <Col span={24}>
                  <span className="text-sm text-muted">{`${activity.created_at_date} - ${activity.created_at_time}`}</span>
                </Col>
              </Row>
            </AntList.Item>
          )}
        />
      </Col>
    </Row>
  )
}

export default List
