import { Col, List as AntList, Row } from 'antd'
import { CollectionProps } from 'app/components/Core/convention'
import KList from 'app/components/Modules/List/KList'
import KInfo from 'app/components/UI-Elements/DataDisplay/KInfo'
import { RecordType } from 'app/constants/GlobalTypes'

const Info: React.FC<CollectionProps<ICustomerRole>> = () => {
  return (
    <Row>
      <Col span={24}>
        <KList
          footer={null}
          size="small"
          grid={{ column: 1 }}
          recordType={RecordType.customer_role}
          itemLayout="horizontal"
          renderItem={(customerRole: ICustomerRole) => (
            <AntList.Item>
              <KInfo title={<b>{customerRole.name}</b>} info={customerRole.description} />
            </AntList.Item>
          )}></KList>
      </Col>
    </Row>
  )
}

export default Info
