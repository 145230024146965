import { gql } from '@apollo/client/core'

export const SUB_ITEM_BASE_ATTRIBUTES = gql`
  fragment subItemBaseAttributes on SubItem {
    id
    lineItemId
    name
    imageUrl
    freeAttachment
    lineItemType
    startDate
    returnDate
    discountedUnitPrice
    discountAsPercentage
    earliestAvailableDate
  }
`
