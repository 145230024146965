/* eslint-disable max-lines */
import { RouteType } from 'app/constants/GlobalTypes'
import { allRoles } from 'app/lib/hooks/useRestriction'
import DocumentationRoutes from 'app/pages/Documentation/Routes'

import { Routes } from './Routes'

export const routes: RouteType[] = [
  {
    key: `${Routes.Overview.Key}.index`,
    path: Routes.Overview.collection().index,
    component: import('app/pages/Overview/Views/index'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Payment.Key}.show`,
    path: Routes.Payment.member().show,
    component: import('app/pages/Payment/Views/show'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Payment.Key}.confirmation`,
    path: Routes.Payment.member().confirmation,
    component: import('app/pages/Payment/Views/confirmation'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Rental.Key}.index`,
    path: Routes.Rental.collection().index,
    component: import('app/pages/Rental/Views/index'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Rental.Key}.missing_information`,
    path: Routes.Transactional.member().missing_information,
    component: import('app/pages/Transactional/Views/MissingInformation'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Rental.Key}.machine_interaction`,
    path: Routes.Transactional.member().machine_interaction,
    component: import('app/pages/Transactional/Views/MachineInteraction'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Payment.Key}.confirmation`, // TODO
    path: Routes.Transactional.member().confirmation,
    component: import('app/pages/Transactional/Views/Confirmation'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Quote.Key}.feedback`,
    path: Routes.Transactional.member().decline_quote,
    component: import('app/pages/Transactional/Views/QuoteFeedback'),
    allowFor: allRoles,
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Rental.Key}.show`,
    path: Routes.Rental.member().show,
    component: import('app/pages/Rental/Views/show'),
    allowFor: allRoles,
    routes: [
      {
        key: `${Routes.Rental.Key}.machine_interaction`,
        path: Routes.Rental.member(undefined, false).machine_interaction,
        component: import('app/pages/Rental/Views/machine_interaction'),
        asFeedback: true,
        exact: true,
        restrictionMode: 'RedirectToTransactional',
        routes: []
      },
      {
        key: `${Routes.Rental.Key}.damage`,
        path: Routes.Rental.member(undefined, false).damage,
        component: import('app/pages/Rental/Views/damage'),
        asFeedback: false,
        exact: true,
        routes: []
      },
      {
        key: `${Routes.Rental.Key}.quote_feedback`,
        path: Routes.Rental.member(undefined, false).decline_quote,
        component: import('app/pages/Rental/Views/quote_feedback'),
        asFeedback: true,
        restrictionMode: 'RedirectToTransactional',
        exact: false,
        routes: []
      },
      {
        key: `${Routes.Rental.Key}.missing_information`,
        path: Routes.Rental.member(undefined, false).missing_information,
        component: import('app/pages/Rental/Views/missing_information'),
        asFeedback: true,
        exact: false,
        restrictionMode: 'RedirectToTransactional',
        routes: []
      },
      {
        key: `${Routes.Rental.Key}.update`,
        path: Routes.Rental.member(undefined, false).update,
        component: import('app/pages/Rental/Views/update'),
        exact: false,
        routes: []
      }
    ]
  },
  {
    key: `${Routes.Project.Key}.index`,
    path: Routes.Project.collection().index,
    component: import('app/pages/Project/Views/index'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Project.Key}.timeline`,
    path: Routes.Project.collection().timeline,
    component: import('app/pages/Project/Views/timeline'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Project.Key}.map`,
    path: Routes.Project.collection().map,
    component: import('app/pages/Project/Views/map'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Project.Key}.show`,
    path: Routes.Project.member().show,
    component: import('app/pages/Project/Views/show'),
    routes: []
  },
  {
    key: `${Routes.Invoice.Key}.index`,
    path: Routes.Invoice.collection().index,
    component: import('app/pages/Invoice/Views/index'),
    exact: false,
    routes: [
      {
        key: `${Routes.Invoice.Key}.show`,
        path: Routes.Invoice.member(undefined, false).show,
        component: import('app/pages/Invoice/Views/show'),
        asFeedback: true,
        exact: true,
        routes: []
      }
    ]
  },
  {
    key: `${Routes.Invoice.Key}.index`,
    path: Routes.Invoice.collection().overview,
    component: import('app/pages/Invoice/Views/overview'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Setting.Key}.index`,
    path: Routes.Setting.collection().index,
    component: import('app/pages/Setting/Views/index'),
    exact: false,
    routes: []
  },
  {
    key: `${Routes.PriceList.Key}.index`,
    path: Routes.PriceList.collection().index,
    component: import('app/pages/Pricelist/Views/index'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Checkout.Key}.show`,
    path: Routes.Checkout.member().show,
    component: import('app/pages/Checkout/Root'),
    routes: []
  },
  {
    key: `${Routes.Authentication.Key}.set_password`,
    path: Routes.Authentication.collection().set_password,
    component: import('app/pages/Password/Views/create'),
    exact: true,
    allowFor: ['admin', 'manager', 'member', 'guest', 'accounting'],
    routes: []
  },
  {
    key: `${Routes.Authentication.Key}.set_password_confirmation`,
    path: Routes.Authentication.collection().set_password_confirmation,
    component: import('app/pages/Password/Views/confirmation'),
    exact: true,
    allowFor: ['admin', 'manager', 'member', 'guest', 'accounting'],
    routes: []
  },
  {
    key: `${Routes.User.Key}.index`,
    path: Routes.User.collection().index,
    component: import('app/pages/User/Views/index'),
    exact: true,
    allowFor: ['admin', 'manager'],
    routes: []
  },
  {
    key: `${Routes.FAQ.Key}.index`,
    path: Routes.FAQ.collection().index,
    component: import('app/pages/FAQ/Views/index'),
    exact: true,
    routes: []
  },
  {
    key: `${Routes.Update.Key}`,
    path: Routes.Update.collection().index,
    component: import('app/pages/Update/Views/index'),
    routes: []
  },
  ...DocumentationRoutes,
  {
    key: `${Routes.Error.Key}.role_not_authorized`,
    path: Routes.Error.collection().role_not_authorized,
    allowFor: allRoles,
    component: import('app/pages/Error/Views/RoleNotAuthorized'),
    routes: []
  },
  {
    key: `${Routes.Error.Key}.not_found`,
    component: import('app/pages/Error/Views/Error404'),
    routes: []
  }
]
