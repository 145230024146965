import { HistoryOutlined } from '@ant-design/icons'
import { Badge as AntBadge } from 'antd'
import List from 'app/components/Core/Activity/List'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'

const Badge: React.FC = () => {
  const {
    drawerfy: { open }
  } = useFeedbacker()
  const onClick = () => {
    open({ content: <List />, title: 'Aktivitäten', width: 320 })
  }
  return (
    <div onClick={onClick} className="pointer" data-testid="ktest-badge" style={{ width: '20px' }}>
      <AntBadge status="success" className="line-height-initial">
        <HistoryOutlined className="text-lg" />
      </AntBadge>
    </div>
  )
}

export default Badge
