/* eslint-disable @typescript-eslint/no-explicit-any */
import Bugsnag from '@bugsnag/js'

export const parseError = (response: any, reloadForAuth = true): string[] => {
  if (response && response.data && typeof response.data === 'object') {
    if (response.status === 401 && reloadForAuth) {
      window.location.reload()
    }
    return handleObjectErrors(response.data)
  } else if (response.statusText) {
    return [response.statusText]
  } else {
    console.warn(response)
    const errorObject = response instanceof Error ? response : new Error(JSON.stringify(response))
    Bugsnag.notify(errorObject, (event) => {
      event.severity = 'info'
    })
    return ['Something went wrong.']
  }
}

// return array of string
const handleObjectErrors = (errorObject: any) => {
  if (errorObject && errorObject.errors) {
    if (typeof errorObject.errors === 'string') {
      return [errorObject.errors]
    } else {
      return normalizeErrors(errorObject.errors)
    }
  } else {
    return [errorObject.error]
  }
}

const normalizeErrors = (err: any) => {
  return Object.keys(err).map((key) => err[key])
}
