import { LeftOutlined } from '@ant-design/icons/lib'
import * as React from 'react'
import { useState } from 'react'

type Props = {
  children: React.ReactNode
}

const MapDrawer: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className={`map-drawer ${open ? `map-drawer-close` : ''}`}>
      {children}
      <button onClick={() => setOpen(!open)} className="drawer-toggler">
        <LeftOutlined />
      </button>
    </div>
  )
}

export default MapDrawer
