import { Checkbox } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KCheckboxProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'

const KCheckbox: React.FC<KCheckboxProps> = ({
  required,
  recordTypeOverride,
  hasFeedback,
  ...props
}) => {
  const { tAttribute } = ToolBox.useString()
  const { recordType } = useKFormContext()

  return (
    <KFormItem
      valuePropName="checked"
      labelCol={{ span: 0 }}
      name={props.name}
      required={required}
      recordTypeOverride={recordTypeOverride}
      readonly={props.readonly}
      hasFeedback={hasFeedback}
      hidden={props.hidden}>
      <Checkbox {...props}>
        {tAttribute({ recordType: recordType, attribute: props.name })}
      </Checkbox>
    </KFormItem>
  )
}

export default KCheckbox
