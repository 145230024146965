import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { RecordType } from 'app/constants/GlobalTypes'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import React, { useEffect, useState } from 'react'

enum MessageEnum {
  at_least_one_letter = 'at_least_one_letter',
  at_least_one_number = 'at_least_one_number',
  at_least_eight_character = 'at_least_eight_character'
}

type Status = 'validating' | 'error' | 'success'

const PasswordValidation: React.FC<{ value?: string }> = ({ value }) => {
  const { form } = useKFormContext()
  const { getFormError } = ToolBox.useFormTools()
  const [errors, setErrors] = useState<Array<MessageEnum>>([])

  useEffect(() => {
    value &&
      form
        .validateFields(['password'])
        .then(() => setErrors([]))
        .catch((err: { errorFields: Array<{ errors: Array<MessageEnum> }> }) => {
          setErrors(err?.errorFields?.[0]?.errors || [])
        })
  }, [value])

  const isInvalid = (messageType: MessageEnum) => {
    return errors.includes(messageType)
  }

  const getStatus = (messageType: MessageEnum): Status => {
    if (!value) {
      return 'validating'
    } else if (isInvalid(messageType)) {
      return 'error'
    } else {
      return 'success'
    }
  }

  const getClassName = (status: Status) => {
    switch (status) {
      case 'error':
        return 'text-danger'
      case 'success':
        return 'text-success'
      default:
        return ''
    }
  }

  return (
    <Space direction="vertical">
      {Object.keys(MessageEnum).map((messageType: MessageEnum) => {
        const status = getStatus(messageType)
        return (
          <Space
            direction="horizontal"
            key={messageType}
            className={getClassName(status)}
            data-testid="password-warnings">
            {status === 'error' ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
            {getFormError({ recordType: RecordType.password, id: messageType })}
          </Space>
        )
      })}
    </Space>
  )
}

const ValidationInfoItem = () => {
  const { hasLetter, hasNumber } = ToolBox.validaton()

  const validate = (value: string, rule: () => boolean) => {
    return new Promise((resolve, reject) => {
      if (value && rule()) {
        resolve(value)
      } else {
        reject()
      }
    })
  }

  const atLeastOneLetter = (_rule: unknown, value: string) => {
    return validate(value, () => hasLetter(value))
  }

  const atLeastOneNumber = (_rule: unknown, value: string) => {
    return validate(value, () => hasNumber(value))
  }

  const atLeastEightCharacter = (_rule: unknown, value: string) => {
    return validate(value, () => value.length >= 8)
  }

  return (
    <KFormItem
      name="password"
      hasFeedback={false}
      hideErrors={true}
      showLabel={false}
      rules={
        [
          {
            message: 'at_least_one_letter',
            validator: atLeastOneLetter
          },
          {
            message: 'at_least_one_number',
            validator: atLeastOneNumber
          },
          {
            message: 'at_least_eight_character',
            validator: atLeastEightCharacter
          }
        ] as Array<{ message: MessageEnum; validator: () => Promise<string> }>
      }>
      <PasswordValidation />
    </KFormItem>
  )
}

export default ValidationInfoItem
