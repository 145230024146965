import { gql } from '@apollo/client/core'

export const ADDRESS_ATTRIBUTES = gql`
  fragment addressAttributes on Address {
    address
    address2
    zip
    city
    country
  }
`
