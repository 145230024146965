/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
// @ts-nocheck

import EnvironmentService, { isCypress } from 'app/lib/services/EnvironmentService'

class ScriptService {
  static gtag(...args) {
    if (EnvironmentService.isProduction()) {
      const layer = window.dataLayer || []
      layer.push(args)
    } else {
      console.warn('gtag is disabled in dev mode')
    }
  }

  static sendFacebookAnlytics = (rental, rentalLineItems) => {
    if (typeof fbq === 'function' && rental) {
      const name = rentalLineItems.find(function (line_item) {
        return !line_item.attachment_id
      }).category_segment_name
      const ids = rentalLineItems.reduce(function (acc, line_item) {
        if (!line_item.attachment_id) acc.push(line_item.id)
        return acc
      }, [])
      fbq('track', 'Purchase', {
        content_name: name,
        content_category: name,
        content_ids: ids,
        content_type: 'product',
        value: parseInt(rental.price_net_total),
        currency: 'EUR'
      })
    }
  }
  static sendBingAnalytics = (rental) => {
    if (typeof window.uetq === 'object' && rental) {
      window.uetq = window.uetq || []
      window.uetq.push({
        ec: 'conversion',
        ea: 'checkout',
        el: 'net_total_price',
        ev: parseInt(rental.price_net_total),
        gv: parseInt(rental.price_net_total),
        gc: 'EUR'
      })
    }
  }
  static getGaClientId = () => {
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
      const trackers = ga.getAll()
      if (trackers) {
        const tracker = trackers[0]
        return tracker ? tracker.get('clientId') : undefined
      }
    }
  }

  static embedHubspotScript = (identify_email_with_hubspot: string, hubspot_sync = true) => {
    if (!hubspot_sync || isCypress) return

    const el = document.getElementById('hs-script-loader')
    // remove check added for IE
    if (el && typeof el.remove === 'function') {
      el.remove()
    }
    const script = document.createElement('script')
    script.id = 'hs-script-loader'
    script.type = 'text/javascript'
    script.async = true
    script.defer = true
    const hubspotId = EnvironmentService.getEnvValue('HUBSPOT_ID')
    script.src = `//js.hs-scripts.com/${hubspotId}.js`
    document.getElementsByTagName('body')[0].appendChild(script)
    script.onload = function () {
      setTimeout(() => {
        if (identify_email_with_hubspot) {
          ScriptService.setHubspotEmail(identify_email_with_hubspot)
        }
      }, 1000)
    }
  }

  static setHubspotEmail = (identify_email_with_hubspot: string, retryCount = 2) => {
    if (window._hsq) {
      const _hsq = window._hsq || []
      _hsq.push([
        'identify',
        {
          email: identify_email_with_hubspot
        }
      ])
    } else if (retryCount > 1) {
      setTimeout(() => {
        ScriptService.setHubspotEmail(identify_email_with_hubspot, retryCount - 1)
      }, 1000)
    }
  }
}

export default ScriptService
