import { InfoCircleOutlined } from '@ant-design/icons'
import { Alert as AntAlert } from 'antd'
import { AlertProps } from 'antd/lib/alert'
import * as React from 'react'

interface Props extends Omit<AlertProps, 'type'> {
  type?: 'success' | 'info' | 'warning' | 'error' | 'default' | 'transparent'
  children?: React.ReactNode
}

const Alert: React.FC<Props> = ({
  type = 'error',
  className,
  description,
  children,
  message,
  icon,
  ...others
}) => {
  const getType = (): 'success' | 'info' | 'warning' | 'error' => {
    if (type === 'default' || type === 'transparent') {
      return 'info'
    } else {
      return type
    }
  }

  const getClassName = (): string => {
    if (type === 'default') {
      return `${className} ant-alert-default`
    }
    if (type === 'transparent') {
      return `${className} ant-alert-transparent`
    } else {
      return className
    }
  }

  return (
    <AntAlert
      description={description || children}
      type={getType()}
      icon={icon ?? <InfoCircleOutlined />} // override default icon
      className={getClassName()}
      message={message}
      {...others}
    />
  )
}

export default Alert
