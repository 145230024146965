import { Col, Row, Space } from 'antd'
import ContactOrganizationForm from 'app/components/Core/ContactOrganization/Form'
import PasswordForm from 'app/components/Core/User/Passsword/Form'
import KCheckbox from 'app/components/Modules/Form/KCheckbox'
import KNestedFields from 'app/components/Modules/Form/KNestedFields'
import { KFormProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'
import { USER } from 'app/redux/reducers/actionTypes'
import { Routes } from 'app/routes/Routes'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const ProfileForm: React.FC<Partial<KFormProps>> = ({ onSuccess, onCancel, ...others }) => {
  const contactOrganization = useCurrentContactOrganization()
  const dispatch = useDispatch()
  const { tComponent } = ToolBox.useString()
  const { getFormDescription } = ToolBox.useFormTools()
  const {
    modalify: { open: openModal, close: closeModal }
  } = useFeedbacker()

  const onResetPasswordClick = () => {
    onCancel?.()

    openModal({
      content: (
        <PasswordForm
          props={{
            onSuccess: closeModal,
            portalId: 'modal-footer',
            cancelable: true,
            visualAction: 'reset_password',
            onCancel: closeModal
          }}
        />
      ),
      recordType: RecordType.user,
      action: 'reset_password',
      modalType: 'form',
      closable: false,
      maskClosable: false
    })
  }

  const payloadModifier = (values: IContactOrganization) => {
    return {
      contact_organization: {
        ...contactOrganization,
        contact_attributes: values.contact,
        customer_attributes: values.customer,
        ...values
      }
    }
  }

  const onUpdateSuccess = (values: IContactOrganization) => {
    dispatch({
      payload: values,
      type: USER.UPDATE_CONTACT_ORGANIZATION
    })
    onSuccess?.(values)
  }

  const translate = (id: string) =>
    tComponent({
      componentType: 'core',
      componentName: 'form',
      recordType: RecordType.profile,
      id
    })

  return (
    <Row>
      <Col span={24}>
        <h5 className="mb-0">{getFormDescription({ recordType: RecordType.profile })}</h5>
      </Col>
      <Col span={24}>
        <ContactOrganizationForm
          member={{
            ...contactOrganization,
            customer: { internal_service_allowed: contactOrganization?.internal_service_allowed }
          }}
          props={{
            payloadModifier,
            strictAttributes: ['contact_organization'],
            onSuccess: onUpdateSuccess,
            visualRecordType: RecordType.profile,
            onCancel,
            ...others
          }}>
          <Space direction="vertical" size={48}>
            <Space direction="vertical" size="small">
              <Link to={Routes.Setting.collection().index} onClick={() => onCancel?.()}>
                {translate('to_company_details')}
              </Link>
              <KNestedFields as="customer">
                <KCheckbox name="internal_service_allowed" />
              </KNestedFields>
            </Space>
            <Space direction="vertical" size="small">
              <h5>{getFormDescription({ recordType: RecordType.password })}</h5>
              <Button
                title="Mein Password Ändern"
                type="secondary"
                onClick={onResetPasswordClick}
              />
            </Space>
          </Space>
        </ContactOrganizationForm>
      </Col>
    </Row>
  )
}

export default ProfileForm
