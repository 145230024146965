import { Spin } from 'antd'
import * as React from 'react'

type Props = {
  loading: boolean
}

const SpinOverlay: React.FC<Props> = ({ loading }) => {
  return (
    <div hidden={!loading} className="spin-overlay">
      <Spin size="large" className="m-auto" spinning={true} />
    </div>
  )
}

export default SpinOverlay
