import { Typography } from 'antd'
import { useCurrentContact, useCurrentCustomer } from 'app/lib/hooks/useCurrent'
import { Routes } from 'app/routes/Routes'
import { Link } from 'react-router-dom'

const CustomerName = () => {
  const customer = useCurrentCustomer()
  const contact = useCurrentContact()

  const title = customer?.organization || contact?.name || contact?.email

  // We should actually check how many customer_organizations the user has
  // and disable the link if there is only one
  return (
    <Typography.Text ellipsis className="d-flex" style={{ maxWidth: 300 }}>
      <Link to={Routes.Account.collection().index}>{title}</Link>
    </Typography.Text>
  )
}

export default CustomerName
