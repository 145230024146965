import Button from 'app/components/UI-Elements/General/Button'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useExternalLink } from 'app/lib/hooks/useExternalLink'
import * as React from 'react'
import { ComponentProps } from 'react'

interface Props extends ComponentProps<typeof Button> {
  recordType?: string
  action?: string
  showTitle?: boolean
}

const KButton: React.FC<Props> = ({
  title,
  recordType,
  action,
  children,
  href,
  showTitle = true,
  ...others
}) => {
  const { tButtonTitle } = ToolBox.useString()
  const { buttonId } = ToolBox.useId()
  const { open } = useExternalLink(href)

  const id = buttonId({ recordType, action })
  const buttonTitle = title || (showTitle && tButtonTitle({ recordType, action })) // hides translation warnings

  const defaultHrefProps: ComponentProps<typeof Button> = {
    href,
    target: '_blank',
    onClick: open
  }

  return (
    <Button
      id={id}
      data-testid={id}
      title={buttonTitle}
      {...(href ? defaultHrefProps : {})}
      {...others}>
      {children}
    </Button>
  )
}

export default KButton
