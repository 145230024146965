/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions, no-sequences */
import EnvironmentService from 'app/lib/services/EnvironmentService'

import ScriptService from '../services/ScriptService'

export default function initGoogleAnalytics(history) {
  if (EnvironmentService.isProduction()) {
    window.dataLayer = window.dataLayer || []
    ScriptService.gtag('js', new Date())
    ScriptService.gtag('config', EnvironmentService.getEnvValue('GTAG_KEY'))
    ScriptService.gtag('event', 'page_view')
  }

  history.listen(function (location) {
    ScriptService.gtag('event', 'page_view', {
      page_location: location.pathname + location.search
    })
  })
}
