import { Grid } from 'antd'
import * as React from 'react'

interface Props {
  mobile: React.ReactElement
  desktop: React.ReactElement
  breakpoint?: 'sm' | 'md' | 'lg'
}

function MobileFriendly<T>({ mobile, desktop, breakpoint = 'sm', ...others }: Props & T) {
  const breakpoints = Grid.useBreakpoint()

  return (
    <Choose>
      <When condition={breakpoints[breakpoint] === true}>
        {React.cloneElement(desktop, { ...others })}
      </When>
      <When condition={breakpoints[breakpoint] === false}>
        {React.cloneElement(mobile, { ...others })}
      </When>
    </Choose>
  )
}

export default MobileFriendly
