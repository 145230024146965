import { gql } from '@apollo/client/core'

import { ATTRIBUTE_SEGMENTATION_ATTRIBUTES } from './fragments'
import { CATEGORY_ATTRIBUTE_ATTRIBUTES } from './fragments/CategoryAttribute'
import { CATEGORY_SEGMENT_DETAILED_ATTRIBUTES } from './fragments/CategorySegment'
import { UNIT_ATTRIBUTES } from './fragments/Unit'

const FETCH_CATEGORY_SEGMENT_QUERY = gql`
  query fetchCategorySegment(
    $id: ID!
    $includeAttributeSegmentations: Boolean = false
    $includeCategorySegmentDetails: Boolean = false
    $includeCategoryAttributes: Boolean = false
    $zip: String
    $startDate: String
    $includeMinimumReturnDate: Boolean = false
  ) {
    categorySegment(id: $id) {
      id
      name
      imageUrl
      minimumRentalTime
      minimumRentalDuration
      minimumReturnDate(startDate: $startDate) @include(if: $includeMinimumReturnDate)
      unit {
        ...unitAttributes
      }
      ...categorySegmentDetailedAttributes @include(if: $includeCategorySegmentDetails)
      earliestAvailableDate(zip: $zip) @include(if: $includeCategorySegmentDetails)
      earliestAvailableDateTime(zip: $zip) @include(if: $includeCategorySegmentDetails)
      attributeSegmentations @include(if: $includeAttributeSegmentations) {
        ...attributeSegmentationAttributes
      }
      category @include(if: $includeCategoryAttributes) {
        categoryAttributes {
          ...categoryAttributeAttributes
        }
      }
    }
  }
  ${ATTRIBUTE_SEGMENTATION_ATTRIBUTES}
  ${CATEGORY_SEGMENT_DETAILED_ATTRIBUTES}
  ${CATEGORY_ATTRIBUTE_ATTRIBUTES}
  ${UNIT_ATTRIBUTES}
`

const FETCH_CATEGORY_SEGMENT_ATTACHMENTS_QUERY = gql`
  query fetchAttachments($id: ID!, $lineItemId: ID!) {
    categorySegment(id: $id) {
      name
      id
      activeAttachments {
        id
        name
        free(categorySegmentId: $id)
        package {
          name
        }
        categorySegment {
          id
          imageUrl
          earliestAvailableDate
        }
      }
    }
    lineItem(id: $lineItemId) {
      id
      subItems {
        id
        categorySegmentId
      }
    }
  }
`

const FETCH_MINIMUM_RENTAL_DATE_QUERY = gql`
  query fetchMinimumRentalDate($id: ID!, $startDate: String) {
    categorySegment(id: $id) {
      id
      minimumReturnDate(startDate: $startDate)
    }
  }
`

export {
  FETCH_CATEGORY_SEGMENT_QUERY,
  FETCH_CATEGORY_SEGMENT_ATTACHMENTS_QUERY,
  FETCH_MINIMUM_RENTAL_DATE_QUERY
}
