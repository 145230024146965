import { RouteType } from 'app/constants/GlobalTypes'
import { Routes } from 'app/routes/Routes'

export const AuthenticationRoutes: RouteType[] = [
  {
    key: `${Routes.Authentication.Key}.login`,
    path: Routes.Authentication.collection().login,
    component: import('app/pages/Authentication/Views/login'),
    routes: []
  },
  {
    key: `${Routes.Authentication.Key}.forgot_password`,
    path: Routes.Authentication.collection().forgot_password,
    component: import('app/pages/PasswordRequest/Views/create'),
    routes: []
  },
  {
    key: `${Routes.Authentication.Key}.email_confirmation`,
    path: Routes.Authentication.collection().email_confirmation,
    component: import('app/pages/Authentication/Views/email_confirmation'),
    routes: []
  }
]
