import {
  PlusOutlined,
  ProjectOutlined,
  ShoppingCartOutlined,
  UserAddOutlined
} from '@ant-design/icons'
import { Dropdown, MenuProps, Space } from 'antd'
import ContactOrganizationForm from 'app/components/Core/ContactOrganization/Form'
import { useCreateProject } from 'app/components/Core/Project/hooks/useCreate'
import Button from 'app/components/UI-Elements/General/Button'
import Restricted from 'app/components/UI-Elements/General/Restricted'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useRentalCreate } from 'app/lib/hooks/useRentalCreate'
import { useRestriction } from 'app/lib/hooks/useRestriction'
import { useIntl } from 'react-intl'

const portalId = 'contact-create-portal-id'

const QuickActions = () => {
  const { formatMessage } = useIntl()
  const { createRental, loading } = useRentalCreate()
  const { onCreate: onCreateProject } = useCreateProject()
  const {
    drawerfy: { open: openDrawer }
  } = useFeedbacker()
  const { generateId } = ToolBox.useId()
  const { restricted } = useRestriction({ allowFor: ['admin'] })
  const getId = (id: string) =>
    generateId({ recordType: RecordType.overview, id, componentType: 'quick_actions' })

  const onCreateContact = () => {
    openDrawer({
      content: <ContactOrganizationForm props={{ portalId }} />,
      recordType: RecordType.user,
      action: 'create',
      width: 500,
      destroyOnClose: true,
      footer: <div id={portalId} />
    })
  }

  const menuItems: MenuProps['items'] = [
    {
      key: 'project',
      onClick: onCreateProject,
      label: (
        <Space id={getId('create_project')}>
          <ProjectOutlined />
          {formatMessage({ id: 'add.project' })}
        </Space>
      )
    },
    {
      key: 'rental',
      onClick: () => createRental(),
      label: (
        <Space id={getId('create_rental')}>
          <ShoppingCartOutlined />
          {formatMessage({ id: 'add.rental' })}
        </Space>
      )
    },
    !restricted && {
      key: 'user',
      onClick: () => onCreateContact(),
      label: (
        <Space id={getId('create_contact')}>
          <UserAddOutlined />
          {formatMessage({ id: 'add.new.user' })}
        </Space>
      )
    }
  ]

  return (
    <Restricted allowFor={['admin', 'manager', 'member']} mode="disable">
      <Dropdown menu={{ items: menuItems }} trigger={['click']}>
        <Button
          loading={loading}
          shape="circle"
          type="primary"
          size="middle"
          icon={<PlusOutlined />}
        />
      </Dropdown>
    </Restricted>
  )
}
export default QuickActions
