import { useSelector } from 'react-redux'

export const useSession = () => {
  const session = useSelector<AppState, SessionReducerState>((state) => state.session)
  return session
}

export const useCurrentContact = (): IContact => {
  const { contactOrganization } = useSelector<AppState, SessionReducerState>(
    (state) => state.session
  )
  return contactOrganization.contact
}

export const useCurrentCustomer = (): ICustomer => {
  const { customer } = useSelector<AppState, SessionReducerState>((state) => state.session)
  return customer
}

export const useCurrentContactOrganization = (): IContactOrganization => {
  const { contactOrganization } = useSelector<AppState, SessionReducerState>(
    (state) => state.session
  )
  return contactOrganization
}

export const useCurrentConfig = (): IConfig => {
  const { config } = useSelector<AppState, SessionReducerState>((state) => state.session)
  return config
}
