import { ArrowLeftOutlined } from '@ant-design/icons/lib'
import { Button, Drawer, Row } from 'antd'
import { KDrawerProps } from 'app/components/Modules/Feedback/typing'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { useBrowserRouterContext } from 'app/lib/contexts/KBrowserRouter'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'
import { useEffect } from 'react'

export const footerPortalId = 'drawer-footer'

const KDrawer: React.FC<KDrawerProps> = ({
  title,
  recordType,
  action,
  open,
  subtitle,
  drawerType = 'default',
  footer,
  drawerKey,
  titleExtra,
  onClose,
  ...others
}) => {
  const { addBackAction, dispatch } = useBrowserRouterContext()

  const actionKey = drawerKey ? `drawer-${drawerKey}` : `drawer-${recordType}-${action}`

  useEffect(() => {
    if (open) {
      addBackAction(onClose, actionKey)
    } else {
      dispatch({ type: 'REMOVE_DRAWER_ACTION', drawerKey: actionKey })
    }
  }, [open])

  const { smartTranslation } = ToolBox.useString()
  const getFooter = () => (
    <Choose>
      <When condition={drawerType === 'form'}>
        <div id={footerPortalId} />
      </When>
      <Otherwise>{footer}</Otherwise>
    </Choose>
  )

  const getTitle = () => {
    if (drawerType === 'page') {
      return (
        <Row align="middle" wrap={false} className="header-height">
          <KCol flex={'40px'}>
            <Button
              data-testid="back-button"
              id="close-button"
              icon={<ArrowLeftOutlined />}
              onClick={() => onClose?.(null)}
            />
          </KCol>
          <KCol className="text-center" flex={'auto'}>
            <h5 className="mb-0">
              {open &&
                (title ||
                  smartTranslation({
                    recordType,
                    id: `component.feedback.drawer.title.${recordType}.${action}`
                  }))}
              {titleExtra}
            </h5>
          </KCol>
          <KCol flex={'40px'} />
        </Row>
      )
    } else {
      return (
        <>
          <div>
            <h5 className="mb-0">
              {open &&
                (title ||
                  smartTranslation({
                    recordType,
                    id: `component.feedback.drawer.title.${recordType}.${action}`
                  }))}
              {titleExtra}
            </h5>
          </div>
          <If condition={subtitle}>
            <span className="text-sm">{subtitle}</span>
          </If>
        </>
      )
    }
  }

  return (
    <Drawer
      className={drawerType === 'page' ? 'page' : ''}
      title={getTitle()}
      open={open}
      footer={getFooter()}
      closable={drawerType !== 'page'}
      onClose={onClose}
      {...others}
    />
  )
}

export default KDrawer
