import { MemberProps } from 'app/components/Core/convention'
import KAddressInput from 'app/components/Modules/Form/KAddressInput'
import KDateRange from 'app/components/Modules/Form/KDateRange'
import KForm from 'app/components/Modules/Form/KForm'
import KInputText from 'app/components/Modules/Form/KInputText'
import { KFormProps, RecordType } from 'app/constants/GlobalTypes'
import useTranslation from 'app/lib/hooks/useTranslation'
import * as React from 'react'

const Form: React.FC<MemberProps<IProject, KFormProps<IProject>>> = ({
  member,
  props: { onSuccess, onCancel }
}) => {
  const { tAttribute } = useTranslation({ recordType: RecordType.address })

  return (
    <KForm
      recordId={member?.id}
      recordType={RecordType.project}
      layout="vertical"
      portalId="modal-footer"
      cancelable
      onCancel={onCancel}
      onSuccess={onSuccess}>
      <KInputText name="name" />
      <KAddressInput
        name="display_address"
        label={tAttribute({ id: 'address' })}
        required
        recordTypeOverride={'address'}
      />
      <KDateRange name={'dates'} startName={'start_date'} endName={'end_date'} />
    </KForm>
  )
}

export default Form
