import { Drawer, Grid, Layout } from 'antd'
import { SiderProps } from 'antd/lib/layout'
import Support from 'app/components/Core/Customer/Support'
import Menu from 'app/components/UI-Elements/Layout/Sider/Menu'
import * as React from 'react'

import Restricted from '../../General/Restricted'
import Logo from '../Header/Logo'

interface Props extends SiderProps {
  footer?: React.ReactNode
  toggleDrawer?: () => void
  open?: boolean
}

const Sider: React.FC<Props> = ({ toggleDrawer, open, ...others }) => {
  const [collapsed, setCollapsed] = React.useState<boolean>(false)
  const screens = Grid.useBreakpoint()

  return (
    <Restricted>
      {!screens.xl ? (
        <Drawer
          onClose={toggleDrawer}
          title={<Logo />}
          closable={true}
          placement={'left'}
          open={open}
          footer={<Support />}>
          <Menu onClick={toggleDrawer} />
        </Drawer>
      ) : (
        <Layout.Sider
          collapsible={true}
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          width={240}
          {...others}>
          <Menu />
          <Support showConnectButton={!collapsed} />
        </Layout.Sider>
      )}
    </Restricted>
  )
}

export default Sider
