import { LanguageSelectOptionType } from 'app/constants/GlobalTypes'

import { LocaleEnum } from './Enums'

export const LOGO_FILE = '/assets/images/logo.svg'

export const KLARX_INFO_EMAIL = 'info@klarx.de'
export const KLARX_PHONE = '+4989125014983'
export const KLARX_PHONE_DISPLAY = '+49 89 125014983'

export const themeColor = '#E75113'

export const allLocales: LanguageSelectOptionType[] = [
  {
    id: 1,
    language: LocaleEnum.de,
    label: 'Deutsch',
    icon: 'de'
  },
  {
    id: 2,
    language: LocaleEnum.en,
    label: 'English',
    icon: 'us'
  }
]
