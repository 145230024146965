import KFormContextProvider, { useKFormContext } from 'app/lib/contexts/KFormContext'
import * as React from 'react'

const KNestedFields: React.FC<{ as: string; children: React.ReactNode }> = ({ as, children }) => {
  const { form, loading } = useKFormContext()

  return (
    <KFormContextProvider nested recordType={as} form={form} loading={loading}>
      {children}
    </KFormContextProvider>
  )
}

export default KNestedFields
