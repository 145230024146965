import { DeleteOutlined } from '@ant-design/icons/lib'
import { Popconfirm } from 'antd'
import { KDestroyButtonProps } from 'app/components/Modules/Form/typing'
import Button from 'app/components/UI-Elements/General/Button'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { useDestroy } from 'app/lib/hooks/useApi'
import * as React from 'react'

const KDestroyButton: React.FC<KDestroyButtonProps> = ({
  className,
  recordType,
  visualRecordType = recordType,
  recordId,
  onSuccess,
  onFailure,
  children
}) => {
  const { smartTranslation } = ToolBox.useString()
  const { buttonId } = ToolBox.useId()
  const id = buttonId({ recordType: visualRecordType, action: 'destroy' })

  const { loading, execute } = useDestroy(recordType, visualRecordType)

  const onConfirm = async () => {
    try {
      await execute({ id: recordId })
      onSuccess?.()
    } catch (e) {
      onFailure?.(e)
    }
  }

  return (
    <Popconfirm
      onConfirm={onConfirm}
      getPopupContainer={(target) => target}
      title={smartTranslation({
        id: `form.${visualRecordType}.prompt.destroy`,
        recordType: visualRecordType
      })}>
      <Choose>
        <When condition={Boolean(children)}>{children}</When>
        <Otherwise>
          <Button
            danger
            id={id}
            data-testid={id}
            loading={loading}
            className={className}
            icon={<DeleteOutlined />}
            type="primary"
            title={smartTranslation({
              id: `form.${visualRecordType}.action.destroy`,
              recordType: visualRecordType
            })}
          />
        </Otherwise>
      </Choose>
    </Popconfirm>
  )
}

export default KDestroyButton
