import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './app/lib/Bugsnag'

import App from 'app/App'
import ErrorBoundary from 'app/components/UI-Elements/General/ErrorBoundary'
import { allLocales } from 'app/constants/Global'
import AppProviders from 'app/lib/AppProviders'
import { isCypress } from 'app/lib/services/EnvironmentService'
import { history } from 'app/redux/store'
import areIntlLocalesSupported from 'intl-locales-supported'
import { browserVersion, isIE } from 'react-device-detect'
import { createRoot } from 'react-dom/client'

import initBingAnalytics from './app/lib/config/BingAnalytics'
import initGoogleAdwords from './app/lib/config/GoogleAdwordConfig'
import initGoogleAnalytics from './app/lib/config/GoogleAnalytics'
import initHeapAnalytics from './app/lib/config/HeapAnalytics'
import initMouseflow from './app/lib/config/Mouseflow'

if (!(isIE && parseInt(browserVersion) < 11) && !isCypress) {
  initGoogleAdwords()
  initGoogleAnalytics(history)
  initBingAnalytics(history)
  initMouseflow()
  initHeapAnalytics()
}

if (global.Intl) {
  if (!areIntlLocalesSupported(allLocales.map((i) => i.language))) {
    const IntlPolyfill = require('intl')
    Intl.NumberFormat = IntlPolyfill.NumberFormat
    Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat
    import('@formatjs/intl-relativetimeformat/polyfill')
    import('@formatjs/intl-relativetimeformat/locale-data/en')
    import('@formatjs/intl-relativetimeformat/locale-data/de')
  }
} else {
  global.Intl = require('intl')
  window.Intl = require('intl')
}

const container = document.getElementById('app-site') || document.createElement('div')

const root = createRoot(container)
root.render(
  <AppProviders>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </AppProviders>
)
