import { Row } from 'antd'
import KCol from 'app/components/UI-Elements/Layout/KCol'

type InfoProps = {
  icon?: React.ReactNode
  title: React.ReactNode
  info: React.ReactNode
}

const KInfo: React.FC<InfoProps> = ({ icon, title, info }) => (
  <Row gutter={[24, 0]} align="middle">
    <KCol render={Boolean(icon)}>{icon}</KCol>
    <KCol>
      <p className="text-primary mb-1">{title}</p>
      <p className="mb-1">{info || '-'}</p>
    </KCol>
  </Row>
)

export default KInfo
