import '../styles/app.less'
import '../styles/app.scss'
import '../lngProvider' // Needs to be included here, but don't know why

import { ConfigProvider } from 'antd'
import SpinOverlay from 'app/components/UI-Elements/Feedback/Spin'
import FeedbackProvider from 'app/lib/contexts/Feedbacker'
import BrowserService from 'app/lib/services/BrowserService'
import { getQueryString } from 'app/lib/utils/search-params'
import MainRoutes from 'app/routes/Main'
import { Routes } from 'app/routes/Routes'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { themeConfig } from './constants/ThemeConfig'
import KApolloProvider from './lib/apollo/Provider'
import setAxiosInterceptor from './lib/axios'
import KBrowserRouter from './lib/contexts/KBrowserRouter'
import { useSession } from './lib/hooks/useCurrent'
import { UIProvider } from './lib/providers/UIProvider'
import AuthenticationRoot from './pages/Authentication/Root'
import { authenticateUser, authenticateUserWithLoginToken } from './redux/actions/__legacy/User'
import AppListener from './routes/AppListener'

const App: React.FC = () => {
  const { loggedIn, contactOrganization, loading } = useSession()
  const location = window.location || { search: '', pathname: '' }

  useEffect(() => {
    const { ejectInterceptors } = setAxiosInterceptor()
    const urlToken = getQueryString(location.search, 'login_token')
    const customerId = BrowserService.getCustomerIdParam(location.pathname)

    if (urlToken) {
      authenticateUserWithLoginToken(urlToken, customerId)
    } else if (!loggedIn) {
      authenticateUser(customerId).catch((err: string) => console.log({ err }))
    }
    return () => ejectInterceptors()
  }, [])

  if (loading) {
    return (
      <ConfigProvider theme={themeConfig}>
        <SpinOverlay loading={true} />
      </ConfigProvider>
    )
  }

  const basename = contactOrganization?.parentable_id
    ? Routes.Base(contactOrganization.parentable_id)
    : ''

  if (basename && !location.pathname.startsWith(basename)) {
    BrowserService.redirectToLink(`${basename}${location.pathname}${location.search}`)
  }

  return (
    <BrowserRouter basename={basename}>
      <KBrowserRouter>
        <AppListener />
        <UIProvider>
          <KApolloProvider>
            <FeedbackProvider>
              <Choose>
                <When condition={!loggedIn}>
                  <AuthenticationRoot />
                </When>
                <Otherwise>
                  <MainRoutes />
                </Otherwise>
              </Choose>
            </FeedbackProvider>
          </KApolloProvider>
        </UIProvider>
      </KBrowserRouter>
    </BrowserRouter>
  )
}

export default App
