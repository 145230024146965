import { NotifyIntent, NotifyOptions } from '@2wunder/klarx-tool'
import { InfoCircleOutlined } from '@ant-design/icons'
import { notification } from 'antd'
import { NotificationPlacement } from 'antd/lib/notification/interface'
import { ReactNode } from 'react'

import { FeedbackType } from './typing'

type AntdNotifyOptions = Partial<{
  top: number
  bottom: number
  description: ReactNode
  icon: ReactNode
}>

export const notify = (options: NotifyOptions<AntdNotifyOptions>) => {
  const { type, ...config } = AntdNotifyAdapter(options)

  notification[type](config)
}

const AntdNotifyAdapter = (options: NotifyOptions<AntdNotifyOptions>) => {
  return {
    type: INTENT_MAP[options.intent] || DEFAULT_ATTRIBUTES['intent'],
    placement: options.position || DEFAULT_ATTRIBUTES['placement'],
    duration: options.timeout || DEFAULT_ATTRIBUTES['timeout'],
    message: options.message,
    description: options.additionalOptions?.description,
    top: options.additionalOptions?.top || DEFAULT_ATTRIBUTES['top'],
    bottom: options.additionalOptions?.bottom || DEFAULT_ATTRIBUTES['bottom'],
    icon: options.additionalOptions?.icon || DEFAULT_ATTRIBUTES['icon'],
    className: options.className,
    onClose: options.onDismiss
  }
}

const DEFAULT_ATTRIBUTES = {
  intent: 'error' as FeedbackType,
  placement: 'bottomRight' as NotificationPlacement,
  timeout: 5,
  top: 0,
  bottom: 0,
  icon: <InfoCircleOutlined />
}

const INTENT_MAP: Record<NotifyIntent, FeedbackType> = {
  success: 'success',
  danger: 'error',
  warning: 'warning',
  info: 'info'
}
