import SpinOverlay from 'app/components/UI-Elements/Feedback/Spin'
import { RouteType } from 'app/constants/GlobalTypes'
import { retry } from 'app/lib/utils/retry'
import * as React from 'react'
import { lazy, Suspense } from 'react'
import { Route, Routes as Switch } from 'react-router-dom'

import RestrictedRoute from './RestrictedRoute'

type PageType = { default: React.ComponentType<{ route: Partial<RouteType> }> }

const getRoutePath = (route: RouteType) => (route.exact ? route.path : `${route.path}/*`)

export const treeRenderer = (routes: RouteType[]) => {
  const renderRoute = (route: RouteType): React.ReactNode => {
    const Page = lazy(() => retry(() => route.component) as Promise<PageType>)

    return (
      <Route
        key={route.key}
        path={getRoutePath(route)}
        element={
          <RestrictedRoute restrictionMode={route.restrictionMode} allowFor={route.allowFor}>
            <Suspense fallback={<SpinOverlay loading={true} />}>
              <Page route={route} />
            </Suspense>
          </RestrictedRoute>
        }
      />
    )
  }

  const renderSubRoutes = (parent: RouteType) => {
    const Page = lazy(() => retry(() => parent.component) as Promise<PageType>)
    const feedbackRoutes = parent.routes.filter((route) => route.asFeedback)
    const routes = parent.routes.filter((route) => !route.asFeedback)

    return (
      <Route
        key={parent.key}
        path={getRoutePath(parent)}
        element={
          <RestrictedRoute allowFor={parent.allowFor}>
            <>
              <Switch>
                {routes.map((route) =>
                  route.routes.length > 0 ? renderSubRoutes(route) : renderRoute(route)
                )}
                <Route
                  key={parent.key}
                  path="/*"
                  element={
                    <Suspense fallback={<SpinOverlay loading={true} />}>
                      <Page route={parent} />
                    </Suspense>
                  }
                />
              </Switch>
              <Switch>{feedbackRoutes.map((route) => renderRoute(route))}</Switch>
            </>
          </RestrictedRoute>
        }
      />
    )
  }

  return routes.map((route) =>
    route.routes.length > 0 ? renderSubRoutes(route) : renderRoute(route)
  )
}
