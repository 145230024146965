import { AUTH, USER } from 'app/redux/reducers/actionTypes'

const initialState: SessionReducerState = {
  langCode: 'de',
  loading: true,
  loggedIn: false,
  contactOrganization: null,
  customer: null,
  authenticationErrors: null,
  config: {}
}

const user = (state: SessionReducerState = initialState, action: ActionTypes) => {
  switch (action.type) {
    case AUTH.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        authenticationErrors: null,
        ...action.payload
      }

    case AUTH.SESSION_FAILURE: // Show login on session failure
      return {
        ...state,
        loading: false,
        loggedIn: false,
        authenticationErrors: action.payload
      }

    case USER.UPDATE_LOCALE:
      return {
        ...state,
        langCode: action.payload.language
      }

    case USER.UPDATE_CONTACT_ORGANIZATION:
      return {
        ...state,
        contactOrganization: action.payload
      }

    default:
      return state
  }
}

export default user
