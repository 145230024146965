import { Flex, Layout } from 'antd'
import ProfileAvatar from 'app/components/Core/Profile/Avatar'
import Container from 'app/components/UI-Elements/Layout/Container'
import Logo from 'app/components/UI-Elements/Layout/Header/Logo'
import * as React from 'react'

import TitleExtra from './TitleExtra'

type Props = {
  hideProfile?: boolean
  showCancel?: boolean
}

const PlainHeader: React.FC<Props> = ({ hideProfile = false, showCancel = false }) => {
  return (
    <Layout.Header>
      <Container card={false} className="header-height">
        <Flex justify="space-between" align="middle" className="mx-4 full-height">
          <Logo showCancel={showCancel} />
          <If condition={TitleExtra({}) !== null}>
            <TitleExtra />
          </If>
          <div hidden={hideProfile}>
            <ProfileAvatar />
          </div>
        </Flex>
      </Container>
    </Layout.Header>
  )
}

export default PlainHeader
