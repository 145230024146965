import ProjectForm from 'app/components/Core/Project/Form'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { Routes } from 'app/routes/Routes'
import { useNavigate } from 'react-router'

export const useCreateProject = ({
  onSuccess
}: { onSuccess?: (project: IProject) => void } = {}) => {
  const {
    modalify: { open, close }
  } = useFeedbacker()
  const navigate = useNavigate()

  const onCreateSuccess = (project: IProject) => {
    close()
    navigate(Routes.Project.member(project.id.toString()).show, { replace: true })

    onSuccess?.(project)
  }

  const onCreate = () => {
    open({
      content: <ProjectForm props={{ onSuccess: onCreateSuccess, onCancel: close }} />,
      recordType: RecordType.project,
      action: 'create',
      destroyOnClose: true,
      modalType: 'form'
    })
  }

  return { onCreate }
}
