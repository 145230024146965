import { gql } from '@apollo/client/core'

export const CATEGORY_ATTRIBUTE_ATTRIBUTES = gql`
  fragment categoryAttributeAttributes on CategoryAttribute {
    id
    attributeDetailId
    descriptionShort
    descriptionLong
    attributeType
    displayMetric
    featureValues
    question
    required
    questionText
    questionTextShort
  }
`
