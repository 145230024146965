import { App as CapApp } from '@capacitor/app'
import useChangeEffect from 'app/lib/hooks/useChangeEffect'
import { Routes } from 'app/routes/Routes'
import { last } from 'lodash'
import React, { PropsWithChildren, Reducer, useContext, useReducer } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useBackButton } from '../hooks/useCapAppListener'

type Action = {
  type: 'REMOVE_DRAWER_ACTION' | 'SET_STATE'
  drawerKey?: string
  state?: Partial<State>
}

type KBrowserRouterTye = {
  goBack?: () => void
  setBackRoute?: (backRoute: string) => void
  setBackAction?: (backAction: BackAction, key?: string) => void
  addBackAction?: (backAction: BackAction, key?: string) => void
  removeLast?: () => void
  dispatch?: (action: Action) => void
}

type BackAction = (e?: unknown) => void

type BackConfig = {
  key?: string
  backAction?: BackAction
  backRoute?: string
}

type State = {
  backHistory: BackConfig[]
  hasBackRoute: boolean
}

const KBrowserRouterContext = React.createContext<KBrowserRouterTye>(null)

const reducer: Reducer<State, Action> = (state, action) => {
  const getNewBackHistory = () => {
    const newBackHistory = state.backHistory.filter(
      ({ key: historyKey }) => historyKey !== action.drawerKey
    )
    return newBackHistory
  }

  switch (action.type) {
    case 'REMOVE_DRAWER_ACTION':
      return { ...state, backHistory: getNewBackHistory() }
    case 'SET_STATE':
      return { ...state, ...action.state }
    default:
      return state
  }
}

export function useBrowserRouterContext(): KBrowserRouterTye {
  return useContext(KBrowserRouterContext)
}

export const defaultRoute = Routes.Overview.collection().index

const initialState: State = {
  hasBackRoute: false,
  backHistory: []
}

let blockResetHistory = false

const KBrowserRouter: React.FC<PropsWithChildren> = ({ ...others }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const setState = (state: Partial<State>) => dispatch({ type: 'SET_STATE', state })
  const navigate = useNavigate()
  const location = useLocation()

  const { backHistory, hasBackRoute } = state

  useChangeEffect(() => {
    setState({
      hasBackRoute: true,
      ...(blockResetHistory ? {} : { backHistory: [] })
    })
    blockResetHistory = false
  }, [location.pathname])

  // Subscribe to android back button
  useBackButton(() => {
    if (location.pathname === defaultRoute) {
      CapApp.exitApp()
    } else {
      goBack()
    }
  })

  const setBackHistory = (backHistory: BackConfig[]) => {
    blockResetHistory = true
    setState({ backHistory })
  }

  const setBackRoute = (backRoute: string) => {
    setBackHistory([{ backRoute }])
  }

  const setBackAction = (backAction: BackAction, key?: string) => {
    setBackHistory([{ backAction, key }])
  }

  const addBackAction = (backAction: BackAction, key?: string) => {
    setBackHistory([...backHistory, { backAction, key }])
  }

  const removeLast = () => {
    if (backHistory.length > 0) {
      setBackHistory(backHistory.slice(0, backHistory.length - 1))
    } else {
      setBackHistory([])
    }
  }
  const getLast = () => {
    return last(backHistory) || {}
  }

  const goBack = () => {
    const { backAction, backRoute } = getLast()
    if (backAction) {
      backAction()
    } else if (backRoute) {
      navigate(backRoute)
    } else if (hasBackRoute) {
      navigate(-1)
    } else {
      navigate(defaultRoute)
    }
    removeLast()
  }

  return (
    <KBrowserRouterContext.Provider
      value={{
        setBackRoute,
        setBackAction,
        addBackAction,
        goBack,
        removeLast,
        dispatch
      }}
      {...others}
    />
  )
}

export default KBrowserRouter
