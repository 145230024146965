import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import KHelp from 'app/components/Modules/KHelp'
import { RecordType } from 'app/constants/GlobalTypes'
import { Routes } from 'app/routes/Routes'
import * as React from 'react'
import { useMatch } from 'react-router-dom'

const TitleExtra: React.FC = () => {
  const matchMachines = useMatch(Routes.Checkout.member().machines)
  const { lg } = useBreakpoint()

  if (matchMachines) {
    return (
      <KHelp
        recordType={RecordType.checkout}
        id="general"
        popoverProps={{ placement: 'bottom', className: 'px-1' }}
        popover={!lg}
      />
    )
  }

  return null
}

export default TitleExtra
