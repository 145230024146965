/* eslint-disable max-lines */
import { RecordType } from 'app/constants/GlobalTypes'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { CollectionArgs, MemberArgs } from 'app/redux/actions/typing'

export const ExtendLineItemRequest = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${ExtendLineItemRequest.base}${ToolBox.parameterize({
      ...params,
      ...ExtendLineItemRequest.params
    })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${ExtendLineItemRequest.base}${ToolBox.parameterize({
        ...params,
        ...ExtendLineItemRequest.params
      })}`,
      show: `/${ExtendLineItemRequest.base}/${id}${ToolBox.parameterize({
        ...params,
        ...ExtendLineItemRequest.params
      })}`,
      update: `/${ExtendLineItemRequest.base}/${id}${ToolBox.parameterize({
        ...params,
        ...ExtendLineItemRequest.params
      })}`,
      delete: `/${ExtendLineItemRequest.base}/${id}`
    }
  },
  base: RecordType.plurify().extend_line_item_request,
  params: {}
}

export const Search = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Search.base}${ToolBox.parameterize({ ...params, ...Search.params })}`
  }),
  base: RecordType.search,
  params: {}
}

export const CustomerRole = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${CustomerRole.base}${ToolBox.parameterize({ ...params, ...CustomerRole.params })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${CustomerRole.base}/${id}${ToolBox.parameterize({
        ...params,
        ...CustomerRole.params
      })}`
    }
  },
  base: RecordType.plurify().customer_role,
  params: {}
}

export const Contact = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Contact.base}${ToolBox.parameterize({ ...params, ...Contact.params })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Contact.base}/${id}${ToolBox.parameterize({ ...Contact.params, ...params })}`,
      update: `/${Contact.base}/${id}${ToolBox.parameterize({ ...Contact.params, ...params })}`,
      destroy: `/${Contact.base}/${id}${ToolBox.parameterize({ ...Contact.params, ...params })}`,
      create: `/${Contact.base}/${ToolBox.parameterize({ ...Contact.params, ...params })}`
    }
  },
  base: RecordType.plurify().contact,
  params: {
    includes: [RecordType.user]
  }
}

export const ContactOrganization = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${ContactOrganization.base}${ToolBox.parameterize({
      ...params,
      ...ContactOrganization.params
    })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${ContactOrganization.base}/${id}${ToolBox.parameterize({
        ...ContactOrganization.params,
        ...params
      })}`,
      update: `/${ContactOrganization.base}/${id}${ToolBox.parameterize({
        ...ContactOrganization.params,
        ...params
      })}`,
      destroy: `/${ContactOrganization.base}/${id}${ToolBox.parameterize({
        ...ContactOrganization.params,
        ...params
      })}`,
      create: `/${ContactOrganization.base}/${ToolBox.parameterize({
        ...ContactOrganization.params,
        ...params
      })}`
    }
  },
  base: RecordType.plurify().contact_organization,
  params: {
    includes: [RecordType.user, RecordType.contact]
  }
}

export const Notification = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Notification.base}${ToolBox.parameterize({ ...params, ...Notification.params })}`
  }),
  base: RecordType.plurify().notification,
  params: {}
}

export const Activity = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Activity.base}${ToolBox.parameterize({ ...params, ...Activity.params })}`
  }),
  base: RecordType.plurify().activity,
  params: {}
}

export const Quote = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Quote.base}/${id}${ToolBox.parameterize({ ...params, ...Quote.params })}`
    }
  },
  base: RecordType.plurify().quote,
  params: {}
}

export const Rental = {
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${Rental.base}${ToolBox.parameterize({ ...params, ...Rental.params })}`
    }
  },
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Rental.base}/${id}${ToolBox.parameterize({ ...Rental.params, ...params })}`,
      create: `/${Rental.base}/${ToolBox.parameterize({ ...Rental.params, ...params })}`,
      update: `/${Rental.base}/${id}${ToolBox.parameterize({ ...Rental.params, ...params })}`
    }
  },
  base: RecordType.plurify().rental,
  params: {
    includes: [RecordType.project, RecordType.quote],
    serializer: 'status_info'
  }
}

export const QuoteFeedback = {
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${QuoteFeedback.base}/${ToolBox.parameterize({
        ...params,
        ...QuoteFeedback.params
      })}`
    }
  },
  base: RecordType.plurify().quote_feedback,
  params: {}
}

export const LineItem = {
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${LineItem.base}${ToolBox.parameterize({ ...LineItem.params, ...params })}`
    }
  },
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${LineItem.base}/${id}${ToolBox.parameterize({ ...LineItem.params, ...params })}`,
      update: `/${LineItem.base}/${id}${ToolBox.parameterize({ ...LineItem.params, ...params })}`,
      destroy: `/${LineItem.base}/${id}${ToolBox.parameterize({ ...LineItem.params, ...params })}`,
      create: `/${LineItem.base}/${ToolBox.parameterize({ ...LineItem.params, ...params })}`
    }
  },
  base: RecordType.plurify().line_item,
  params: {
    includes: [RecordType.plurify().sub_item, RecordType.plurify().category_attribute],
    serializer: 'default'
  }
}

export const DeliveryItem = {
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${DeliveryItem.base}${ToolBox.parameterize({ ...params, ...DeliveryItem.params })}`
    }
  },
  base: RecordType.plurify().delivery_item,
  params: {
    sort: 'start_date'
  }
}

export const DamageItem = {
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${DamageItem.base}${ToolBox.parameterize({ ...params, ...DamageItem.params })}`
    }
  },
  base: RecordType.plurify().damage_item,
  params: {
    includes: [RecordType.damage_info]
  }
}

export const User = {
  collection: (params: CollectionArgs = {}) => {
    return {
      index: `/${User.base}${ToolBox.parameterize({ ...params, ...User.params })}`
    }
  },
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${User.base}/${id}${ToolBox.parameterize({ ...params, ...User.params })}`,
      // this update refer to the current user, not needed to provide the id backend will automatically pick the current user.
      update: `/${User.base}/${ToolBox.parameterize({ ...params, ...User.params })}`
    }
  },
  base: RecordType.plurify().user,
  params: {}
}

export const Project = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Project.base}${ToolBox.parameterize({ ...params, ...Project.params })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Project.base}/${ToolBox.parameterize({ ...params, ...Project.params })}`,
      update: `/${Project.base}/${id}/${ToolBox.parameterize({ ...params, ...Project.params })}`,
      show: `/${Project.base}/${id}${ToolBox.parameterize({ ...params, ...Project.params })}`,
      documents: `/${Project.base}/${id}/${ToolBox.parameterize({ ...params, ...Project.params })}`
    }
  },
  base: RecordType.plurify().project,
  params: {}
}

export const Document = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Document.base}${ToolBox.parameterize({ ...params, ...Document.params })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Document.base}/${ToolBox.parameterize({ ...params, ...Document.params })}`
    }
  },
  base: RecordType.plurify().document,
  params: {}
}

export const Invoice = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Invoice.base}${ToolBox.parameterize({ ...params, ...Invoice.params })}`
  }),
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Invoice.base}/${id}/${ToolBox.parameterize({ ...params, ...Invoice.params })}`
    }
  },
  base: RecordType.plurify().invoice,
  params: {}
}

export const Customer = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      update: `/${Customer.base}/${id}${ToolBox.parameterize({ ...params, ...Customer.params })}`,
      show: `/${Customer.base}/${id}/${ToolBox.parameterize({ ...params, ...Customer.params })}`
    }
  },
  base: RecordType.plurify().customer,
  params: {}
}

export const CategorySegment = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${CategorySegment.base}/${id}${ToolBox.parameterize({
        ...CategorySegment.params,
        ...params
      })}`
    }
  },
  base: RecordType.plurify().category_segment,
  params: {
    includes: [RecordType.plurify().category_attribute, RecordType.plurify().attachment]
  }
}

export const Category = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Category.base}${ToolBox.parameterize({ ...params, ...Category.params })}`
  }),
  base: RecordType.plurify().category,
  params: {
    includes: [RecordType.plurify().category_segment]
  }
}

export const CategorySearch = {
  ...Category,
  base: RecordType.plurify().category_search,
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Category.base}/search${ToolBox.parameterize({
      ...params,
      ...Category.params
    })}`
  })
}

export const Session = {
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Session.base}${ToolBox.parameterize({ ...params, ...Session.params })}`
    }
  },
  base: RecordType.plurify().session,
  params: {}
}

export const PasswordRequest = {
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${PasswordRequest.base}${ToolBox.parameterize({
        ...params,
        ...PasswordRequest.params
      })}`
    }
  },
  base: RecordType.plurify().password_request,
  params: {}
}

export const Password = {
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      update: `/${Password.base}/me${ToolBox.parameterize({ ...params, ...Password.params })}`
    }
  },
  base: RecordType.plurify().password,
  params: {}
}

export const DamageInfo = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${DamageInfo.base}/${id}${ToolBox.parameterize({ ...params, ...DamageInfo.params })}`,
      update: `/${DamageInfo.base}/${id}/${ToolBox.parameterize({
        ...params,
        ...DamageInfo.params
      })}`
    }
  },
  base: RecordType.plurify().damage_info,
  params: {}
}

export const Package = {
  collection: (params: CollectionArgs = {}) => ({
    index: `/${Package.base}${ToolBox.parameterize({ ...Package.params, ...params })}`
  }),
  base: RecordType.plurify().package,
  params: {
    includes: [RecordType.plurify().attachment]
  }
}

export const Attachment = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Attachment.base}${ToolBox.parameterize({ ...params, ...Attachment.params })}`,
      show: `/${Attachment.base}/${id}${ToolBox.parameterize({ ...params, ...Attachment.params })}`
    }
  },
  base: RecordType.plurify().attachment,
  params: {}
}

export const SubItem = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${SubItem.base}${ToolBox.parameterize({ ...params, ...SubItem.params })}`,
      update: `/${SubItem.base}/${id}${ToolBox.parameterize({ ...params, ...SubItem.params })}`,
      show: `/${SubItem.base}/${id}${ToolBox.parameterize({ ...params, ...SubItem.params })}`
    }
  },
  base: RecordType.plurify().sub_item,
  params: {}
}

export const InvoicePayment = {
  member: (args: MemberArgs = {}) => {
    const { params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${InvoicePayment.base}${ToolBox.parameterize({
        ...params,
        ...InvoicePayment.params
      })}`
    }
  },
  base: RecordType.plurify().invoice_payment,
  params: {}
}

export const Voucher = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Voucher.base}${ToolBox.parameterize({ ...params, ...Voucher.params })}`,
      update: `/${Voucher.base}/${id}${ToolBox.parameterize({ ...params, ...Voucher.params })}`
    }
  },
  base: RecordType.plurify().voucher,
  params: {}
}

export const Invitation = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Invitation.base}${ToolBox.parameterize({ ...params, ...Invitation.params })}`,
      update: `/${Invitation.base}/${id}${ToolBox.parameterize({
        ...params,
        ...Invitation.params
      })}`
    }
  },
  base: RecordType.plurify().invitation,
  params: {}
}

export const PaymentIntent = {
  member: (args: MemberArgs & { quote_id?: number } = {}) => {
    const { quote_id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Quote.base}/${quote_id}/${PaymentIntent.base}${ToolBox.parameterize({
        ...params,
        ...PaymentIntent.params
      })}`
    }
  },
  base: RecordType.plurify().payment_intent,
  params: {}
}

export const PaypalPayment = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${PaypalPayment.base}${ToolBox.parameterize({
        ...params,
        ...PaypalPayment.params
      })}`,
      update: `/${PaypalPayment.base}/${id}${ToolBox.parameterize({
        ...params,
        ...PaypalPayment.params
      })}`
    }
  },
  base: RecordType.plurify().paypal_payment,
  params: {}
}

export const Payment = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      show: `/${Payment.base}/${id}${ToolBox.parameterize({ ...params, ...Payment.params })}`
    }
  },
  base: RecordType.plurify().payment,
  params: {}
}

export const Solvency = {
  member: (args: MemberArgs & { rental_id?: number } = {}) => {
    const { rental_id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Rental.base}/${rental_id}/${Solvency.base}${ToolBox.parameterize({
        ...params,
        ...PaymentIntent.params
      })}`
    }
  },
  base: RecordType.plurify().solvency,
  params: {}
}

export const DirectBooking = {
  member: (args: MemberArgs = {}) => {
    const { id, params } = { ...{ ...args, params: {} }, ...args }
    return {
      create: `/${Rental.base}/${id}/${DirectBooking.base}${ToolBox.parameterize({
        ...params,
        ...PaymentIntent.params
      })}`
    }
  },
  base: RecordType.direct_booking,
  params: {}
}
