const USER = {
  UPDATE_CONTACT_ORGANIZATION: 'USER_UPDATE_CONTACT_ORGANIZATION',
  UPDATE_LOCALE: 'USER_UPDATE_LOCALE'
}

const AUTH = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  SESSION_FAILURE: 'SESSION_FAILURE'
}

const DOCUMENT = {
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  DELETE_DOCUMENT: 'DELETE_DOCUMENT'
}

export { USER, AUTH, DOCUMENT }
