import { Col, Divider, Grid, Row, Space, Typography } from 'antd'
import KItem from 'app/components/Modules/General/KItem'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { RecordType } from 'app/constants/GlobalTypes'
import { useFeedbacker } from 'app/lib/contexts/Feedbacker'
import { ToolBox } from 'app/lib/hooks/toolBox'
import useChangeEffect from 'app/lib/hooks/useChangeEffect'
import {
  useCurrentContact,
  useCurrentContactOrganization,
  useCurrentCustomer
} from 'app/lib/hooks/useCurrent'
import { useHelpUrl } from 'app/lib/hooks/useHelpUrl'
import CapacitorService, { PlatformEnum } from 'app/lib/services/CapacitorService'
import { Routes } from 'app/routes/Routes'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import KAvatar from '../../UI-Elements/DataDisplay/KAvatar'
import Logout from '../Account/Logout'
import LanguageToggle from '../Contact/LanguageToggle'
import ProfileForm from './Form'
import ProfileLink from './Link'
import Version from './Version'

type Props = {
  close?: () => void
}

const { isWeb, platform } = CapacitorService

const urls = {
  data_protection: 'https://www.klarx.de/impressum',
  imprint: {
    ios: 'https://www.klarx.de/datenschutz/ios',
    android: 'https://www.klarx.de/datenschutz/android',
    web: ''
  },
  helpCenterUrl: useHelpUrl()
}

const portalId = 'profile-settings-portal-id'

const ProfileContent: React.FC<Props> = ({ close }) => {
  const customer = useCurrentCustomer()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const contact = useCurrentContact()
  const contactOrganization = useCurrentContactOrganization()
  const { getInitials } = ToolBox.useString()
  const { formatMessage } = useIntl()
  const {
    drawerfy: { open: openDrawer, close: closeDrawer }
  } = useFeedbacker()

  const { md } = Grid.useBreakpoint()

  useChangeEffect(() => {
    close?.()
  }, [pathname])

  const translate = (id: string) => formatMessage({ id: `component.feedback.drawer.profile.${id}` })

  const openRoute = (route: string) => navigate(route)

  const onSettingsOpen = () => {
    openDrawer({
      content: (
        <ProfileForm
          portalId={portalId}
          onSuccess={() => closeDrawer()}
          cancelable
          onCancel={() => closeDrawer()}
        />
      ),
      recordType: RecordType.profile,
      action: 'update',
      width: 500,
      destroyOnClose: true,
      footer: <div id={portalId} />
    })
  }

  return (
    <Choose>
      <When condition={!md}>
        <Row>
          <KCol span={24} card>
            <Row gutter={[0, 24]}>
              <Col span={24}>
                <KItem
                  title={
                    <Typography.Title className="mb-0 text-black text-base">
                      {contact?.name}
                    </Typography.Title>
                  }
                  description={
                    <Typography.Text className="text-muted">
                      {customer?.organization}
                    </Typography.Text>
                  }
                  avatar={<KAvatar type="primary">{getInitials(contact?.name)}</KAvatar>}
                />
              </Col>
              <If condition={!isWeb()}>
                <Col span={24}>
                  <Divider className="my-0" />
                </Col>
                <Col span={24}>
                  <Space direction="vertical">
                    <ProfileLink title={translate('imprint')} url={urls.data_protection} />
                    <If condition={urls.imprint[platform as PlatformEnum]}>
                      <ProfileLink
                        title={translate('data_protection')}
                        url={urls.imprint[platform as PlatformEnum]}
                      />
                    </If>
                    <ProfileLink title={translate('help_center')} url={urls.helpCenterUrl} />
                  </Space>
                </Col>
              </If>
              <Col span={24}>
                <Divider className="my-0" />
              </Col>
              <Col span={24}>
                <Version />
                <Logout block={!md} />
              </Col>
            </Row>
          </KCol>
        </Row>
      </When>
      <Otherwise>
        <If condition={contact}>
          <Row>
            <Col span={24} className="h4 mb-0 text-capitalize">
              {contact.name}
            </Col>
            <Col span={24} className="text-muted text-truncate text-lowercase">
              {contact.email}
            </Col>
            <Col span={24} className="text-muted pb-2">
              {formatMessage({ id: contactOrganization.role })}
            </Col>
          </Row>
        </If>
        <Divider style={{ margin: '8px 0' }} />
        <If condition={customer}>
          <Row className="position-relative pointer">
            <KCol
              span={24}
              className="background-hover"
              onClick={() => openRoute(Routes.Account.Base)}>
              <Row>
                <Col span={24} className="h5 mb-0 pt-2 text-capitalize">
                  {customer.organization}
                </Col>
                <Col span={24} className="text-muted pb-2">
                  {customer.id}
                </Col>
              </Row>
            </KCol>
          </Row>
        </If>
        <Divider style={{ margin: '8px 0' }} />
        <Row gutter={[0, 12]}>
          <Col
            span={24}
            className="on-hover-underline pointer"
            onClick={() => openRoute(Routes.FAQ.Base)}>
            {formatMessage({ id: 'sidebar.faq' })}
          </Col>
          <Col span={24} className="on-hover-underline pointer" onClick={onSettingsOpen}>
            {formatMessage({ id: 'userProfile.settings' })}
          </Col>
          <Col xs={24} md={12}>
            <LanguageToggle />
          </Col>
          <Col span={24}>
            <Logout />
          </Col>
        </Row>
      </Otherwise>
    </Choose>
  )
}

export default ProfileContent
