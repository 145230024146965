import Bugsnag from '@bugsnag/js'
import { App as CapApp } from '@capacitor/app'
import { APP_MIN_VERSION } from 'app/constants/Config'
import { useEffect, useState } from 'react'
import semverLt from 'semver/functions/lt'
import semverValid from 'semver/functions/valid'

import CapacitorService from '../services/CapacitorService'

type AppVersionHook = {
  version: string
}

export const useAppVersion = (): AppVersionHook => {
  const [version, setVersion] = useState(null)

  useEffect(() => {
    if (CapacitorService.appAvailable()) {
      // App.getInfo is not available for web
      CapApp.getInfo().then((data) => {
        if (data?.version) setVersion(data.version)
      })
    } else if (CapacitorService.isNative()) {
      setVersion('0.0.1')
      Bugsnag.notify(
        `CapApp was not defined. Set version to 0.0.1 and show update screen`,
        (event) => {
          event.severity = 'info'
        }
      )
    }
  }, [])

  return { version }
}

type RequireUpdateHook = {
  version: string
  requireUpdate: boolean
}

export const useRequireUpdate = (): RequireUpdateHook => {
  const { version } = useAppVersion()
  const [requireUpdate, setRequireUpdate] = useState(false)
  const minVersion = APP_MIN_VERSION

  const logMessage = () => {
    Bugsnag.notify(`User with version (${version}) identified. Show update screen`, (event) => {
      event.severity = 'info'
    })
  }

  useEffect(() => {
    if (!CapacitorService.isNative()) {
      // Do not require a update for web version
      setRequireUpdate(false)
    } else if (version && semverValid(version) && semverValid(minVersion)) {
      // semverGte throw an error if version string is not valid
      // 1.0.0 --> valid
      // 1.2 --> not valid
      setRequireUpdate(semverLt(version, minVersion))
    } else if (version && !semverValid(version)) {
      setRequireUpdate(true) // show update screen for version 1.1
      logMessage()
    } else {
      setRequireUpdate(false)
    }
  }, [version])

  return { version, requireUpdate }
}
