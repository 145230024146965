import { message as Toast, Modal, notification } from 'antd'
import KDrawer from 'app/components/Modules/Feedback/KDrawer'
import KModal from 'app/components/Modules/Feedback/KModal'
import Alert from 'app/components/UI-Elements/Feedback/Alert'
import { useContext, useState } from 'react'
import * as React from 'react'

import { ToolBox } from '../hooks/toolBox'
import {
  AlertConfig,
  BoxConfig,
  ConfirmConfig,
  DrawerConfig,
  FeedbackContextType,
  ModalConfig,
  NotifyConfig,
  ToastConfig
} from '../typing'

const FeedbackContext = React.createContext<FeedbackContextType>(null)

export const kDrawerTestId = 'kdrawer-test'

export function useFeedbacker(): FeedbackContextType {
  const { toastify, alertify, boxefy, notify, drawerfy, modalify, confirmfy } =
    useContext(FeedbackContext)
  return { toastify, alertify, boxefy, notify, drawerfy, modalify, confirmfy }
}

type Props = {
  children: React.ReactNode
}

const FeedbackProvider: React.FC<Props> = ({ children }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [drawerConfig, setDrawerConfig] = useState<Array<DrawerConfig>>([])
  const [modalConfig, setModalConfig] = useState<ModalConfig>(null)
  const { smartTranslation } = ToolBox.useString()

  const closeDrawer = (closeAll = false) => {
    if (closeAll) {
      setDrawerConfig([])
    } else {
      setDrawerConfig(drawerConfig.slice(0, -1))
    }
  }

  const drawerfy = ({ singleDrawer, ...config }: DrawerConfig) => {
    // Not obvious: We have to use current drawerConfig as function gets copied
    // and we can not use closeDrawer here as it would close more than one drawer
    const onClose = () => setDrawerConfig(singleDrawer ? [] : drawerConfig)

    setDrawerConfig([
      ...(singleDrawer ? [] : drawerConfig),
      {
        open: true,
        ...config,
        onClose: onClose,
        destroyOnClose: true
      }
    ])
  }

  const modalify = (config: ModalConfig) => {
    setOpenModal(true)
    setModalConfig({ onClose: () => setOpenModal(false), ...config, ...{ destroyOnClose: true } })
  }

  const toastify = (config: ToastConfig) => {
    Toast.config({
      duration: 5,
      maxCount: config.maxCount
    })
    Toast[config.type](config.content)
  }

  const notify = (config: NotifyConfig) => {
    // deprecated
    notification[config.type]({
      placement: config.placement || 'bottomRight',
      duration: config.duration || 5,
      description: config.content,
      message: config.message,
      className: config.className
    })
  }

  const alertify = (config: AlertConfig) => {
    Modal[config.type]({
      title: config.title,
      content: config.content,
      onOk: config.onOk && config.onOk()
    })
  }

  const boxefy = (config: BoxConfig) => {
    return <Alert showIcon message={config.content} closable={config.closable} type={config.type} />
  }

  const onConfirm = (config: ConfirmConfig) => {
    const { recordType, values, action, ...others } = config

    modalify({
      destroyOnClose: true,
      title: smartTranslation({
        recordType,
        id: `component.feedback.modal.title.${recordType}.${action}`,
        values
      }),
      modalType: 'confirm',
      recordType,
      ...others
    })
  }

  const contextValue: FeedbackContextType = {
    toastify,
    alertify,
    boxefy,
    notify,
    drawerfy: {
      close: closeDrawer,
      open: drawerfy,
      isOpen: drawerConfig[0]?.open || false,
      closeAll: () => closeDrawer(true)
    },
    modalify: {
      close: () => setOpenModal(false),
      open: modalify
    },
    confirmfy: {
      close: () => setOpenModal(false),
      open: onConfirm
    }
  }

  return (
    <FeedbackContext.Provider value={contextValue}>
      {children}
      {[...drawerConfig, { open: false, content: null }].map((config, index) => {
        return (
          <KDrawer
            {...config}
            open={config.open}
            key={`nested-drawer-${index}`}
            data-testid={kDrawerTestId}
            drawerKey={String(index)}
            mask={index === 0}>
            {config.content}
          </KDrawer>
        )
      })}
      <KModal footer={null} {...modalConfig} open={openModal}>
        {modalConfig?.content}
      </KModal>
    </FeedbackContext.Provider>
  )
}

export default FeedbackProvider
