import { RequestPayload } from 'app/constants/GlobalTypes'
import EnvironmentService from 'app/lib/services/EnvironmentService'
import UserService from 'app/lib/services/UserService'
import axios, { AxiosError, AxiosResponse } from 'axios'

const fallbackError: AxiosError = {
  code: '404',
  name: '',
  message:
    'Unsere Server sind vorübergehend nicht verfügbar. Bitte versuchen Sie es in den nächsten Minuten erneut.',
  isAxiosError: true,
  config: null,
  toJSON: () => ({}),
  response: {
    status: 404,
    headers: null,
    config: null,
    data: 'Unsere Server sind vorübergehend nicht verfügbar. Bitte versuchen Sie es in den nächsten Minuten erneut.',
    statusText:
      'Unsere Server sind vorübergehend nicht verfügbar. Bitte versuchen Sie es in den nächsten Minuten erneut.'
  }
}

axios.defaults.withCredentials = true
axios.defaults.xsrfCookieName = null // We set xsrf with interceptor
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}
// Compared to connect withXSRFToken is not need as we set xsrf with interceptor

export const API_VERSION = '/api/v2/customer'

class Api {
  static host = `${EnvironmentService.getHostUrlValue()}${API_VERSION}`

  static headers = () => ({ headers: { 'Customer-Id': `${UserService.customerId}` } })

  static get<T>(route: string): Promise<AxiosResponse<T>> {
    const url = `${Api.host}${route}`
    return axios
      .get(url, Api.headers())
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw Api.failureFallBack(error.response)
      })
  }

  static put<T>(
    route: string,
    payload?: RequestPayload<T>
  ): Promise<AxiosResponse<IMemberResponse<T>>> {
    const url = `${Api.host}${route}`
    return axios
      .put(url, payload, Api.headers())
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw Api.failureFallBack(error.response)
      })
  }

  static post<T>(
    route: string,
    payload?: RequestPayload<T>
  ): Promise<AxiosResponse<IMemberResponse<T>>> {
    const url = `${Api.host}${route}`
    return axios
      .post(url, payload, Api.headers())
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw Api.failureFallBack(error.response)
      })
  }

  static postWithMultipart<T>(
    route: string,
    payload?: RequestPayload<T>
  ): Promise<AxiosResponse<IMemberResponse<T>>> {
    const url = `${Api.host}${route}`
    return axios
      .post(url, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw Api.failureFallBack(error.response)
      })
  }

  static delete<T>(route: string): Promise<AxiosResponse<IMemberResponse<T>>> {
    const url = `${Api.host}${route}`
    return axios
      .delete(url, Api.headers())
      .then((response) => {
        return response
      })
      .catch((error: AxiosError) => {
        throw Api.failureFallBack(error.response)
      })
  }

  static failureFallBack(res: AxiosResponse) {
    return res ? res : fallbackError.response
  }
}

export default Api
