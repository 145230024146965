import { Input } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KPasswordInputProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'

const KPasswordInput: React.FC<KPasswordInputProps> = ({
  withConfirm,
  required,
  recordTypeOverride,
  ...others
}) => {
  const { smartTranslation } = ToolBox.useString()
  const { recordType } = useKFormContext()
  return (
    <>
      <KFormItem
        name="password"
        required={required}
        recordTypeOverride={recordTypeOverride}
        {...others}>
        <Input.Password />
      </KFormItem>
      <If condition={withConfirm}>
        <KFormItem
          dependencies={['password']}
          name="password_confirmation"
          required={required}
          recordTypeOverride={recordTypeOverride}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(
                    smartTranslation({
                      recordType,
                      id: `form.${recordType}.validation.confirm_password.not_match`
                    })
                  )
                }
              }
            })
          ]}>
          <Input.Password />
        </KFormItem>
      </If>
    </>
  )
}

export default KPasswordInput
