import { Col, Row, Typography } from 'antd'
import GoogleMap, { MapGeoPoint } from 'app/components/Modules/Map/Map'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { INames, useAddress } from 'app/lib/hooks/useAddress'
import React, { ComponentProps, useEffect, useState } from 'react'

const maxDigit = 5

interface Props extends Partial<ComponentProps<typeof GoogleMap>> {
  draggable?: boolean
  names?: INames
  fullAddressName?: string
}

const KMap: React.FC<Props> = ({ draggable, names, fullAddressName, options = {}, ...others }) => {
  const { form } = useKFormContext()
  const { tComponent } = ToolBox.useString()
  const { geocode } = useAddress()
  const [collection, setCollection] = useState<MapGeoPoint[]>([])

  const onDragEnd = async (e: google.maps.MapMouseEvent) => {
    const longitude = e.latLng.lng().toFixed(maxDigit)
    const latitude = e.latLng.lat().toFixed(maxDigit)

    const address = await geocode(e.latLng, names)

    form.setFieldsValue({
      [`${fullAddressName || 'address'}`]: address.fullAddress,
      ...address,
      longitude,
      latitude
    })

    updateGeoPoint(e.latLng.lng(), e.latLng.lat())
  }

  useEffect(() => {
    const longitude = parseFloat(form.getFieldValue('longitude'))
    const latitude = parseFloat(form.getFieldValue('latitude'))
    updateGeoPoint(longitude, latitude)
  }, [])

  // validate and updates collection with geo points safely
  const updateGeoPoint = (longitude: number, latitude: number) => {
    if (longitude && latitude) {
      setCollection([
        {
          id: form.getFieldValue('id'),
          longitude: longitude,
          latitude: latitude
        }
      ])
    }
  }

  return (
    <Row>
      <Col span={24}>
        <GoogleMap
          collection={collection}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: Boolean(draggable),
            fullscreenControl: false,
            ...options
          }}
          onDragEnd={draggable ? onDragEnd : undefined}
          height="200px"
          {...others}
        />
      </Col>
      <If condition={draggable}>
        <Col span={24}>
          <Typography.Text>
            {tComponent({
              componentType: 'modules',
              componentName: 'kmap',
              id: 'move_pin'
            })}
          </Typography.Text>
        </Col>
      </If>
    </Row>
  )
}

export default KMap
