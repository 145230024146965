import usePortal from 'app/lib/hooks/usePortal'
import * as React from 'react'
import { createPortal } from 'react-dom'

type Props = {
  id: string
  children: React.ReactNode
}
/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 *
 * </div id="modal"></div>
 */
const Portal: React.FC<Props> = ({ id, children }) => {
  const target = usePortal(id)
  return createPortal(children, target) as React.ReactElement
}

export default Portal
