import { useEffect, useRef } from 'react'

// Invoke given function only on dependencies change NOT ON MOUNT of the component
const useChangeEffect: typeof useEffect = (func, deps) => {
  const didMount = useRef(false)

  useEffect(() => {
    if (didMount.current) {
      func()
    } else {
      didMount.current = true
    }
  }, deps)
}

export default useChangeEffect
