import { gql } from '@apollo/client/core'

const SET_LINE_ITEMS_VACANT = gql`
  mutation setLineItemsVacant($input: SetLineItemsVacantInput!) {
    setLineItemsVacant(input: $input) {
      errors {
        message
      }
    }
  }
`

export { SET_LINE_ITEMS_VACANT }
