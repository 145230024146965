import { useExternalLink } from 'app/lib/hooks/useExternalLink'
import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'

interface Props extends React.ComponentProps<typeof Link> {
  external?: boolean
  blank?: boolean
  to: string
}

const KLink: React.FC<Props> = ({ to, external, blank = true, children, ...others }) => {
  const { pathname } = useLocation()
  const { open } = useExternalLink(to)
  const newTabProps = blank ? { target: '_blank', rel: 'noreferrer' } : {}

  return (
    <Choose>
      <When condition={external}>
        <a href={to} onClick={open} {...newTabProps} {...others}>
          {children}
        </a>
      </When>
      <When condition={pathname !== to}>
        <Link to={to} {...others}>
          {children}
        </Link>
      </When>
      <Otherwise>{children}</Otherwise>
    </Choose>
  )
}

export default KLink
