import { gql } from '@apollo/client/core'

import { CATEGORY_ATTRIBUTE_ATTRIBUTES } from './fragments/CategoryAttribute'
import { CATEGORY_SEGMENT_DETAILED_ATTRIBUTES } from './fragments/CategorySegment'
import { LINE_ITEM_PRODUCT_BASE_ATTRIBUTES } from './fragments/LineItem'
import { UNIT_ATTRIBUTES } from './fragments/Unit'

const FETCH_LINE_ITEM_QUERY = gql`
  query fetchLineItem(
    $id: ID!
    $includeCategorySegment: Boolean = true
    $includeCategoryAttributes: Boolean = false
    $includeSubItems: Boolean = false
  ) {
    lineItem(id: $id) {
      id
      name
      annotation
      imageUrl
      startDate
      startTime
      returnDate
      returnTime
      rentalId
      earliestAvailableDate
      earliestAvailableDateTime
      attributeValues @include(if: $includeCategoryAttributes) {
        id
        displayValue
        attributeDetailId
      }
      subItems @include(if: $includeSubItems) {
        id
        name
        imageUrl
        startDate
        returnDate
        earliestAvailableDate
        earliestAvailableDateTime
        lineItemType
        freeAttachment
      }
      categorySegment @include(if: $includeCategorySegment) {
        id
        name
        imageUrl
        minimumRentalTime
        minimumRentalDuration
        ...categorySegmentDetailedAttributes
        unit {
          ...unitAttributes
        }
        category @include(if: $includeCategoryAttributes) {
          categoryAttributes {
            ...categoryAttributeAttributes
          }
        }
      }
    }
  }
  ${CATEGORY_SEGMENT_DETAILED_ATTRIBUTES}
  ${CATEGORY_ATTRIBUTE_ATTRIBUTES}
  ${UNIT_ATTRIBUTES}
`

const FETCH_ALL_LINE_ITEMS = gql`
  query fetchAllLineItems(
    $lineItemType: [LineItemTypeEnum!]
    $projectId: ID
    $excludeSubItems: Boolean = true
    $limit: Int
    $requested: Boolean = true
    $excludeCanceled: Boolean = true
    $sort: [SortOptions!] = [{ sortField: "created_at", sortDirection: "desc" }]
  ) {
    lineItems(
      lineItemType: $lineItemType
      projectId: $projectId
      excludeSubItems: $excludeSubItems
      sort: $sort
      limit: $limit
      requested: $requested
      excludeCanceled: $excludeCanceled
    ) {
      id
      name
      startDate
      returnDate
      rentalId
      status
      rentalCode
      project {
        name
      }
      ... on ProductLineItemInterface {
        imageUrl
        categorySegment {
          name
        }
        product {
          name
        }
      }
    }
  }
`

const UPDATE_LINE_ITEM = gql`
  mutation updateCustomerMachineDetails($input: UpdateCustomerMachineDetailsInput!) {
    updateCustomerMachineDetails(input: $input) {
      lineItem {
        ...lineItemProductBaseAttributes
      }
      errors {
        message
      }
    }
  }
  ${LINE_ITEM_PRODUCT_BASE_ATTRIBUTES}
`

const CREATE_LINE_ITEM = gql`
  mutation createCustomerLineItem($input: CreateCustomerLineItemInput!) {
    createCustomerLineItem(input: $input) {
      lineItem {
        ...lineItemProductBaseAttributes
      }
      errors {
        message
      }
    }
  }
  ${LINE_ITEM_PRODUCT_BASE_ATTRIBUTES}
`

const DELETE_LINE_ITEM = gql`
  mutation deleteLineItem($input: DeleteLineItemInput!) {
    deleteLineItem(input: $input) {
      lineItem {
        id
        name
      }
      errors {
        path
        message
      }
    }
  }
`

export {
  FETCH_LINE_ITEM_QUERY,
  UPDATE_LINE_ITEM,
  CREATE_LINE_ITEM,
  DELETE_LINE_ITEM,
  FETCH_ALL_LINE_ITEMS
}
