import { ApolloProvider } from '@apollo/client'
import { initClient } from 'app/lib/apollo/client'
import React, { PropsWithChildren, useMemo } from 'react'

import { useCurrentContactOrganization } from '../hooks/useCurrent'

const KApolloProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { parentable_id } = useCurrentContactOrganization() || {}

  const client = useMemo(
    () =>
      initClient({
        'Customer-Id': parentable_id
      }),
    [parentable_id]
  )

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default KApolloProvider
