import { ThemeConfig } from 'antd'

// ****************************************************************
//                  ANTD ALL THE VARIABLES:
// ****************************************************************

// *** Applied variables:
// @primary-color: #2a5b66;
// @success-color: #28a745;
// @warning-color: #ffc107;
// @error-color: #fd6565;
// @text-color: #21393f;
// @heading-color: #36707a;
// @menu-item-active-bg: #fd65651a;
// @menu-highlight-color: #fd6565;
// @tabs-hover-color: #36707a;
// @tabs-card-active-color: #fd6565;

// @font-size-base: 14px;
// @border-color-base: #e8e8e8;
// @box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15);
// @pagination-font-family: 'Fira Sans Condensed';

// *** Not applied yet:
// @secondary-color: #fd6565; TODO
// @link-color: #36707a; -
// @menu-item-active-color: #fd6565; -
// @text-selection-bg: #36707a; -
// @select-item-selected-bg: #fd65651a;
// @collapse-content-bg: #fff;

const colorPalette = {
  primary: '#2a5b66',
  secondary: '#fd6565',
  secondaryLight: '#fff2f0'
}

export const themeConfig: ThemeConfig = {
  cssVar: false,
  hashed: false,
  token: {
    borderRadius: 1,
    colorPrimary: colorPalette.primary,
    colorSuccess: '#28a745',
    colorWarning: '#ffc107',
    colorError: colorPalette.secondary,
    colorText: '#21393f',
    colorTextSecondary: colorPalette.primary,
    colorTextTertiary: colorPalette.primary,
    colorTextHeading: '#36707a',
    colorBorder: '#e8e8e8',
    fontSize: 14,
    colorLink: '#36707a',
    fontFamily: 'Fira Sans Condensed',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
  },
  components: {
    Menu: {
      colorPrimary: colorPalette.secondary,
      itemActiveBg: '#fd65651a',
      colorHighlight: colorPalette.secondary,
      itemHoverColor: colorPalette.secondary,
      itemHoverBg: 'unset',
      itemSelectedBg: '#fd65651a',
      itemMarginInline: 0 // fix menu margin for prod build
    },
    Tabs: {
      itemHoverColor: '#36707a',
      itemActiveColor: colorPalette.secondary,
      fontSize: 16
    },
    Table: {
      fontWeightStrong: 400 // reset bold header text
    },
    Pagination: {
      fontFamily: 'Fira Sans Condensed',
      colorBgTextHover: 'unset'
    },
    Input: {
      fontSize: 14,
      fontSizeLG: 14,
      lineHeightLG: 1.1
    },
    Select: {
      fontSizeLG: 14,
      lineHeightLG: 1.1
    },
    Radio: {
      fontSizeLG: 14
    },
    Button: {
      fontSizeLG: 14,
      onlyIconSize: 16
    },
    Layout: {
      triggerBg: colorPalette.secondaryLight,
      triggerColor: colorPalette.primary
    },
    Steps: {
      iconFontSize: 16,
      colorTextBase: colorPalette.primary
    },
    List: {
      itemPaddingLG: '12px 0px',
      colorTextDescription: 'unset'
    }
  }
}
