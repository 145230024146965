import BrowserService from 'app/lib/services/BrowserService'
import EnvironmentService, { Environment } from 'app/lib/services/EnvironmentService'

class RouteService {
  static getKlarxConnectUrl = () => {
    const baseUrl = EnvironmentService.getEnvValue('SUPPLIER_DASHBOARD_URL')
    const domain = EnvironmentService.is(Environment.Dev)
      ? ''
      : `.${BrowserService.getDomainName()}`
    return `${baseUrl}${domain}`
  }

  static getKlarxHomePageUrl = () => {
    return EnvironmentService.getHostUrlValue()
  }
}

export default RouteService
