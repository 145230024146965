/* eslint-disable prettier/prettier */
import EnvironmentService from 'app/lib/services/EnvironmentService'

export default function initMouseflow() {
  if (EnvironmentService.isProduction()) {
    window._mfq = window._mfq || [];
    (function () {
      var mf = document.createElement("script");
      mf.type = "text/javascript"; mf.async = true;
      mf.src = "//cdn.mouseflow.com/projects/b7f8cba9-5eb8-4d58-beb1-ecf57c0cbc29.js";
      document.getElementsByTagName("head")[0].appendChild(mf);
    })();
  }
}
