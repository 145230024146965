export enum ReducerKeys {
  index = 'index',
  show = 'show',
  create = 'create',
  update = 'update',
  destroy = 'destroy'
}

export type ReducerBase<T> = {
  [key in keyof typeof ReducerKeys]: ReducerRow<T>
}

export interface Reducer<T> extends ReducerBase<T> {
  hash: string
}

export type ReducerRow<T> = {
  [key: string]: ReducerRecord<T>
}

export interface ReducerRecord<T> {
  [key: string]: T
}

export type ReducerAction<T> = {
  type: string
  data: T[]
  recordType: string
  actionType: ReducerKeys
}
