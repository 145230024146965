import { classNames } from 'app/lib'
import React, { CSSProperties } from 'react'

interface Props extends React.HTMLAttributes<HTMLImageElement> {
  type: string
  extension?: 'png' | 'jpeg' | 'jpg' | 'svg'
  iconStyles?: CSSProperties
}

const Icon: React.FC<Props> = ({
  type = '',
  extension = 'svg',
  iconStyles,
  className,
  ...others
}) => {
  return (
    <img
      src={`/assets/images/icons/${type}_icon.${extension}`}
      alt=""
      style={iconStyles}
      className={classNames('anticon', className)}
      {...others}
    />
  )
}

export default Icon
