/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  PossibleTypesMap
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { asyncMap } from '@apollo/client/utilities'
import Bugsnag from '@bugsnag/js'
import EnvironmentService from 'app/lib/services/EnvironmentService'

import { XSRF_REQUEST_HEADER_NAME, XSRF_RESPONSE_HEADER_NAME } from '../axios'
import StorageService from '../services/StorageService'

const baseUrl = EnvironmentService.getHostUrlValue()

const { get, set } = StorageService

const errorLink = onError(({ graphQLErrors }) => {
  graphQLErrors?.map((error) => {
    const errorMsg = `GraphQL Error: ${error.message}`
    console.log(errorMsg)
    Bugsnag.notify(new Error(errorMsg), (event) => {
      event.severity = 'info'
    })
    return error
  })
})

const authLink = (headerOptions: any) =>
  setContext(async (_, { headers }) => {
    const { value } = (await get({ key: XSRF_RESPONSE_HEADER_NAME })) || null
    return {
      headers: {
        ...headers,
        ...headerOptions,
        [XSRF_REQUEST_HEADER_NAME]: value
      }
    }
  })

const setTokenLink = new ApolloLink((operation, forward) => {
  return asyncMap(forward(operation), async (data) => {
    const context = operation.getContext()
    const {
      response: { headers }
    } = context

    const token = headers.get(XSRF_RESPONSE_HEADER_NAME)
    if (token) {
      await set({ key: XSRF_RESPONSE_HEADER_NAME, value: token })
    }

    return data
  })
})

const httpLink = createHttpLink({
  uri: `${baseUrl}/graphql`,
  credentials: 'include'
})

const createLink = (headers: any) =>
  ApolloLink.from([errorLink, authLink(headers), setTokenLink, httpLink])

export const possibleTypes: PossibleTypesMap = {
  ProductLineItemInterface: ['LineItem', 'SubItem'],
  LineItemInterface: ['LineItem', 'SubItem', 'DeliveryItem']
}

const initClient = (headers: any) =>
  new ApolloClient({
    cache: new InMemoryCache({ possibleTypes }),
    link: createLink(headers),
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      },
      watchQuery: {
        errorPolicy: 'all',
        partialRefetch: true
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  })

export { initClient }
