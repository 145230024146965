import { Result, Row } from 'antd'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import * as React from 'react'
import { useIntl } from 'react-intl'

const NotAuthorized: React.FC = () => {
  const { formatMessage } = useIntl()
  return (
    <Row justify="center">
      <KCol card span={24}>
        <Result
          status="403"
          title="403"
          subTitle={formatMessage({ id: 'component.feedback.result.not_authorized.sub_title' })}
        />
      </KCol>
    </Row>
  )
}

export default NotAuthorized
