import EnvironmentService from 'app/lib/services/EnvironmentService'

import ScriptService from '../services/ScriptService'

export default function initGoogleAdwords() {
  if (EnvironmentService.isProduction()) {
    window.dataLayer = window.dataLayer || []
    ScriptService.gtag('js', new Date())
    ScriptService.gtag('config', EnvironmentService.getEnvValue('ADWORDS_ID'), {
      linker: {
        domains: `${EnvironmentService.getEnvValue('HOST_URL')}.de`
      }
    })
  }
}
