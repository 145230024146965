/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnProps } from 'antd/lib/table'
import { TableProps as AntTableProps } from 'antd/lib/table'
import { RecordTypeEnum } from 'app/queries'
import { CollectionArgs } from 'app/redux/actions/typing'
import * as React from 'react'
import { ThunkDispatch } from 'redux-thunk'

import { LocaleEnum } from './Enums'

export type PaginatedResponse<T> = {
  data: T[]
  pagination?: IPaginationMeta
}

export type LanguageSelectOptionType = {
  id: number
  language: LocaleEnum
  label: string
  icon: string
}

export type Dispatchable = ThunkDispatch<AppState, any, ActionTypes>

export interface TableProps<T> extends AntTableProps<T> {
  params?: CollectionArgs
  size?: 'small' | 'middle'
  onRowClick?: (record: T, index: number, e: React.MouseEvent) => void
  onRowHover?: (record: T, index: number, e: React.MouseEvent) => void
  extraColumns?: ColumnProps<T>[]
  footer?: (records: T[]) => React.ReactNode
  dataSource?: T[]
  filters?: React.ReactElement
  filtersExtra?: React.ReactElement
  tableExtra?: React.ReactElement
  empty?: {
    emptySearchText?: string | React.ReactNode
    default?: string | React.ReactNode
  }
  showSearch?: boolean
  pageSize?: number
  renderMobileRow?: (record: T) => React.ReactElement
  loading?: boolean // removes SpinProps from parent types
}

// use it for expandable table data structure
export type KExpandable<T> = T & { children?: KExpandable<T>[] }

export type KFormProps<T> = {
  onSuccess?: (record: T) => void
  onFailure?: () => void
  onCancel?: () => void
  portalId?: string
}

export interface GenericObject<T> {
  [key: string]: T
}

export type RouteInstance = {
  Base: string
  Key: string
  root?: boolean
  member?: (id?: string | number) => any
  collection?: () => GenericObject<string>
}
export type RequestPayload<T> = { [key: string]: string | number | T | RequestPayload<any> } | T

export type RouteType = {
  path?: string
  component: any
  routes: RouteType[]
  exact?: boolean
  key: string
  asFeedback?: boolean
  allowFor?: RoleType[]
  restrictionMode?: RestrictionMode
}

export type RestrictionMode = 'NotAuthorized' | 'RedirectToTransactional'

export const RecordType = {
  authentication: 'authentication',
  user: 'user',
  contact: 'contact',
  contact_organization: 'contact_organization',
  customer: 'customer',
  rental: 'rental',
  session: 'session',
  password_request: 'password_request',
  quote_feedback: 'quote_feedback',
  quote: 'quote',
  invoice: 'invoice',
  invoice_item: 'invoice_item',
  line_item: 'line_item',
  address: 'address',
  delivery_item: 'delivery_item',
  document: 'document',
  notification: 'notification',
  project: 'project',
  price_list: 'price_list',
  price_list_item: 'price_list_item',
  checkout: 'checkout',
  price: 'price',
  change_request: 'change_request',
  category_attribute: 'category_attribute',
  sub_item: 'sub_item',
  package: 'package',
  requirement: 'requirement',
  search: 'search',
  overview: 'overview',
  account: 'account',
  setting: 'setting',
  faq: 'faq',
  activity: 'activity',
  profile: 'profile',
  attachment: 'attachment',
  customer_role: 'customer_role',
  category_segment: 'category_segment',
  category: 'category',
  category_search: 'category_search',
  password: 'password',
  transactional: 'transactional',
  extend_line_item_request: 'extend_line_item_request',
  damage_info: 'damage_info',
  damage_item: 'damage_item',
  line_item_vacant: 'line_item_vacant',
  attribute_value: 'attribute_value',
  attribute_detail: 'attribute_detail',
  machine_group: 'machine_group',
  billing_address: 'billing_address',
  legal_address: 'legal_address',
  delivery_contact: 'delivery_contact',
  manager_contact: 'manager_contact',
  delivery_address: 'delivery_address',
  invoice_payment: 'invoice_payment',
  credit_card: 'credit_card',
  invitation: 'invitation',
  voucher: 'voucher',
  payment: 'payment',
  payment_intent: 'payment_intent',
  paypal_payment: 'paypal_payment',
  direct_booking: 'direct_booking',
  solvency: 'solvency',
  update: 'update',
  copy_request: 'copy_request',
  error: 'error',
  ...RecordTypeEnum,
  plurify: () => {
    let types = { ...RecordType }
    Object.keys(RecordType).map((key: keyof typeof RecordType) => {
      return (types = { ...types, [key]: `${types[key]}s` })
    })
    delete types.plurify
    return {
      ...types,
      activity: 'activities',
      category: 'categories',
      search: 'search',
      line_item_vacant: 'line_item_vacant',
      paypal_payment: 'paypal_payment',
      direct_booking: 'direct_booking',
      solvency: 'solvencies'
    }
  }
}
