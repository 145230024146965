import ant_de from 'antd/locale/de_DE'
import { ToolBox } from 'app/lib/hooks/toolBox'

import antd_mobile_de from '../locales/antd_mobile/de_DE.json'
import deMessages from '../locales/de_DE.json'
import deMessagesV2 from '../locales/v2/de_DE.json'

const typeTemplate = 'Bitte geben Sie eine gültige ${type} ein'

const deLang = {
  messages: {
    ...deMessages,
    ...ToolBox?.flattenPayload(deMessagesV2),
    formValidation: {
      required: 'Dieses Feld ist erforderlich',
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: 'Bitte geben Sie eine gültiges Datum ein',
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: 'Bitte geben Sie eine gültige E-Mail Adresse ein',
        url: typeTemplate,
        hex: typeTemplate
      }
    }
  },
  antLocale: ant_de,
  antMobileLocale: Object.assign({}, ant_de, antd_mobile_de),
  locale: 'de-DE'
}

export default deLang
export const deTree = deMessagesV2
