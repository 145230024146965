import { gql } from '@apollo/client/core'

export const CONTACT_BASE_ATTRIBUTES = gql`
  fragment contactBaseAttributes on Contact {
    id
    name
    email
    tel
  }
`
