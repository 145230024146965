/* eslint-disable @typescript-eslint/no-explicit-any */

import { allLocales } from 'app/constants/Global'
import { store } from 'app/lib/AppProviders'
import { onLoginSuccess, onSessionFailure } from 'app/redux/actions/__legacy/User'
import { history } from 'app/redux/store'
import { Routes } from 'app/routes/Routes'

import BrowserService from './BrowserService'
import ConfigService from './ConfigService'
import IntlService from './IntlService'
import ScriptService from './ScriptService'
import UserService from './UserService'

class LoginService {
  static startSession(data: { included?: any; data?: any }) {
    //authentication payload
    const { data: contact_organization } = data
    const contact = (data?.included || []).find(
      (include: { type: string }) => include.type === 'contact'
    )?.attributes
    const customer = (data?.included || []).find(
      (include: { type: string }) => include.type === 'customer'
    )?.attributes
    //user infos
    const { language, email, hubspot_sync } = contact || {}

    //language check
    const langCode = allLocales.includes(language) ? language : 'de'

    IntlService.loadLanguage()
    IntlService.setLocale(langCode)
    LoginService.removeTokenFromUrl()

    if (contact_organization) {
      const { config, ...contact_organization_attributes } = contact_organization.attributes
      UserService.setCustomerId(contact_organization_attributes.parentable_id)
      store.dispatch(
        onLoginSuccess({
          langCode: language,
          contactOrganization: { ...contact_organization_attributes, contact },
          customer,
          config: ConfigService.init(config)
        })
      )
      ScriptService.embedHubspotScript(email, hubspot_sync)
    } else {
      BrowserService.redirectToLink(`${Routes.Account.collection().index}?error=not_found`)
    }
  }

  static onLogoutSuccess(logoutUrl = Routes.Default) {
    window.heap && window.heap.resetIdentity()
    BrowserService.redirectToLink(logoutUrl)
  }

  static onSessionFailure(errors: string[]) {
    store.dispatch(onSessionFailure(errors))
  }

  static removeTokenFromUrl() {
    const pathname = history.location.pathname
    const search = BrowserService.removeParamFromRoute('login_token')
    history.replace({ pathname, search })
  }
}

export default LoginService
