import { Routes } from 'app/routes/Routes'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const RentalRedirect: React.FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  useEffect(() => {
    const path = pathname.replace(Routes.Rental.Base, Routes.Transactional.Base)
    navigate(path, { replace: true })
  }, [])

  return null
}

export default RentalRedirect
