import { DocumentNode } from '@apollo/client'

import * as attachments from './Attachments'
import * as categorySegment from './CategorySegment'
import * as extendLineItems from './ExtendLineItems'
import * as hubsport from './Hubspot'
import * as lineItems from './LineItems'
import * as lineItemsVacant from './LineItemsVacant'
import * as priceLists from './PriceList'
import * as projects from './Projects'
import * as recentMachines from './RecentMachines'
import * as rentalDeliveryAddress from './RentalDeliveryAddress'
import * as rentals from './Rentals'
import * as rentalsCheckout from './Rentals/Checkout'
import * as subItems from './SubItems'

export enum RecordTypeEnum {
  rental = 'rental',
  project = 'project',
  categorySegment = 'categorySegment',
  lineItem = 'lineItem',
  priceList = 'priceList',
  subItem = 'subItem',
  attachment = 'attachment',
  line_item_vacant = 'line_item_vacant',
  extend_line_item_request = 'extend_line_item_request',
  recentMachine = 'recentMachine',
  rentalDeliveryAddress = 'rentalDeliveryAddress',
  rentalCancel = 'rentalCancel',
  hubspotTicket = 'hubspotTicket',
  invoice = 'invoice'
}

export enum CollectionType {
  rentals = 'rentals'
}

type QueryType = {
  get: DocumentNode
  query: DocumentNode
  update: DocumentNode
  create: DocumentNode
  delete: DocumentNode
  index: DocumentNode
}

type ResourceQueries = {
  [key in RecordTypeEnum]?: Partial<QueryType>
}

export type CollectionResponseType<T> = {
  [key in CollectionType]: T[]
}

export type ResourceResponseType<T> = {
  [key in RecordTypeEnum]: T
} & {
  me?: T
}

export const queries: ResourceQueries = {
  rental: {
    index: rentals.PAGINATED_RENTAL_QUERY,
    get: rentals.RENTAL_SHOW_QUERY,
    update: rentalsCheckout.UPDATE_CHECKOUT_RENTAL_DELIVERY
  },
  project: {
    index: projects.PAGINATED_PROJECT_QUERY
  },
  priceList: {
    get: priceLists.CUSTOMER_PRICE_LIST_QUERY
  },
  categorySegment: {
    get: categorySegment.FETCH_CATEGORY_SEGMENT_QUERY
  },
  line_item_vacant: {
    update: lineItemsVacant.SET_LINE_ITEMS_VACANT
  },
  extend_line_item_request: {
    update: extendLineItems.EXTEND_LINE_ITEMS
  },
  rentalDeliveryAddress: {
    update: rentalDeliveryAddress.UPDATE_RENTAL_DELIVERY_ADDRESS
  },
  subItem: {
    get: subItems.FETCH_SUB_ITEM_QUERY,
    create: subItems.CREATE_SUB_ITEM,
    update: subItems.UPDATE_SUB_ITEM,
    delete: subItems.DELETE_SUB_ITEM
  },
  lineItem: {
    get: lineItems.FETCH_LINE_ITEM_QUERY,
    update: lineItems.UPDATE_LINE_ITEM,
    create: lineItems.CREATE_LINE_ITEM,
    delete: lineItems.DELETE_LINE_ITEM,
    index: lineItems.FETCH_ALL_LINE_ITEMS
  },
  recentMachine: {
    index: recentMachines.PAGINATED_RECENT_MACHINE_QUERY
  },
  attachment: {
    get: attachments.FETCH_ATTACHMENT_QUERY
  },
  rentalCancel: {
    update: rentals.CANCEL_REQUEST
  },
  hubspotTicket: {
    create: hubsport.CREATE_HUBSPOT_TICKET_FOR_OWNER_MUTATION
  }
}
