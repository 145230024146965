import { Input } from 'antd'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KInputTextProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { omit } from 'lodash'
import * as React from 'react'

type Props = {
  hasPlaceholder?: boolean
}

const KInputText: React.FC<
  React.ComponentProps<typeof KFormItem> & Omit<KInputTextProps, 'name' | 'status'> & Props
> = ({
  required,
  className,
  showLabel,
  recordTypeOverride,
  label,
  hasFeedback,
  initialValue,
  labelRenderer,
  hasPlaceholder,
  getValueProps,
  validateStatus,
  extra,
  status,
  ...props
}) => {
  const { tPlaceholder } = ToolBox.useString()
  const { recordType } = useKFormContext()

  const getPlaceholder = (): string => {
    if (hasPlaceholder) {
      return tPlaceholder({
        recordType,
        attribute: props.name as string
      })
    }
  }

  return (
    <KFormItem
      label={label}
      showLabel={showLabel}
      className={className}
      rules={props.rules}
      name={props.name}
      required={required}
      recordTypeOverride={recordTypeOverride}
      readonly={props.readOnly}
      hidden={props.hidden}
      hasFeedback={hasFeedback}
      labelRenderer={labelRenderer}
      initialValue={initialValue}
      getValueProps={getValueProps}
      validateStatus={validateStatus}
      status={status}
      extra={extra}>
      <Input placeholder={getPlaceholder()} {...omit(props, ['name'])} />
    </KFormItem>
  )
}

export default KInputText
