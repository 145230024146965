import { dayjs } from '@2wunder/klarx-tool'
import { LocaleEnum } from 'app/constants/Enums'
import { allLocales } from 'app/constants/Global'
import moment from 'moment'
//@ts-ignore
import moment_de from 'moment/locale/de'
//@ts-ignore
import moment_en from 'moment/locale/en-gb'

import BrowserService from './BrowserService'

const defaultCountry = 'DE'
const allowedCountries = ['DE', 'AT']

class IntlService {
  static isLanguageValid(locale = LocaleEnum.de) {
    return allLocales.map((i) => i.language).includes(locale)
  }

  static getLocaleWithHost(locale: LocaleEnum) {
    // check set_locale implementation in backend
    return `${locale}_${IntlService.getCountryCode()}`
  }

  static getCountryCode() {
    const topLevelDomain = BrowserService.getDomainName().toUpperCase()
    if (allowedCountries.includes(topLevelDomain)) {
      return topLevelDomain // DE, AT, ...
    }
    return defaultCountry
  }

  static setLocale = (langCode = 'de') => {
    moment.locale(langCode)
    dayjs.locale(langCode)
  }

  static loadLanguage = () => {
    moment.updateLocale('de', {
      ...moment_de,
      calendar: {
        sameDay: '[Heute]',
        nextDay: '[Morgen]',
        nextWeek: 'ddd DD.MM',
        lastDay: '[Gestern]',
        lastWeek: '[Letzten] dddd',
        sameElse: 'ddd DD.MM'
      }
    })
    moment.updateLocale('en', {
      ...moment_en,
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'ddd DD.MM',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'ddd DD.MM'
      }
    })
  }
}

export default IntlService
