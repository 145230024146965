import { KFormContextProps } from 'app/components/Modules/Form/typing'
import { useContext } from 'react'
import * as React from 'react'

const KFormContext = React.createContext<KFormContextProps>(null)

export function useKFormContext() {
  return useContext(KFormContext) as KFormContextProps
}

const KFormContextProvider: React.FC<KFormContextProps> = ({
  recordType,
  visualRecordType = recordType,
  children,
  ...others
}) => {
  return (
    <KFormContext.Provider value={{ ...others, recordType, visualRecordType }}>
      {children as React.ReactNode}
    </KFormContext.Provider>
  )
}

export default KFormContextProvider
