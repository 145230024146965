import ContactOrganization from 'app/redux/reducers/ContactOrganization'
import Document from 'app/redux/reducers/Document'
import Session from 'app/redux/reducers/Session'
import { combineReducers } from 'redux'

// eslint-disable-next-line
export default () =>
  combineReducers({
    session: Session,
    document: Document,
    contact_organization: ContactOrganization
  })
