import { Button as AntButton } from 'antd'
import { ButtonProps, ButtonType } from 'antd/lib/button'
import * as React from 'react'

type CustomButtonType = 'default' | 'primary' | 'secondary' | 'dashed' | 'link' | 'text'
interface Props extends Omit<ButtonProps, 'type'> {
  title?: string
  className?: string
  children?: React.ReactNode
  type?: CustomButtonType
  ellipsis?: boolean
  size?: ButtonProps['size']
}

const Button: React.FC<Props> = ({
  title,
  className,
  type = 'default',
  children,
  ellipsis,
  ...others
}) => {
  const getButtonType = (): ButtonType => {
    const types: { [key in CustomButtonType]: ButtonType } = {
      default: 'default',
      primary: 'primary',
      secondary: 'primary',
      dashed: 'dashed',
      link: 'link',
      text: 'text'
    }
    return types[type as CustomButtonType]
  }

  const getClassName = () => {
    let extraClasses = ''
    if (type === 'secondary') {
      extraClasses = 'ant-btn-secondary-border'
    }
    return `${className} ${extraClasses} ${ellipsis ? 'text-ellipsis' : ''}`
  }

  return (
    <AntButton type={getButtonType()} className={getClassName()} {...others}>
      {title || children}
    </AntButton>
  )
}

export default Button
