import axios from 'axios'

import StorageService from './services/StorageService'

export const XSRF_REQUEST_HEADER_NAME = 'X-CSRF-Token' // set this header
export const XSRF_RESPONSE_HEADER_NAME = 'X-XSRF' // read this from response header

let numberOfAjaxCallPending = 0
let loadStarted: Date = null
let timer: NodeJS.Timeout = null

type Type = {
  ejectInterceptors: () => void
}
const setAxiosInterceptor = (): Type => {
  const { get, set } = StorageService

  const xsrfRequestId = axios.interceptors.request.use(async (request) => {
    const { value } = await get({ key: XSRF_RESPONSE_HEADER_NAME })
    if (value) {
      request.headers[XSRF_REQUEST_HEADER_NAME] = value
    }

    return request
  })

  // Add a response interceptor
  const xsrfResponseId = axios.interceptors.response.use(async (response) => {
    const xsrfToken = response.headers[XSRF_RESPONSE_HEADER_NAME.toLocaleLowerCase()]

    if (xsrfToken) {
      await set({ key: XSRF_RESPONSE_HEADER_NAME, value: xsrfToken })
    }

    return response
  })

  const loadTimeRequestId = axios.interceptors.request.use(
    (config) => {
      if (!loadStarted) loadStarted = new Date()
      numberOfAjaxCallPending++
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  // Add a response interceptor
  const loadTimeResponseId = axios.interceptors.response.use(
    (response) => {
      numberOfAjaxCallPending--
      clearTimeout(timer)
      if (numberOfAjaxCallPending === 0) {
        timer = setTimeout(() => {
          const diff =
            new Date().getTime() - 3000 - (loadStarted?.getTime?.() || new Date().getTime())
          const loadTime = Math.abs(Math.round(diff))
          window.heap && window.heap.track('Page Load', { 'Load Time': loadTime })
          loadStarted = null
        }, 3000)
      }
      return response
    },
    (error) => {
      numberOfAjaxCallPending--
      if (numberOfAjaxCallPending === 0) {
        //hide loader
      }
      return Promise.reject(error)
    }
  )

  const ejectInterceptors = () => {
    axios.interceptors.request.eject(xsrfRequestId)
    axios.interceptors.request.eject(loadTimeRequestId)

    axios.interceptors.response.eject(xsrfResponseId)
    axios.interceptors.response.eject(loadTimeResponseId)
  }

  return { ejectInterceptors }
}

export default setAxiosInterceptor
