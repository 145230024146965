import { Space } from 'antd'
import { classNames } from 'app/lib'
import * as React from 'react'
import { ReactNode } from 'react'

interface Props extends Omit<React.ComponentProps<typeof Space>, 'title'> {
  title: ReactNode
  extra?: ReactNode
  avatar?: ReactNode
  description?: ReactNode
  onClick?: () => void
  verticalAlign?: boolean
}

const KItem: React.FC<Props> = ({
  title,
  avatar,
  description,
  extra,
  onClick,
  verticalAlign,
  className,
  ...others
}) => {
  return (
    <Space
      className={classNames(onClick && 'pointer', 'justify-content-between full-width', className)}
      onClick={() => onClick?.()}
      direction="horizontal"
      role="listitem"
      align={verticalAlign ? 'center' : 'start'}
      size="large"
      {...others}>
      <Space align={verticalAlign ? 'center' : 'start'} size={12}>
        {avatar}
        <Space direction="vertical">
          {title}
          {description}
        </Space>
      </Space>
      {extra}
    </Space>
  )
}

export default KItem
