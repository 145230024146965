import { Search as Resource } from 'app/constants/Url'
import Api from 'app/lib/api'
import { parseError } from 'app/lib/services/ErrorService'
import { CollectionArgs } from 'app/redux/actions/typing'

import Base from '../normalizers/Base'

export const Search = {
  index: (params: CollectionArgs): Promise<ISearchResult[]> => {
    return new Promise((resolve, reject) => {
      Api.get<ICollectionResponse<ISearchResult>>(Resource.collection(params).index)
        .then((response) => {
          resolve(Base.mapAttributesOfCollection(response.data.data))
        })
        .catch((error) => {
          reject(parseError(error))
        })
    })
  }
}
