import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'

export const allRoles: RoleType[] = [
  'admin',
  'manager',
  'member',
  'accounting',
  'delivery_contact',
  'guest'
]

export const useRestriction = ({ allowFor }: { allowFor: RoleType[] }) => {
  const contactOrganization = useCurrentContactOrganization()

  const restricted = !allowFor.includes(contactOrganization?.role)

  return { restricted }
}
