import Bugsnag from '@bugsnag/js'
import { LoadScriptProps, useLoadScript } from '@react-google-maps/api'
import EnvironmentService from 'app/lib/services/EnvironmentService'

const libraries: LoadScriptProps['libraries'] = ['places']

const loader = () =>
  useLoadScript({
    googleMapsApiKey: EnvironmentService.getEnvValue('GOOGLE_MAP_KEY'),
    libraries: libraries
  })

type Props = {
  isLoaded: boolean
  loadError: Error | undefined
  url: string
}

export const loadGoogleScripts = (retry = 0): Props => {
  const { isLoaded, loadError, url } = loader()

  if (loadError) {
    if (retry < 2) {
      return loadGoogleScripts(retry + 1)
    } else {
      const errorStr = JSON.stringify(loadError)
      console.error(errorStr)
      Bugsnag.notify(new Error(errorStr), (event) => {
        event.severity = 'info'
      })
    }
  }

  return { isLoaded, loadError, url }
}
