import Bugsnag from '@bugsnag/js'
import configureStore from 'app/redux/store'
import React, { PropsWithChildren } from 'react'
import { Provider } from 'react-redux'

export const store = configureStore()

function subscription() {
  const session = store.getState().session
  const contact = session?.contactOrganization?.contact
  if (session?.loggedIn && contact) {
    Bugsnag.setUser(contact)
    window.heap &&
      window.heap.addUserProperties({
        ...contact,
        customer_organization_name: session.contactOrganization.name,
        role: session.contactOrganization.role,
        hubspot_lead_status: session.customer?.hubspot_lead_status
      })
    unsubscribe()
  }
}

const unsubscribe = store.subscribe(subscription)

const AppProviders: React.FC<PropsWithChildren> = ({ children }) => {
  return <Provider store={store}>{children}</Provider>
}

export default AppProviders
