import { URLOpenListenerEvent } from '@capacitor/app'
import { useRequireUpdate } from 'app/lib/hooks/useAppVersion'
import { useUrlOpen } from 'app/lib/hooks/useCapAppListener'
import BrowserService from 'app/lib/services/BrowserService'
import { getQueryString } from 'app/lib/utils/search-params'
import { authenticateUserWithLoginToken } from 'app/redux/actions/__legacy/User'
import React, { useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { Routes } from './Routes'

const AppListener: React.FC = () => {
  const navigate = useNavigate()
  const { requireUpdate } = useRequireUpdate()
  const match = useMatch(Routes.Update.collection().index)

  useUrlOpen(async (data: URLOpenListenerEvent) => {
    // More information about deep links in capacitor: https://capacitorjs.com/docs/guides/deep-links
    const customerId = BrowserService.getCustomerIdParam(data.url)
    const url = new URL(data.url)
    const urlToken = getQueryString(url.search, 'login_token')
    if (urlToken) {
      await authenticateUserWithLoginToken(urlToken, customerId)
    }
    // Example url: https://app.klarx.de/kunden/78600/uebersicht
    let slug = url.pathname // slug: /kunden/78600/uebersicht
    const basePath = Routes.Base(Number(customerId))
    if (slug.includes(basePath)) {
      slug = slug.replace(basePath, '')
    }
    // slug: /uebersicht
    if (slug) {
      navigate({ pathname: slug, search: url.search }, { replace: true })
    }
  })

  useEffect(() => {
    if (requireUpdate) {
      navigate(Routes.Update.collection().index, { replace: true })
    } else if (match) {
      navigate(Routes.Overview.collection().index, { replace: true })
    }
  }, [requireUpdate])

  return null
}

export default AppListener
