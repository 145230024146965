/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { GoogleMap, GoogleMapProps, MarkerClusterer } from '@react-google-maps/api'
import Marker from 'app/components/Modules/Map/Marker'
import SpinOverlay from 'app/components/UI-Elements/Feedback/Spin'
import { loadGoogleScripts } from 'app/lib/hooks/loadGoogleScripts'
import { ToolBox } from 'app/lib/hooks/toolBox'
import React, { ComponentProps, useEffect, useRef, useState } from 'react'

import MapDrawer from './MapDrawer'

const defaultCenterVal = { lat: 51.1280655, lng: 8.6463666 }
export interface MapGeoPoint {
  id: number
  latitude: number
  longitude: number
}

interface MapProps<T> extends Omit<GoogleMapProps, 'onDragEnd'> {
  collection: T[]
  tooltip?: (item: T) => React.ReactNode
  drawer?: React.ReactNode
  button?: React.ReactNode
  height?: string
  onDragEnd?: ComponentProps<typeof Marker>['onDragEnd']
}

function Map<T extends MapGeoPoint>({
  collection,
  tooltip,
  onDragEnd,
  height = '80vh',
  drawer,
  button,
  ...others
}: MapProps<T>) {
  const { isLoaded } = loadGoogleScripts()
  const geoData = ToolBox.useMapTools().geofy<T>(collection)
  const google = window.google
  const [openMarkerId, setOpenMarkerId] = useState(-1)
  const [center, setCenter] = useState<google.maps.LatLng | typeof defaultCenterVal>(null)
  const ref = useRef<GoogleMap>()

  useEffect(() => {
    setCenter(getCenter())

    if (collection.length > 1) fitBounds()
  }, [collection, isLoaded])

  const fitBounds = () => {
    try {
      ref.current.state.map.fitBounds(getBounds())
    } catch (e) {
      console.warn({ fitBoundsErrors: e })
    }
  }

  const getBounds = () => {
    const bounds = new google.maps.LatLngBounds()
    geoData.forEach((item) => {
      bounds.extend(new google.maps.LatLng(item.lat, item.lng))
    })
    return bounds
  }

  const getCenter = () => {
    if (geoData.length && isLoaded) {
      return getBounds().getCenter()
    } else {
      return defaultCenterVal
    }
  }

  const options = {
    imagePath:
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  const getZoom = () => {
    if (collection.length === 1) {
      return 16
    } else {
      return 12
    }
  }

  const onMarkerToggle = (open: boolean, member: T) => {
    if (open) {
      setOpenMarkerId(member.id)
    } else {
      setOpenMarkerId(-1)
    }
  }

  return (
    <Choose>
      <When condition={isLoaded}>
        <GoogleMap
          data-testid="kgoogle-map"
          mapContainerStyle={{ height }}
          center={center}
          zoom={getZoom()}
          options={{
            streetViewControl: false
          }}
          ref={ref}
          {...others}>
          <MarkerClusterer zoomOnClick={true} options={options}>
            {(clusterer) =>
              //@ts-ignore
              geoData.map((member) => (
                <Marker
                  onDragEnd={onDragEnd}
                  draggable={Boolean(onDragEnd)}
                  tooltip={tooltip}
                  clusterer={clusterer}
                  key={`${member.lng}-${member.lat}-${member.id}`}
                  position={{ lat: member.lat || 0, lng: member.lng || 0 }}
                  item={member}
                  open={openMarkerId === member.id}
                  onMarkerToggle={(open: boolean) => onMarkerToggle?.(open, member)}
                />
              ))
            }
          </MarkerClusterer>
          <If condition={drawer}>
            <MapDrawer>{drawer}</MapDrawer>
          </If>
          <If condition={button}>
            <div className="position-absolute" style={{ bottom: '16px', right: '16px' }}>
              {button}
            </div>
          </If>
        </GoogleMap>
      </When>
      <Otherwise>
        <SpinOverlay loading={true} />
      </Otherwise>
    </Choose>
  )
}

export default Map
