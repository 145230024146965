import { RecordType } from 'app/constants/GlobalTypes'
import CapacitorService from 'app/lib/services/CapacitorService'
import ScriptService from 'app/lib/services/ScriptService'
import { Routes } from 'app/routes/Routes'
import { useNavigate } from 'react-router-dom'

import { useCreate } from './useApi'
import { useCurrentContactOrganization } from './useCurrent'

type HookType = {
  createRental: (member?: Partial<IRental>, onSuccess?: (rental: IRental) => void) => Promise<void>
  loading: boolean
}

export const useRentalCreate = (): HookType => {
  const { execute, loading } = useCreate<Partial<IRental> & { ga_client_id?: number }>(
    RecordType.rental,
    RecordType.rental,
    true
  )
  const contactOrganization = useCurrentContactOrganization()
  const navigate = useNavigate()

  const createRental = (member: Partial<IRental> = {}, onSuccess?: (rental: IRental) => void) => {
    const clientId = ScriptService.getGaClientId()

    return execute({
      lead: CapacitorService.isNative() ? 'manager_app' : 'manager',
      contact_id: contactOrganization.contact_id,
      customer_id: contactOrganization.parentable_id,
      ...(clientId ? { ga_client_id: clientId } : {}),
      ...member
    }).then((rental: IRental) => {
      onSuccess ? onSuccess(rental) : navigate(Routes.Checkout.member(rental.id.toString()).show)
    })
  }

  return { createRental, loading }
}
