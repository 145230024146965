import { InfoOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import Button from 'app/components/UI-Elements/General/Button'
import EnvironmentService, { Environment } from 'app/lib/services/EnvironmentService'
import { useNavigate } from 'react-router-dom'

const boxShadow =
  '"0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05)"'

const HiddenButton = () => {
  const navigate = useNavigate()
  const open = (link: string) => navigate(link)
  const { md } = useBreakpoint()

  const items = [
    EnvironmentService.is(Environment.Dev) && {
      key: 'Graphiql',
      label: 'Graphiql',
      onClick: () => open('/graphiql')
    },
    {
      key: 'design-system',
      label: 'Design System',
      onClick: () => open('/design_system')
    },
    {
      key: 'kcomponents-docs',
      label: 'KComponents Documentation',
      onClick: () => open('/component_documentation')
    }
  ]

  return (
    <div style={{ position: 'fixed', right: 20, bottom: md ? 20 : 80 }}>
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button
          type="secondary"
          shape="circle"
          icon={<InfoOutlined />}
          style={{ boxShadow: boxShadow }}
        />
      </Dropdown>
    </div>
  )
}

export default HiddenButton
