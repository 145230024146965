import KFormItem from 'app/components/Modules/Form/KFormItem'
import { GenericObject } from 'app/constants/GlobalTypes'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import { ReactNode } from 'react'
import * as React from 'react'

interface Props {
  name?: string
  values?: GenericObject<ReactNode>
  type?: 'description' | 'value'
  className?: string
}

const KFormInfo: React.FC<Props> = ({ name, values, type = 'description', className }) => {
  const { recordType, form } = useKFormContext()
  const { getFormItemDescription, getFormDescription } = ToolBox.useFormTools()

  return (
    <KFormItem>
      <Choose>
        <When condition={type === 'value' && name}>
          <span className={className}>{form.getFieldValue(name)}</span>
        </When>
        <When condition={type === 'description' && name}>
          {getFormItemDescription({ recordType, attribute: name as string, values })}
        </When>
        <Otherwise>{getFormDescription({ recordType, values })}</Otherwise>
      </Choose>
    </KFormItem>
  )
}

export default KFormInfo
