import { persister } from 'app/lib/stateHandler'
import { Reducer, ReducerAction } from 'app/redux/typing'

function BaseReducer<T>({ recordType }: { recordType: string }) {
  const initial: Reducer<T> = {
    index: null,
    show: null,
    create: null,
    update: null,
    destroy: null,
    hash: (+new Date()).toString(36)
  }

  return (state: Reducer<T> = initial, action: ReducerAction<T>) => {
    const { type, actionType } = action
    if (type === recordType) {
      return persister<T>(state, action, actionType)
    }
    return state
  }
}

export default BaseReducer
