import Api from 'app/lib/api'
import { parseError } from 'app/lib/services/ErrorService'
import LoginService from 'app/lib/services/LoginService'
import { AUTH } from 'app/redux/reducers/actionTypes'

export const authenticateUser = (customerId = ''): Promise<void> => {
  return new Promise((resolve) => {
    Api.get(`/authentication/login_session?customer_id=${customerId}`)
      .then((response) => {
        LoginService.startSession(response.data)
        resolve()
      })
      .catch((error) => {
        LoginService.onSessionFailure(parseError(error, false))
      })
  })
}

export const authenticateUserWithLoginToken = (
  loginToken: string,
  customerId: string
): Promise<void> => {
  return new Promise((resolve) => {
    Api.post(`/authentication/token_login?customer_id=${customerId}&login_token=${loginToken}`)
      .then((response) => {
        LoginService.startSession(response.data)
        resolve()
      })
      .catch((error) => {
        LoginService.onSessionFailure(parseError(error, false))
      })
  })
}

export const logoutUser = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    Api.get('/authentication/logout')
      .then(() => {
        LoginService.onLogoutSuccess()
        resolve()
      })
      .catch((error) => {
        reject(parseError(error))
      })
  })
}

// action save data from service
export const onLoginSuccess = (payload: unknown) => {
  return {
    type: AUTH.LOGIN_SUCCESS,
    payload
  }
}

export const onSessionFailure = (errors: string[]) => {
  return {
    type: AUTH.SESSION_FAILURE,
    payload: errors
  }
}
