import { Row, Select } from 'antd'
import { SelectValue } from 'antd/lib/select'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KSelectProps } from 'app/components/Modules/Form/typing'
import KCol from 'app/components/UI-Elements/Layout/KCol'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { useIndex, useShow } from 'app/lib/hooks/useApi'
import { uniqBy } from 'lodash'
import { useEffect, useState } from 'react'

function KSelect<T extends SelectValue & { [key: string]: any }>(props: KSelectProps<T>) {
  const {
    selectValueBy,
    optionLabelProp,
    recordType,
    name,
    optionRenderer,
    value,
    params = {},
    label,
    status,
    ...others
  } = props
  const {
    loading: loadingIndex,
    response: { data: collection },
    execute: index
  } = useIndex<T>(recordType)
  const { loading: loadingShow, response: member, execute: show } = useShow<T>(recordType)
  const [searchText, setSearchText] = useState<string>('')
  const { form, nested, recordType: nestedRecordType } = useKFormContext()

  const getName = (): string | string[] => {
    return nested ? [nestedRecordType, name] : name
  }

  useEffect(() => {
    if (form.getFieldValue(getName())) show({ id: form.getFieldValue(getName()) })
  }, [form.getFieldValue(getName())])

  const onFocus = () => {
    !props.options?.length && fetchCollection()
  }

  const onSearch = (e: string) => {
    setSearchText(e)
    !props.filterOption && fetchCollection(e)
  }

  const fetchCollection = (q = searchText) => {
    index({
      ...params,
      q
    })
  }

  const getData = () => {
    return uniqBy([...[member], ...collection].filter(Boolean), selectValueBy).map((record) => ({
      label: optionRenderer?.(record) || record[optionLabelProp],
      value: record[selectValueBy]
    }))
  }

  return (
    <KFormItem name={name} label={label} status={status} {...others}>
      <Select
        showSearch
        onFocus={onFocus}
        onSearch={onSearch}
        filterOption={false}
        getPopupContainer={(target) => target}
        className="full-width"
        options={getData()}
        loading={loadingIndex || loadingShow}
        dropdownRender={(menu) => (
          <Row gutter={[12, 12]}>
            <KCol span={24}>{menu}</KCol>
          </Row>
        )}
        {...others}
      />
    </KFormItem>
  )
}

export default KSelect
