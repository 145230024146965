import { Layout } from 'antd'
import { Grid } from 'antd'
import Desktop from 'app/components/UI-Elements/Layout/MainLayout/Desktop'
import Mobile from 'app/components/UI-Elements/Layout/MainLayout/Mobile'
import { useFeatureToggle } from 'app/lib/hooks/useFeatureToggle'
import { Routes } from 'app/routes/Routes'
import * as React from 'react'
import { useLocation } from 'react-router'

import HiddenButton from './Documentation/Component/HiddenButton'

const MainLayout: React.FC<React.PropsWithChildren> = ({ children, ...others }) => {
  const screens = Grid.useBreakpoint()
  const { notProduction } = useFeatureToggle()
  const { pathname } = useLocation()

  const standaloneRoutes = [
    Routes.Authentication.collection().set_password,
    Routes.Update.collection().index
  ]

  return (
    <Layout>
      <Choose>
        <When condition={standaloneRoutes.includes(pathname)}>{children}</When>
        <When condition={!screens.lg}>
          <Mobile {...others}>{children}</Mobile>
        </When>
        <Otherwise>
          <Desktop {...others}>{children}</Desktop>
        </Otherwise>
      </Choose>
      <If condition={notProduction && screens.md}>
        <HiddenButton />
      </If>
    </Layout>
  )
}

export default MainLayout
