import { Layout } from 'antd'
import Header from 'app/components/UI-Elements/Layout/Header/Desktop/Header'
import Sider from 'app/components/UI-Elements/Layout/Sider/Sider'
import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'
import { Routes } from 'app/routes/Routes'
import { useState } from 'react'
import * as React from 'react'
import { useLocation, useMatch } from 'react-router-dom'

import PlainHeader from '../Header/Plain'

const { Content } = Layout

const DesktopLayout: React.FC<React.PropsWithChildren> = ({ ...others }) => {
  const { role } = useCurrentContactOrganization()
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const { pathname } = useLocation()
  const checkoutRoute = pathname.includes(Routes.Checkout.Base)
  const quoteRoute = pathname.includes(Routes.Payment.Base)
  const transactional = role === 'guest'
  const updateRental = Boolean(useMatch(Routes.Rental.member().update))

  const plainHeader = checkoutRoute || quoteRoute || transactional || updateRental

  const toggleDrawer = () => setOpenDrawer(!openDrawer)

  return (
    <Layout>
      <Choose>
        <When condition={plainHeader}>
          <PlainHeader hideProfile={plainHeader} showCancel={checkoutRoute} />
        </When>
        <Otherwise>
          <Header toggleDrawer={toggleDrawer} />
        </Otherwise>
      </Choose>
      <Layout>
        <If condition={!plainHeader}>
          <Sider toggleDrawer={toggleDrawer} open={openDrawer} />
        </If>
        <Layout>
          <Content className="main-layout-content" {...others} />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default DesktopLayout
