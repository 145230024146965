import { Radio } from 'antd'
import { RadioGroupProps } from 'antd/lib/radio'
import KFormItem from 'app/components/Modules/Form/KFormItem'
import { KRadioProps } from 'app/components/Modules/Form/typing'
import { useKFormContext } from 'app/lib/contexts/KFormContext'
import { ToolBox } from 'app/lib/hooks/toolBox'
import * as React from 'react'

const KRadio: React.FC<KRadioProps & RadioGroupProps> = ({
  required,
  name,
  optionType,
  labelRenderer,
  disabled,
  showLabel,
  defaultValue,
  ...props
}) => {
  const { getRadioOptions } = ToolBox.useFormTools()
  const { recordType } = useKFormContext()
  const options = getRadioOptions({ recordType, attribute: name })

  return (
    <KFormItem
      name={name}
      required={required}
      readonly={props.readonly}
      hasFeedback={false}
      showLabel={showLabel}
      labelRenderer={labelRenderer}>
      <Radio.Group defaultValue={defaultValue} options={options} disabled={disabled}>
        <Choose>
          <When condition={optionType === 'button'}>
            {Object.keys(options).map((key) => (
              <Radio.Button key={key} value={key}>
                {options[key]}
              </Radio.Button>
            ))}
          </When>
          <Otherwise>
            {Object.keys(options).map((key) => (
              <Radio key={key} value={key}>
                {options[key]}
              </Radio>
            ))}
          </Otherwise>
        </Choose>
      </Radio.Group>
    </KFormItem>
  )
}

export default KRadio
