/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider } from 'antd'
import { LocaleProvider as MobileLocaleProvider } from 'antd-mobile'
import { LocaleEnum } from 'app/constants/Enums'
import { themeConfig } from 'app/constants/ThemeConfig'
import AppLocale from 'lngProvider'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'

import { useSession } from '../hooks/useCurrent'
import IntlService from '../services/IntlService'

type Props = {
  children: React.ReactNode
}

// Provides Ant Design and React Intl
export const UIProvider: React.FC<Props> = ({ children }) => {
  const { langCode } = useSession()

  const localeKey = IntlService.isLanguageValid(langCode as LocaleEnum) ? langCode : 'de'
  const { locale, messages, antLocale, antMobileLocale } = AppLocale[localeKey]

  useEffect(() => {
    IntlService.setLocale(locale)
  }, [locale])

  return (
    <IntlProvider locale={locale} messages={messages as any}>
      <ConfigProvider
        form={{ validateMessages: messages.formValidation }}
        locale={antLocale}
        theme={themeConfig}
        componentSize="large">
        {/* //@ts-ignore */}
        <MobileLocaleProvider locale={antMobileLocale || antLocale}>
          {children}
        </MobileLocaleProvider>
      </ConfigProvider>
    </IntlProvider>
  )
}
