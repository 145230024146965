import { Capacitor } from '@capacitor/core'
import EnvironmentService from 'app/lib/services/EnvironmentService'

export enum PlatformEnum {
  web = 'web',
  ios = 'ios',
  android = 'android'
}

const CapacitorService = {
  platform: Capacitor.getPlatform(),

  isWeb: (): boolean => CapacitorService.platform === PlatformEnum.web,
  isIos: (): boolean => CapacitorService.platform === PlatformEnum.ios,
  isAndroid: (): boolean => CapacitorService.platform === PlatformEnum.android,
  isNative: (): boolean => Capacitor.isNativePlatform() || false,
  appAvailable: (): boolean => Capacitor.isNativePlatform() && Capacitor.isPluginAvailable('App'),
  isPluginAvailable: (plugin: string): boolean => Capacitor.isPluginAvailable(plugin),
  getStoreLink: (): string | undefined => {
    switch (CapacitorService.platform) {
      case PlatformEnum.ios:
        return EnvironmentService.getEnvValue('IOS_LINK')
      case PlatformEnum.android:
        return EnvironmentService.getEnvValue('ANDROID_LINK')
      default:
        break
    }
  }
}

export default CapacitorService
