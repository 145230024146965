import { Layout } from 'antd'
import MobileHeader from 'app/components/UI-Elements/Layout/Header/Mobile/Header'
import { useCurrentContactOrganization } from 'app/lib/hooks/useCurrent'
import { Routes } from 'app/routes/Routes'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import PlainHeader from '../Header/Plain'

const { Content } = Layout

type Props = {
  customRoots?: string[]
  children: React.ReactNode
}

const MobileLayout: React.FC<Props> = ({ customRoots = [], ...others }) => {
  const { pathname } = useLocation()
  const { role } = useCurrentContactOrganization()
  const roots = [
    ...[Routes.Rental, Routes.Project, Routes.Overview, Routes.Invoice, Routes.Transactional].map(
      (r) => r.Base
    ),
    ...customRoots
  ]
  const transactional = role === 'guest'

  return (
    <Layout>
      <Choose>
        <When condition={roots.includes(pathname) || transactional}>
          <PlainHeader hideProfile={transactional} />
        </When>
        <Otherwise>
          <MobileHeader />
        </Otherwise>
      </Choose>
      <Layout>
        <Layout>
          <Content className="main-layout-content" {...others} />
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MobileLayout
